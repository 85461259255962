@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family="Alexandria":wght@100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;


}

body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
p,
textarea,
a,
div,
button,
div,
input,
label,
input::placeholder {
  font-family: "Poppins", sans-serif;

}

button {
  background-color: transparent;
  cursor: pointer;
}

.navbar-nav {
  justify-content: space-around;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #263069;

}

a {
  color: #263069;
}

button {
  border: none;
  outline: none;
}

.mapp {
  cursor: url('./location.svg'), auto;
}

.navbar {
  padding: 10px 20.98px !important;
  min-height: 87.96px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
  position: sticky;
  top: 0;
  z-index: 222;
  width: 100%;
}

.navbar .navbar-brand span {
  margin-right: 159px;
  color: #263069;
  font-size: 28.15px;
  font-style: normal;
  font-weight: 800;
  line-height: 42.22px;
  margin-left: 7.7px;
}

.ImgNav {
  display: flex;
  align-items: center;
}

.navbar .ImgNav img {
  width: 34.98px;
  height: 47.87px;
  flex-shrink: 0;

}

.navbar .addRest {
  text-decoration: none;
  color: #263069;
  font-size: 17.593px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 13.194px;
  border: 1.759px solid #263069;
  display: flex;
  width: 221.667px;
  padding: 8.796px;
  justify-content: center;
  align-items: center;
  gap: 6.157px;
}
.navbar .addRest.add:hover{
  background-color: #CEE4FF;
  /* border: 1.759px solid #CEE4FF; */

}

.addRest.find {
  background-color: #263069;
  color: #fff !important;
}

.navbar .nav-link {
  text-decoration: none;
  margin: 0 10px;
  color: #263069;
  font-size: 21.111px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navbar .loginBtn {
  text-decoration: none;
  color: #FFF;
  font-size: 17.593px;
  font-weight: 400;
  display: flex;
  width: 102.037px;
  height: 43.981px;
  justify-content: center;
  align-items: center;
  background-color: #263069;
  border-radius: 15px;
}

.navbar .loginBtn.edit {
  margin-left: unset;
}

a {
  text-decoration: none !important;

}

.nav-item a.loginBtn {
  color: #fff !important;

}

.nav-item a.loginBtn img {
  margin-right: 5px;
}

.navbar .urlphoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* .container0 nav {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 555555;
  height: 87.96px;
  justify-content: space-around;
  background: #FFF;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.30);

}


.container0 nav .container1 {
  left: 52px;
  top: 45px;

}

.container0 nav .container1 .ul1 {
  display: flex;

  align-items: center;
}

.container0 nav .container1 .ul1 li {
  list-style-type: none;



}

.container0 nav .container1 .ul1 img {
  width: 43.981px;
  height: 43.981px;
  flex-shrink: 0;
  top: -22px;

}

.container0 nav .container1 .ul1 .li0 {
  align-items: center;
  box-sizing: border-box;
  left: 50px;


}

.container0 nav .container1 .ul1 .li0 a {
  text-decoration: none;
  color: #263069;
  box-sizing: border-box;
  width: 100px;
  height: auto;
  font-size: 28.148px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;





}

.container0 nav .container1 .ul2 {
  top: -22.5px;

}

.container0 nav .container1 .ul2 .li1 {
  list-style-type: none;
  left: 303px;

}

.container0 nav .container1 .ul2 .li1 a {
  text-decoration: none;
  color: #263069;
  font-size: 17.593px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 221.667px;
  padding: 9px 0 9px 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 13.194px;
  border: 1.759px solid #263069;
  gap: 6.157px;
}

.container0 nav .container1 {
  display: flex;

  justify-content: space-between;
  align-items: center;

}

.container0 nav .container2 {
  display: flex;
  justify-content: space-between;
  right: 22px;
  gap: 144px;
  top: 20px;



}

.container0 nav .container2 .ul3 li {
  list-style-type: none;

}

.container0 nav .container2 .ul3 li a {
  text-decoration: none;
  height: 36px;
  width: auto;
  color: #263069;
  font-size: 21.111px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.container0 nav .container2 .ul3 {
  display: flex;
  align-items: center;

  gap: 55.42px;
}

.container0 nav .container2 .ul4 {
  width: 116px;
  height: 50px;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.container0 nav .container2 .ul4 li {
  list-style-type: none;

}

.container0 nav .gap {
  display: none;



}


.container0 nav .container2 .ul4 li a {
  text-decoration: none;
  color: #FFF;
  font-size: 17.593px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 102.037px;
  height: 43.981px;
  justify-content: center;
  align-items: center;
  gap: 8.8px;
  flex-shrink: 0;
  background-color: #263069;
  border-radius: 15px;
}

.container0 nav .container2 .ul4 li a img {
  width: 14.074px;
  height: 14.074px;
  flex-shrink: 0;
} */

/* end navbar */




.containerbody {
  /* padding-top: 160px; */
  position: relative;
  /* z-index: -1; */
  height: 100%;
  overflow: hidden;
}

.containerbody .overlay {
  position: absolute;
  width: 100%;
  height: 110%;
  top: -15px;

  z-index: 0;
}

.containerbody .row {
  margin-right: 0px !important;
  padding: 2px;


}

.containerbody .row .row .col-sm-12 {
  padding: 0 2px !important;
}

.overlay.filtered {
  position: absolute;
  width: 41%;
  height: 540px;
  top: 29%;
  right: 0.5%;
  z-index: 11;
}

.containerbody .overlay .mapp {
  width: 100%;
  height: 100%;
}

.containerbody .overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.d0 {
  width: 100%;
  /* min-height: 100vh; */
  background-color: #ECECEC;
  /* padding-bottom: 40px; */
  padding-top: 60px;
}

.containerbody .searchbar {
  width: 1249.95px;
  margin: auto;
  /* height: 177.69px; */
  position: relative;
  /* display: flex; */
  padding: 10.72px 12.31px 14.79px 14.07px;
  gap: 0px;
  border-radius: 17.59px;
  /* opacity: 0px; */
  box-shadow: 3.52px 3.52px #00000040;
  background-color: #FFFFFF;
}

.containerbody .searchbar .input-search0 {
  width: 100%;
  height: 64.21px;
  border: 0.88px;
  display: flex;
  border-radius: 13.194px;
  background: #EDEAEA;
  box-shadow: 0px 0px 4.838px 0px rgba(0, 0, 0, 0.60);

}

.containerbody .searchbar .input-search1 {
  display: flex;
  width: 393.19px;
  height: 46.62px;
  gap: 15px;


}


.containerbody .searchbar .input-search0 .input-search1 {
  width: 423.98px;
  height: 64.21px;
  /* position: absolute; */
  z-index: 10;

}

.searchbar input[type="checkbox"] {
  display: none;
}

.containerbody .searchbar .input-search0 .input-search1 .form-controlsearch {
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  width: 100%;
  /* height: 43.59px; */
  padding: 8.8px;
  gap: 8.8px;
  color: #263069;
  border: none;
  border-radius: 13.19px;
  top: 1.5px;
  outline: none;
  background-color: #EDEAEA;

}

.containerbody .searchbar .input-search0 .input-search1 .form-controlsearch:focus {
  outline: none;
}

.containerbody .searchbar .input-search0 .input-search1 button {
  /* width: 96.76px;
  height: 46.62px;
  padding: 8.8px; */
  left: 375px;


}

.containerbody .searchbar .input-search0 .input-search1 .display-img {
  display: flex;
  width: 96.76px;
  /* height: 46.62px; */
  padding: 8.8px;
  align-items: center;
}

.containerbody .searchbar .input-search0 .input-search1 img {
  width: 29.03px;
  height: 29.03px;
  gap: 0px;
  left: 350px;


}

.containerbody .searchbar .input-search0 .input-search1 button {
  font-size: 17.59px;
  font-weight: 400;
  text-align: left;
  border: none;
  background-color: #EDEAEA;
  color: #263069;
}



.containerbody .searchbar .input-search0 .frame0 {
  height: 64.21px;
  /* gap: 4.4px; */
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  /* gap: 10px; */
  z-index: 10;
  /* left: 480px; */
  align-items: center;
}


.containerbody .searchbar .input-search0 .frame0 .title101 {
  display: flex;
  /* width: 200px; */
  /* align-items: center; */


}

.containerbody .searchbar .input-search0 .frame0 .title101 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.containerbody .searchbar .input-search0 .frame0 .title101 input[type="checkbox"]:after {
  content: '';
}

.containerbody .searchbar .input-search0 .frame0 .title101 label {
  /* width: 190px; */
  height: 29px;
  /* padding: 8.796px; */
  box-sizing: border-box;
  align-items: center;
  /* gap: 5px; */
  cursor: pointer;
  display: flex;
}

.containerbody .searchbar .input-search0 .frame0 .title101 label h5 {
  color: #263069;
  font-size: 17.593px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.containerbody .searchbar .input-search0 .frame0 .title101 label img {
  fill: #FFF;
  stroke: #263069;
}




.containerbody .searchbar .input-search0 .frame0 .title101 input[type="checkbox"]:checked~label img {
  fill: #263069;
  stroke: #ffffff;
  filter: invert(100%) hue-rotate(180deg);
}




.containerbody .searchbar .input-search0 .frame0 .frame1 {
  display: flex;
  align-items: center;
}



.containerbody .searchbar .input-search0 .filter0 .frame5 a {
  width: 145.14px;
  height: 64.21px;
  padding: 8.8px;
  box-sizing: border-box;
  /* gap: 8.8px; */
  text-decoration: none;
  border-left: 1.759px solid #000;
  font-size: 17.59px;
  font-weight: 400;
  line-height: 26.39px;
  text-align: left;
  color: #263069;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerbody .searchbar .input-search0 .filter0 .frame5 a .desk {
  color: #263069;
  font-size: 21.991px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.containerbody .searchbar .input-search0 .filter0 .frame5 a .desk img {
  width: 30.787px;
  height: 30.787px;
  flex-shrink: 0;
  margin-left: 8.8px;
}

.containerbody .searchbar .input-search0 .search-logo {
  position: absolute;
  right: 4px;
  height: 64.21px;

  padding: 8.8px;
  box-sizing: border-box;
  gap: 8.8px;
  border-radius: 0px 13.19px 13.19px 0px;
  display: flex;
  justify-content: center;
  align-items: center;



}

.containerbody .searchbar .input-search0 .search-logo button {
  width: 140px;
  height: 64.21px;
  gap: 8.8px;
  border: 0px 0px 0px 1.76px;
  border-radius: 0px 13.19px 13.19px 0px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 17.59px;
  font-weight: 400;
  line-height: 26.39px;
  background-color: #263069;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;


}










.containerbody .searchbar .main {
  position: static;
  margin-top: 25px;
  background-color: white;
  border-radius: 12px;

}

.containerbody .searchbar .main .item-div {
  display: flex;

  gap: 10px;

  overflow-x: auto;



}

.containerbody .searchbar .main .item-div::-webkit-scrollbar {
  display: none;
}



.containerbody .searchbar .main .item-div .title10 {
  min-width: 125px;
  height: 83px;

  margin: auto;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  position: relative;
  align-items: center;
  background: #FFF;
  justify-content: center;


}

.containerbody .searchbar .main .item-div .title10 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.containerbody .searchbar .main .item-div .title10 input[type="checkbox"]:after {
  content: '';
}

.containerbody .searchbar .main .item-div .title10 label {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 101px;

}

.containerbody .searchbar .main .item-div .title10 input[type="checkbox"]~label {
  display: flex;
  width: 125.301px;
  height: 80px;
  padding: 9.639px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9.639px;
  text-align: center;
  padding-top: 5px;
  border-radius: 6.747px;
  background: #FFF;
}

.containerbody .searchbar .main .item-div .title10 input[type="checkbox"]:checked~label {

  background-color: #263069;

}

.containerbody .searchbar .main .item-div .title10 label h5 {


  margin: 0 auto;
  color: rgba(0, 0, 0, 0.7);
  font-size: 11.566265106201172px;
  font-weight: 500;
  line-height: 17px;
}

.containerbody .searchbar .main .item-div .title10 label img {
  width: 33.735px;
  height: 33.735px;
  /* fill: #263069; */
  filter: grayscale(100%) contrast(100%);

}

.containerbody .searchbar .main .item-div .title10 input[type="checkbox"]:checked {
  background-color: #263069;
  border-radius: 7px;




}

.containerbody .searchbar .main .item-div .title10 input[type="checkbox"]:checked~label h5 {
  color: #FFF;
}

.containerbody .searchbar .main .item-div .title10 input[type="checkbox"]:checked~label img {
  filter: brightness(0%);
}

.containerbody .searchbar .main .item-div .title10 input[type="checkbox"]:checked~label img {
  /* fill: #FFF; */
  filter: brightness(0%) invert(1) contrast(1);

}

.changecontainer {
  padding: 0 !important;
}

.changecontainer .col-md-4 {
  padding: 0 !important;
}

.containerbody.d0.active .searchbar {
  /* transition: all 0.5s ease-in-out; */
  transform: translateY(-50px);
}

.containerbody.d0.active .overlay {
  background-color: #ECECEC;
}

.overlay {
  background-color: #ECECEC;
}

.containerbody.d0 .changecontainer>.row .row {
  /* width: 100%; */
  padding: 0;
  height: 546px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;

  padding: 0.519px 1px 0.204px 12px;
  border-radius: 10px 13.194px 10px 10px;

}

.containerbody.d0.active .changecontainer>.row {
  margin-top: -40px;
  /* transition: all 3s ease-in-out; */
}

.filter-dropdowm {
  position: absolute;
  /* width: 1120px; */
  z-index: 20;
  left: 0px;
  height: 131px;
  border-radius: 20px;
  background: #FFF;
  display: flex;
  justify-content: start;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.50);
  margin-top: 7px;
}

.dropdown0 {

  padding: 25px;
  box-sizing: border-box;
}

.dropdown0 .drop1 {
  width: 199px;
  height: 69px;
  border-radius: 10px;
  border: 2px solid #000;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 23px;
  cursor: pointer;

}

.dropdown0 .drop1 span {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 20px;
  box-sizing: border-box;
}

.dropdown0 .options {
  width: 199px;
  height: 146px;
  flex-shrink: 0;
  position: relative;
  margin-top: 3px;
  box-sizing: border-box;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: none;
}

.dropdown0.active .options,
.restroom-select.active .options0,
.categories-select.active .options1 {
  display: flex;
  width: 194px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  position: absolute;
  top: 70px;
  left: 0px;
  z-index: 222;
}

.dropdown0 .options .option {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.20);
  width: 100%;

}

.dropdown0 .options .option .option-test {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* ============================================================================================= */

.restroom-select {
  padding: 25px 0;
  box-sizing: border-box;
}

.restroom-select .select-room {
  width: 199px;
  height: 69px;
  border-radius: 10px;
  border: 2px solid #000;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 23px;
  cursor: pointer;

}

.restroom-select .select-room span {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 20px;
  box-sizing: border-box;
}

.restroom-select .options0 {
  width: 199px;

  flex-shrink: 0;
  position: relative;
  margin-top: 3px;
  box-sizing: border-box;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: none;
}

.restroom-select.active .options0,
.restroom-select.active .options0 {
  display: flex;
  width: 194px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.restroom-select .options0 .option0 {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.20);
  width: 100%;

}

.restroom-select .options0 .option0 .option-testRoom {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* ================ */
.categories-select {
  padding: 25px;
  box-sizing: border-box;
}

.categories-select .select-categories {
  width: 199px;
  height: 69px;
  border-radius: 10px;
  border: 2px solid #000;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 23px;
  cursor: pointer;

}

.categories-select .select-categories span {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 20px;
  box-sizing: border-box;
}

.categories-select .options1 {
  width: 199px;

  flex-shrink: 0;
  position: relative;
  margin-top: 3px;
  box-sizing: border-box;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: none;
}

.categories-select.active .options1 {
  display: flex;
  width: 210px;
  padding: 10px 10px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.categories-select .options1 .option1 {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 8.8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.20);
  width: 100%;

}

.categories-select .options1 .option1 .option-Categories {
  color: #000;
  font-size: 13.023px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filter li img {
  width: 19.535px;
  height: 19.535px;
  filter: grayscale(100%) brightness(0%) contrast(111111) !important;
}

.filter li {
  display: flex;
  padding: 2.442px 1.628px;
  align-items: center;
  gap: 8.14px;
  align-self: stretch;
}

.filter0 .filter-dropdowm .asst {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 290px;
}


.filter0 .filter-dropdowm .asst .filterr0 {
  display: flex;
  align-items: center;
  width: 125px;
  align-items: center;

}


.filter0 .filter-dropdowm .asst .filterr0 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.filter0 .filter-dropdowm .asst .filterr0 input[type="checkbox"]:after {
  content: '';
}

.filter0 .filter-dropdowm .asst .filterr0 label {

  width: 120px;
  height: 29px;
  padding: 8.796px;
  box-sizing: border-box;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  display: flex;

}

.filter0 .filter-dropdowm .asst .filterr0 label h5 {
  color: #263069;
  font-size: 17.593px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter0 .filter-dropdowm .asst .filterr0 label img {
  fill: #263069;
  border-radius: 5px;
  border: 2px solid #263069;
  background: #FFF;
  display: flex;
  width: 33px;
  height: 33px;
  padding: 6px 8px 6px 7px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

}

.filter0 .filter-dropdowm .asst .filterr0 input[type="checkbox"]:checked~label img {
  fill: #FFFFFF;
  background-color: #263069;

}

.filter0 .filter-dropdowm .asst .filterr00 {
  display: flex;
  align-items: center;
  width: 120px;
  align-items: center;

}


.filter0 .filter-dropdowm .asst .filterr00 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.filter0 .filter-dropdowm .asst .filterr00 input[type="checkbox"]:after {
  content: '';
}

.filter0 .filter-dropdowm .asst .filterr00 label {

  width: 170px;
  height: 29px;
  padding: 8.796px;
  box-sizing: border-box;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  display: flex;

}

.filter0 .filter-dropdowm .asst .filterr00 label h5 {
  color: #263069;
  font-size: 17.593px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter0 .filter-dropdowm .asst .filterr00 label img {
  fill: #fff;
  stroke-width: 1.55px;
  stroke: #263069;
  border-radius: 5px;
  display: flex;

}


.filter0 .filter-dropdowm .asst .filterr00 input[type="checkbox"]:checked~label img {
  fill: #263069;
  stroke: #FFFFFF;
}

.filter0 .filter-dropdowm .asst .filterr01 {
  display: flex;
  align-items: center;
  width: 125px;
  align-items: center;

}


.filter0 .filter-dropdowm .asst .filterr01 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.filter0 .filter-dropdowm .asst .filterr01 input[type="checkbox"]:after {
  content: '';
}

.filter0 .filter-dropdowm .asst .filterr01 label {

  width: 120px;
  height: 29px;
  padding: 8.796px;
  box-sizing: border-box;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  display: flex;

}

.filter0 .filter-dropdowm .asst .filterr01 label h5 {
  color: #263069;
  font-size: 17.593px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter0 .filter-dropdowm .asst .filterr01 label img {
  fill: #fff;
  stroke-width: 1.55px;
  stroke: #263069;
  border-radius: 5px;
  display: flex;

}

.filter0 .filter-dropdowm .asst .filterr01 input[type="checkbox"]:checked~label img {
  fill: #263069;
  stroke: #FFFFFF;
}

.filter0 .filter-dropdowm .asst .filterr02 {
  display: flex;
  align-items: center;
  width: 120px;
  align-items: center;

}


.filter0 .filter-dropdowm .asst .filterr02 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.filter0 .filter-dropdowm .asst .filterr02 input[type="checkbox"]:after {
  content: '';
}

.filter0 .filter-dropdowm .asst .filterr02 label {

  width: 120px;
  height: 29px;
  padding: 8.796px;
  box-sizing: border-box;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  display: flex;

}

.filter0 .filter-dropdowm .asst .filterr02 label h5 {
  color: #263069;
  font-size: 17.593px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter0 .filter-dropdowm .asst .filterr02 label img {
  fill: #fff;
  stroke-width: 1.56px;
  stroke: #263069;
  border-radius: 5px;
  display: flex;

}

.filter0 .filter-dropdowm .asst .filterr02 input[type="checkbox"]:checked~label img {
  fill: #263069;
  stroke: #FFFFFF;
}

.filter0 .filter-dropdowm .reset {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.filter0 .filter-dropdowm .reset div {
  width: 100%;

}

.filter0 .filter-dropdowm .reset div a {
  color: #B92525;
  text-align: center;
  font-size: 15.396px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;

}


.searchbar .search-for-restroom {
  position: relative;
}

.searchbar .search-for-restroom-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
}

.searchbar .gislocation-poi-o-icon {
  width: 29px;
  position: relative;
  height: 29px;
  overflow: hidden;
  flex-shrink: 0;
}

.gislocation-poi-o-icon.checked {
  display: none;
}

#nearby:checked~label .gislocation-poi-o-icon.checked {
  display: block;
}

#nearby:checked~label .search-for-restroom {
  color: #CEE4FF;
}

#nearby:checked~label .gislocation-poi-o-icon {
  display: none;
}

.searchbar .gislocation-poi-o-parent {
  width: 111px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  gap: 8.8px;
  font-size: 17.59px;
}

.searchbar .frame-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.searchbar .frame-wrapper {
  width: 424px;
  height: 64.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.searchbar .linevertical-icon {
  width: 36.4px;
  position: relative;
  height: 38.7px;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: -22px;
}

.searchbar .frame-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.searchbar .component-12-icon {
  width: 29px;
  position: relative;
  border-radius: 4.4px;
  height: 29px;
  overflow: hidden;
  flex-shrink: 0;
}

.searchbar .component-12-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 8.8px;
}

.searchbar .frame-parent1 {
  height: 64.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4.4px;
  margin-left: -11.4px;
  font-size: 17.59px;
}

.searchbar .frame-group {
  width: 943px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.searchbar .funnelsimple-icon {
  width: 30.8px;
  position: relative;
  margin-left: 10px;
  height: 30.8px;
  overflow: hidden;
  flex-shrink: 0;
}

.searchbar .filters-parent {
  width: 145.1px;
  border-left: 1.8px solid #000;
  box-sizing: border-box;
  height: 64.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 8.8px;
  cursor: pointer;
}

.searchbar .filters-parent {
  color: #263069;
}

.searchbar .search {
  position: relative;
  font-weight: 500;
}

.searchbar .magnifyingglass-icon {
  width: 35.2px;
  position: relative;
  height: 35.2px;
  overflow: hidden;
  flex-shrink: 0;
}

.searchbar .search-parent {
  width: 158.3px;
  border-radius: 0px 13.19px 13.19px 0px;
  background-color: #263069;
  height: 64.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  gap: 8.8px;
  cursor: pointer;
  color: #fff;
}

.searchbar .frame-parent2 {
  width: 303.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: -17.6px;
  font-size: 21.99px;
}

.searchbar .frame-parent {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 4.84px rgba(0, 0, 0, 0.6);
  border-radius: 13.19px;
  background-color: #edeaea;
  display: flex;
  margin: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 17px;
  color: #263069;
}

.searchbar .frame-parent input {
  background-color: #edeaea;
  width: 210px;

}

.searchbar .component-2-child {
  position: absolute;
  top: 12px;
  left: 0px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #000;
  box-sizing: border-box;
  width: 199px;
  height: 69px;
}

.searchbar .component-2-item {
  position: absolute;
  top: -6px;
  left: 15px;
  background-color: #fff;
  width: 77px;
  height: 36px;
}

.searchbar .gender {
  position: absolute;
  top: -6px;
  left: 21px;
}

.searchbar .select {
  position: relative;
  font-weight: 300;
}

.searchbar .vector-icon {
  width: 11px;
  position: relative;
}

.searchbar .caretdown {
  width: 34px;
  position: relative;
  height: 34px;
  overflow: hidden;
  flex-shrink: 0;
}

.searchbar .select-parent {
  position: absolute;
  top: calc(50% - 10.5px);
  left: calc(50% - 84.5px);
  width: 145px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 22px;
  font-size: 15px;
}

.searchbar .component-2 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 199px;
  height: 81px;
  font-size: 18px;
}

.searchbar .select-group {
  position: absolute;
  top: calc(50% - 11.5px);
  left: calc(50% - 72.5px);
  width: 145px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 22px;
}

.searchbar .component-3-item {
  position: absolute;
  top: -4px;
  left: 14px;
  background-color: #fff;
  width: 140px;
  height: 24px;
}

.searchbar .restroom-type {
  position: absolute;
  top: -7px;
  left: 17px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 29px;
}

.searchbar .component-3 {
  position: absolute;
  top: 0px;
  left: 231px;
  width: 199px;
  height: 81px;
}

.searchbar .component-4-item {
  position: absolute;
  top: -2px;
  left: 14px;
  background-color: #fff;
  width: 120px;
  height: 24px;
}

.searchbar .categories {
  position: absolute;
  top: -5px;
  left: 17px;
  font-size: 18px;
  display: inline-block;
  width: 114px;
  height: 28px;
}

.searchbar .component-4 {
  position: absolute;
  top: 0px;
  left: 462px;
  width: 199px;
  height: 81px;
}

.searchbar .component-2-parent {
  position: absolute;
  top: 17px;
  left: 22px;
  width: 661px;
  height: 81px;
  text-align: center;
  font-size: 15px;
  color: #000;
}

.searchbar .reset {
  position: relative;
  text-decoration: underline;
  font-weight: 600;
}

.searchbar .reset-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.searchbar .vector-icon3 {
  width: 16.7px;
  position: relative;
  height: 16px;
}

.searchbar .clockclockwise {
  width: 20.5px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2.6px 1.7px;
  box-sizing: border-box;
}

.searchbar .hover {
  position: absolute;
  top: 76px;
  left: 988px;
  border-radius: 8.34px;
  width: 72px;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 23.1px;
  box-sizing: border-box;
  gap: 6.4px;
  text-align: center;
  font-size: 15.4px;
  color: #b92525;
}

.searchbar .component-17-icon {
  width: 33px;
  position: relative;
  border-radius: 5px;
  height: 33px;
  overflow: hidden;
  flex-shrink: 0;
}

.searchbar .component-17-icon.active {
  display: none;
  width: 33px;
  position: relative;
  border-radius: 5px;
  height: 33px;
  overflow: hidden;
  flex-shrink: 0;
}

.searchbar .shower {
  position: relative;
}

.searchbar .component-17-parent {
  position: absolute;
  top: 29px;
  left: 710px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8.4px;
  gap: 8.4px;
}

.searchbar .component-19-parent {
  position: absolute;
  top: 29px;
  left: 852px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8.4px;
  gap: 8.4px;
}

.searchbar .component-18-parent {
  position: absolute;
  top: 65px;
  left: 710px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8.4px;
  gap: 8.4px;
}

.searchbar .component-21-parent {
  position: absolute;
  top: 65px;
  left: 852px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8.4px;
  gap: 8.4px;
}

.searchbar .vector-icon4 {
  position: absolute;
  top: 36px;
  left: 1013px;
  width: 20px;
  height: 20px;
}

.searchbar .filter {
  width: max-content;
  position: relative;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  background-color: #fff;
  height: 131px;
  max-width: 90%;
  max-height: 90%;
  /* overflow: au .to; */
  text-align: left;
  z-index: 222;
  font-size: 16.89px;
  color: #263069;
}


#filterContainer {
  position: relative;
  background-color: #fff;
  background: #fff;
  z-index: 2222;
  width: max-content;
}

.pofilter {
  position: absolute;
  width: inherit;
  right: 30px;
  width: 86%;
  border-radius: 17.59px;
  box-shadow: 3.52px 3.52px #00000040;
  background-color: #fff;
  background: #fff;
  z-index: 22;
}

.component-12-icon.active {
  display: none;
}

.frame-parent1 input[type="checkbox"]:checked+label img {
  display: none;
}

.frame-parent1 input[type="checkbox"]:checked+label img.active {
  display: block;
}

.filter input[type="checkbox"]:checked+label img {
  display: none;
}

.filter input[type="checkbox"]:checked+label img.active {
  display: block;
}

/* ========================================================================================= */
.containerbody .searchbar .main .imgleft {
  position: absolute;

  left: -13px;
  bottom: 40px;

}

.containerbody .searchbar .main .imgright {
  position: absolute;
  right: -13px;
  bottom: 40px;
}




/* page about us */


/* navbar button */

.container0 .navbar .gap0 {
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;



}

.container0 .navbar .gap0 ul li a {
  text-decoration: none;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

}

.container0 .navbar .gap0 ul li {
  gap: 10px;
}

/* end */

.about-us {
  min-height: 100vh;
  padding-top: 80px;
  overflow: hidden;
  position: relative;
}

.aboutp-img {
  position: absolute;
  top: -75%;
  left: 50%;
  width: 100%;
  height: 170vh;
}

.about-us .display {
  display: flex;
}

.about-us .display .man img {
  position: absolute;
  width: 416.06px;
  height: 416.06px;
  left: 15%;
  transform: rotate(2deg);
  z-index: -10;
  top: -8%;
}

.modal-content {
  background-color: transparent !important;
  border: none;
}

.popReview.modal-body {
  width: 140% !important;
  margin-top: 100px;
  margin-left: -15%;
}

.about-us .display .typegraphy0 {

  width: 55%;
  min-height: 420px;

  z-index: 20;
  background: #0000001A;
  top: 233.37px;
  left: 341.3px;
  padding: 21.99px 0px 0px 0px;
  gap: 8.8px;
  border: 0.44px solid;
  border-image-source: linear-gradient(180deg, #000000 -13.21%, #FFFFFF 102.83%);
  backdrop-filter: blur(5px);
  padding: 22px;
  text-align: justify;
  border-radius: 10px;
  margin: auto;
  margin-top: 70px;


}

.about-us .display .location {
  position: absolute;
  top: 50%;
  right: 12.5%;
  z-index: 222;
}

.container0 nav .gap0 ul li {

  width: 221.67px;
  text-align: center;
  background: rgba(38, 48, 105, 1);
  color: #FFF;
  align-items: center;
  padding: 8.8px;

  border-radius: 13.19px;


}

.container0 nav .gap0 ul li img {

  width: 21.11px;
  height: 21.11px;


}

.about-us .display .typegraphy0 span {
  font-size: 21.11px;
  font-weight: 500;
  line-height: 42.22px;
  text-align: center;
  color: #263069;
  ;


}


/* end about */





/* add */

.mapAdd {
  width: 100%;
  height: 292.917px;
  flex-shrink: 0;
  top: 87px;
  box-sizing: border-box;
  background-color: #CEE4FF;
  box-shadow: 0px 3.519px 3.519px 0px rgba(0, 0, 0, 0.25);
  margin-top: -20px;
}

.mapAdd img {
  height: 292.917px;
  width: 100%;
}

.container-add {
  position: relative;
  width: 90%;
  margin: auto;
  padding-top: 30px;
  /* top: 460px; */
}

#filePhoto {
  width: 0px;
}

.labelphoto {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-add .child {

  margin: auto;
}

.container-add .child .child-01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

.container-add .child .child-01 .child0 {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 37px;
}

.container-add .child .child-01 .child1 .child2 {
  display: flex;
  width: 570px;
  height: 165px;
  padding: 0px 46px 0px 47px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1.5px solid #000;

}

.container-add .child .child-01 .child1 .child2 .title0 {
  width: 149px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: 1.5px solid #263069;
  background: #FFF;
  position: relative;
}

.container-add .child .child-01 .child1 .child2 .title0 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
}

.container-add .child .child-01 .child1 .child2 .title0 input[type="checkbox"]:after {
  content: '';
}

.container-add .child .child-01 .child1 .child2 .title0 label {
  position: absolute;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.container-add .child .child-01 .child1 .child2 .title0 label h5 {
  color: #263069;
  font-size: 12px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
  line-height: normal;
}

.container-add .child .child-01 .child1 .child2 .title0 label img {
  fill: #263069;
}

.container-add .child .child-01 .child1 .child2 .title0 input[type="checkbox"]:checked {
  background-color: #263069;
  border-radius: 5px;
  border: #ffffff;



}

.container-add .child .child-01 .child1 .child2 .title0 input[type="checkbox"]:checked~label h5 {
  color: #FFF;
}

.container-add .child .child-01 .child1 .child2 .title0 input[type="checkbox"]:checked~label img {
  fill: #FFF;
}











.d00 {
  background: #ECECEC;
  height: 100vh;
}

.containerbody .searchbar0 {
  width: 1249.95px;
  height: 177.69px;
  top: 95.43px;
  left: 134.58px;
  padding: 10.72px 12.31px 14.79px 14.07px;
  gap: 0px;
  border-radius: 17.59px;
  box-shadow: 3.52px #00000040;
  background-color: #FFFFFF;
}

.containerbody .searchbar0 .input-search0 {
  width: 1247px;
  height: 64.21px;
  border: 0.88px;

  border-radius: 13.194px;
  background: #EDEAEA;
  box-shadow: 0px 0px 4.838px 0px rgba(0, 0, 0, 0.60);

}

.containerbody .searchbar0 .input-search1 {
  display: flex;
  width: 393.19px;
  height: 46.62px;
  gap: 15px;


}


.containerbody .searchbar0 .input-search0 .input-search1 {
  width: 423.98px;
  height: 64.21px;
  z-index: 10;

}


.containerbody .searchbar0 .input-search0 .input-search1 .form-controlsearch {
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  width: 308px;
  height: 43.59px;
  padding: 8.8px;
  gap: 8.8px;
  color: #263069;
  border: none;
  border-radius: 13.19px;
  top: 1.5px;
  outline: none;
  background-color: #EDEAEA;

}

.containerbody .searchbar0 .input-search0 .input-search1 button {
  width: 96.76px;
  height: 46.62px;
  padding: 8.8px;
  left: 375px;


}

.containerbody .searchbar0 .input-search0 .input-search1 .display-img {
  display: flex;
  width: 96.76px;
  height: 46.62px;
  padding: 8.8px;
  align-items: center;
}

.containerbody .searchbar0 .input-search0 .input-search1 img {
  width: 29.03px;
  height: 29.03px;
  gap: 0px;
  left: 350px;


}

.containerbody .searchbar0 .input-search0 .input-search1 button {
  font-size: 17.59px;
  font-weight: 400;
  text-align: left;
  border: none;
  background-color: #EDEAEA;
  color: #263069;
}



.containerbody .searchbar0 .input-search0 .frame0 {

  height: 64.21px;
  gap: 4.4px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 10;
  left: 480px;
  align-items: center;
}


.containerbody .searchbar0 .input-search0 .frame0 .title101 {
  display: flex;
  align-items: center;
  width: 150px;
  align-items: center;


}

.containerbody .searchbar0 .input-search0 .frame0 .title101 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.containerbody .searchbar0 .input-search0 .frame0 .title101 input[type="checkbox"]:after {
  content: '';
}

.containerbody .searchbar0 .input-search0 .frame0 .title101 label {

  width: 190px;
  height: 29px;
  padding: 8.796px;
  box-sizing: border-box;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  display: flex;
}

.containerbody .searchbar0 .input-search0 .frame0 .title101 label h5 {
  color: #263069;
  font-size: 17.593px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.containerbody .searchbar0 .input-search0 .frame0 .title101 label img {
  fill: #FFF;
  stroke: #263069;
}




.containerbody .searchbar0 .input-search0 .frame0 .title101 input[type="checkbox"]:checked~label img {
  fill: #263069;
  stroke: #ffffff;

}




.containerbody .searchbar0 .input-search0 .frame0 .frame1 {
  left: -20px;
  display: flex;
  align-items: center;
}

.containerbody .searchbar0 .input-search0 .filter0 {
  right: 125px;

}

.containerbody .searchbar0 .input-search0 .filter0 .frame5 a {
  width: 145.14px;
  height: 64.21px;
  padding: 8.8px;
  box-sizing: border-box;
  gap: 8.8px;
  text-decoration: none;
  border-left: 1.759px solid #000;
  font-size: 17.59px;
  font-weight: 400;
  line-height: 26.39px;
  text-align: left;
  color: #263069;
  display: flex;
  align-items: center;
  gap: 8px;
}

.containerbody .searchbar0 .input-search0 .search-logo {
  right: 4px;
  height: 64.21px;

  padding: 8.8px;
  box-sizing: border-box;
  gap: 8.8px;
  border-radius: 0px 13.19px 13.19px 0px;
  display: flex;
  justify-content: center;
  align-items: center;



}

.containerbody .searchbar0 .input-search0 .search-logo a {
  width: 140px;
  height: 64.21px;
  gap: 8.8px;
  border: 0px 0px 0px 1.76px;
  border-radius: 0px 13.19px 13.19px 0px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 17.59px;
  font-weight: 400;
  line-height: 26.39px;
  background-color: #263069;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;


}










.containerbody .searchbar0 .main {
  position: static;
  margin-top: 25px;

}

.containerbody .searchbar0 .main .item-div {
  display: flex;

  gap: 20px;

  overflow-x: auto;



}

.containerbody .searchbar0 .main .item-div::-webkit-scrollbar {
  display: none;
}



.containerbody .searchbar0 .main .item-div .title10 {
  min-width: 125px;
  height: 83px;

  margin: auto;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  position: relative;
  align-items: center;
  background: #FFF;
  justify-content: center;


}

.containerbody .searchbar0 .main .item-div .title10 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.containerbody .searchbar0 .main .item-div .title10 input[type="checkbox"]:after {
  content: '';
}

.containerbody .searchbar0 .main .item-div .title10 label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.containerbody .searchbar0 .main .item-div .title10 label h5 {
  color: #263069;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.containerbody .searchbar0 .main .item-div .title10 label img {
  fill: #263069;
}

.containerbody .searchbar0 .main .item-div .title10 input[type="checkbox"]:checked {
  background-color: #263069;
  border-radius: 7px;




}

.containerbody .searchbar0 .main .item-div .title10 input[type="checkbox"]:checked~label h5 {
  color: #FFF;
}

.containerbody .searchbar0 .main .item-div .title10 input[type="checkbox"]:checked~label img {
  fill: #FFF;

}



.containerbody .searchbar0 .main .imgleft {
  left: -13px;
  bottom: 40px;

}

.containerbody .searchbar0 .main .imgright {
  right: -13px;
  bottom: 40px;
}

.containerbody .search-card {
  top: 305px;


  width: 921.852px;
  height: 430px;
  padding: 20px 12.852px 0.204px 12px;
  box-sizing: border-box;

  border-radius: 0px 13.194px 0px 0px;
  background: rgba(255, 255, 255, 0.90);

}


.containerbody .search-card .search-card0 {
  margin-top: 15px;
  display: flex;
  width: 897px;
  align-items: flex-start;
  height: 380px;
  overflow-y: auto;
  flex-shrink: 0;
  row-gap: 13px;
  position: relative;
  flex-wrap: wrap;
  gap: 4px;

}

.containerbody .search-card .search-card0::-webkit-scrollbar {
  display: none;
}

.containerbody .search-card .search-card0 .card {
  min-width: 294.428px;
  min-height: 313.592px;
  border-radius: 15px 15px 8.711px 8.711px;
  border: 0.88px solid #000;
  background: #FFF;

  box-shadow: 0px 0px 4.355px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}

.containerbody .search-card .search-card0 .card .img-card {
  margin-left: 5px;
  position: relative;

}

.containerbody .search-card .search-card0 .card .img-card .img-card0 {
  display: flex;
  width: 283.804px;
  height: 186.42px;
  overflow-y: auto;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  margin-top: 5px;
  gap: 9px;
}

.containerbody .search-card .search-card0 .card .img-card .img-card0 .img-card1 {
  animation: sliider 10s ease-in-out infinite step-start;

}

.containerbody .search-card .search-card0 .card .img-card .img-rihgt {
  left: 250px;
  top: 86px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer;
}

.containerbody .search-card .search-card0 .card .img-card .img-lift {
  left: 9px;
  top: 86px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer;
}

.starFov {
  display: flex;
  width: 38.026px;
  height: 38.026px;
  padding: 8.347px;
  box-sizing: border-box;
  align-items: center;
  gap: 9.275px;
  flex-shrink: 0;
  border-radius: 19.013px;
  background: rgba(255, 255, 255, 0.90);
  top: 10px;
  right: 10px;
}




.containerbody .search-card .search-card0 .card .starFov input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.containerbody .search-card .search-card0 .card .holder .btnn {
  background-color: #D9D9D9;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  gap: 5px;
  display: flex;
}

.containerbody .search-card .search-card0 .card .holder {
  display: flex;
  gap: 5px;
  left: 45%;
  top: 170px;
}

@media only screen and (min-width:1500px) {
  .aboutp-img {
    position: absolute;
    top: -80%;
    left: 50%;
    width: 100%;
    height: 170vh;
  }

}

@media screen and (min-height: 780px) {
  .aboutp-img {
    top: -85%;

  }
}

@keyframes sliider {
  0% {
    transform: translateX(0%);
  }

  10% {
    transform: translateX(-100%);
  }

  20% {
    transform: translateX(-100%);
  }

  30% {
    transform: translateX(-200%);
  }

  40% {
    transform: translateX(-200%);
  }

  50% {
    transform: translateX(-200%);
  }

  60% {
    transform: translateX(-300%);
  }

  70% {
    transform: translateX(-300%);
  }

  80% {
    transform: translateX(-300%);
  }

  90% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(0%);
  }
}

.containerbody .search-card .search-card0 .card .starFov input[type="checkbox"]:after {
  content: '';
}

.containerbody .search-card .search-card0 .card .vectorr {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 3px;

}

.containerbody .search-card .search-card0 .card .vectorr .v1 {
  display: flex;
  width: 200.768px;
  height: 15.767px;
  align-items: flex-end;
  gap: 10px;
}

.containerbody .search-card .search-card0 .card .vectorr .v1 span {
  color: rgba(0, 0, 0, 0.70);
  text-align: center;
  font-family: "Alexandria";
  font-size: 12.985px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.containerbody .search-card .search-card0 .card .vectorr .v2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;


}

.containerbody .search-card .search-card0 .card .vectorr .v2 {
  color: rgba(0, 0, 0, 0.70);
  font-size: 11.034px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.containerbody .search-card .search-card0 .card .type-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
}

.containerbody .search-card .search-card0 .card .type-card .t1 span {
  color: #000;
  text-align: center;
  font-family: "Alexandria";
  font-size: 18.549px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3px;

}

.containerbody .search-card .search-card0 .card .type-card .t2 {
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.containerbody .search-card .search-card0 .card .type-card .t2 .s1 a {
  color: #231FFF;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.containerbody .search-card .search-card0 .card .starFov label {

  display: flex;
  width: 38.026px;
  height: 38.026px;
  padding: 8.347px;
  gap: 9.275px;
  flex-shrink: 0;
  padding: 8.796px;
  box-sizing: border-box;
  left: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 19.013px;
}


.containerbody .search-card .search-card0 .card .starFov svg {
  fill: #FFC42E;

}



.containerbody .search-card .search-card0 .card .starFov input[type="checkbox"]:checked~label img {
  fill: #0e0c05;
}


.containerbody .search-card .search-card0 .card .crd-dire {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  box-sizing: border-box;
  margin-top: 5px;
}

.containerbody .search-card .search-card0 .card .crd-dire .crd1 {
  display: flex;
  align-items: center;
  gap: 6.5px;
}

.containerbody .search-card .search-card0 .card .crd-dire .crd1 .svg01 {
  display: inline-flex;
  height: 24.937px;
  padding: 6.715px 7.834px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 11.192px;
  flex-shrink: 0;
  border-radius: 5.596px;
  background: #0061FF;
}

.containerbody .search-card .search-card0 .card .crd-dire .crd1 div a {
  color: #231FFF;
  font-size: 10.45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.containerbody .search-card .search-card0 .card .crd-dire .crd0 {
  gap: 3px;
  display: flex;
  flex-direction: column;
}

.containerbody .search-card .search-card0 .card .crd-clo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  box-sizing: border-box;
}

.containerbody .search-card .search-card0 .card .crd-clo .clo {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 13.912px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 4px;

}

.containerbody .search-card .search-card0 .card .paid {
  top: 135px;
  right: 14px;
  display: flex;
  align-items: center;
  width: 79px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 5px;
  opacity: 0.85;
  background: #263069;
  justify-content: center;
  gap: 4px;
}

.containerbody .search-card .search-card0 .card .paid .dollar {
  display: flex;
  width: 25.174px;
  height: 25.174px;
  padding: 5.526px 7.982px;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  gap: 6.14px;
  flex-shrink: 0;
  border-radius: 12.587px;
  background: rgba(255, 255, 255, 0.90);
}

.containerbody .search-card .search-card0 .card .paid .paid0 {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.containerbody .search-card .search-card0 .card .crd-clo .clo .cole {
  color: #F61E1E;
  font-size: 13.912px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.containerbody .search-card .search-card0 .card .crd-clo .clo .lin {
  width: 0.927px;
  height: 17.622px;
  background: #000;
}

.containerbody .search-card .search-card0 .card .crd-clo .crd-c a {
  color: #231FFF;
  font-size: 13.912px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}








/* =------------------------------------------------------------------ */


.containerbody .search-card .search-card0 .card .img-card .img-card0::-webkit-scrollbar {
  display: none;
}


/* end search -------------------------------------------- */









.container-add .child .child-01 .child1 {
  position: relative;
}

.container-add .child .child-01 .child1 .btn1 {
  width: 149px;
}

.container-add .child .child-01 .child1 .gender {
  position: absolute;
  color: #000;
  left: 0;
  font-size: 18px;
  pointer-events: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  top: -17px;
  left: 20px;
  background-color: #FFF;
  padding: 0 10px;
}




.container-add .child .child-01 .child0 .inputBox0 {
  position: relative;
}

.container-add .child .child-01 .child0 .inputBox0 input {
  width: 358px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #000;
  background: #FFF;
  outline: none;


}

.container-add .child .child-01 .child0 .inputBox0 textarea {
  padding-top: 20px;
  height: 180px;
  flex-shrink: 0;
  width: 80vw;
  border-radius: 10px;
  border: 2px solid #000;
  background: #FFF;
  outline: none;
  margin-top: 30px;
}

.container-add .child .child-01 .child0 .inputBox0 textarea::placeholder {
  color: #000;
  font-size: 14px;
  padding: 30px;
}

.container-add .child .child-01 .child0 .inputBox0 span {
  position: absolute;
  color: #000;
  left: 20px;
  font-size: 18px;
  font-style: normal;
  background: white;
  font-weight: 500;
  line-height: normal;
  padding: 10px;

}

.container-add .child .child-01 .child0 .inputBox0 input:valid~span,
.container-add .child .child-01 .child0 .inputBox0 input:focus~span {
  position: absolute;
  transform: translateX(0px) translateY(-12px);
  font-size: 1em;
  padding: 0 10px;
  background-color: #FFF;

}

.container-add .child .Categories {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 18px;
  width: 100%;
  margin-top: 40px;
  border-radius: 10px;
  border: 1.5px solid rgba(0, 0, 0, 0.70);
  padding: 34px 0px;
  box-sizing: border-box;

}

.container-add .child .Categories .title {
  width: 11.5%;
  height: 83px;
  border-radius: 7px;
  border: 1.5px solid #263069;
  background: #FFF;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

}

.container-add .child .Categories .title input[type="radio"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
}

.container-add .child .Categories .title input[type="radio"]:after {
  content: '';
}

.container-add .child .Categories .title label {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  cursor: pointer;
}

.container-add .child .Categories .title label h5 {
  color: #263069;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.container-add .child .Categories .title label img {
  fill: #263069;
}

.container-add .child .Categories .title input[type="radio"]:checked {
  background-color: #263069;
  border-radius: 5px;
  border: #ffffff;


}

.container-add .child .Categories .title input[type="radio"]:checked~label h5 {
  color: #FFF;
}

.container-add .child .Categories .title input[type="radio"]:checked~label img {
  fill: #FFF;
}

.container-add .child .divb {
  position: absolute;
  left: 110px;
  top: 215px;
  padding: 5px;
  background-color: #FFF;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.container-add .child .line {
  position: relative;
}

.container-add .child .line .line0 {
  width: 690px;
  height: 1px;
  opacity: 0.2;
  background: #000;
  margin: auto;
  top: 420px;
  display: flex;
  justify-content: center;
  left: 210px;
  margin-bottom: 47px;
}

.container-add .child .divRestType {
  position: relative;
  /* top: 370px; */
  display: flex;
  margin: 50px 0;
  gap: 20px;
}

.container-add .child .divRestType .restroomm {
  position: absolute;
  padding: 10px;
  top: -20px;
  left: 15px;
  background-color: #FFFFFF;
  display: flex;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.container-add .child .divRestType .facilitiess {
  position: absolute;
  padding: 10px;
  top: -25px;
  left: 490px;
  background-color: #FFFFFF;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.container-add .child .divRestType .restroomType {

  display: flex;
  width: 454px;
  height: 165px;
  box-sizing: border-box;
  padding: 0px 46px 0px 47px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1.5px solid #000;

}


.container-add .child .divRestType .restroomType .type {
  display: flex;
  width: 149px;
  height: 47px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1.5px solid #263069;
  background: #FFF;

}

.container-add .child .divRestType .restroomType .type input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.container-add .child .divRestType .restroomType .type input[type="checkbox"]:after {
  content: '';
}

.container-add .child .divRestType .restroomType .type label {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.container-add .child .divRestType .restroomType .type label h5 {
  color: #263069;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.container-add .child .divRestType .restroomType .type label img {
  fill: #263069;
}

.container-add .child .divRestType .restroomType .type input[type="checkbox"]:checked {
  background-color: #263069;
  border-radius: 7px;
  border: #ffffff;


}

.container-add .child .divRestType .restroomType .type input[type="checkbox"]:checked~label h5 {
  color: #FFF;
}

.container-add .child .divRestType .restroomType .type input[type="checkbox"]:checked~label img {
  fill: #FFF;
}


.container-add .child .divRestType .facilities {
  display: flex;

  padding: 16px 32px 18px 33px;
  box-sizing: border-box;
  gap: 25px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1.5px solid #000;
}




.container-add .child .divRestType .facilities .type0 {
  display: flex;
  width: 185px;
  height: 47px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1.5px solid #263069;
  background: #FFF;


}

.container-add .child .divRestType .facilities .type0 input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.container-add .child .divRestType .facilities .type0 input[type="checkbox"]:after {
  content: '';
}

.container-add .child .divRestType .facilities .type0 label {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.container-add .child .divRestType .facilities .type0 label h5 {
  color: #263069;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  line-height: normal;
}

.container-add .child .divRestType .facilities .type0 label img {
  fill: #263069;
}

.container-add .child .divRestType .facilities .type0 input[type="checkbox"]:checked {
  background-color: #263069;
  border-radius: 7px;
  border: #ffffff;


}

.container-add .child .divRestType .facilities .type0 input[type="checkbox"]:checked~label h5 {
  color: #FFF;
}

.container-add .child .divRestType .facilities .type0 label img.active {
  display: none;
}

.container-add .child .divRestType .facilities .type0 input[type="checkbox"]:checked~label img {
  display: none;
}

.container-add .child .divRestType .facilities .type0 input[type="checkbox"]:checked~label img.active {
  display: block;
}

.container-add .child .divRestType .facilities .type0 input[type="checkbox"]:checked~label img {
  fill: #FFF;
}



.container-add .child .parent {

  position: relative;
  /* top: 460px; */

}

.container-add .child .parent .centerr {
  display: flex;
  gap: 80px;
  justify-content: center;
  align-items: center;
  left: 230px;
  font-weight: 600;
}

.container-add .child .parent .centerr .child00 {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.container-add .child .parent .centerr .child00 .kl {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.container-add .child .parent .centerr .child00 .do {
  display: flex;
  gap: 20px;
  align-items: center;
}

.container-add .child .parent .centerr .child00 .do .do0 {
  /* border-radius: 3.875px; */
  /* border: 1.55px solid #263069; */
  /* background: #FFF; */
  padding: 3px;
  align-items: center;
}

.container-add .child .parent .centerr .child00 .do00 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-add .child .parent .centerr .child01 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.container-add .child .parent .centerr .child01 .kl {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.container-add .child .parent .centerr .child01 .do {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.container-add .child .parent .centerr .child00 .do .do0,
.container-add .child .parent .centerr .child01 .do .do0 input[type="checkbox"] {
  display: none;
}

.container-add .child .parent .centerr .child00 .do .do0~label img,
.container-add .child .parent .centerr .child01 .do .do0 input[type="checkbox"]~label img {
  width: 30px;
}

.container-add .child .parent .centerr .child00 .do .do0~label img.active,
.container-add .child .parent .centerr .child01 .do .do0 input[type="checkbox"]~label img.active {
  display: none;
}

.container-add .child .parent .centerr .child00 .do .do0:checked~label img,
.container-add .child .parent .centerr .child01 .do .do0 input[type="checkbox"]:checked~label img {
  display: none;
}

.container-add .child .parent .centerr .child00 .do .do0:checked~label img.active,
.container-add .child .parent .centerr .child01 .do .do0 input[type="checkbox"]:checked~label img.active {
  display: block;
}

.container-add .child .parent .centerr .child01 .do .do0 {
  /* border-radius: 3.875px; */
  /* border: 1.55px solid #263069; */
  background: #FFF;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.container-add .child .line1 {
  position: relative;
}

.container-add .child .line1 .line2 {
  width: 690px;
  height: 1px;
  opacity: 0.2;
  background: #000;
  display: flex;
  margin: auto;
  margin-top: 47px;

  justify-content: center;
}

.container-add .child .timee {
  padding: 40px;
  /* top: 930px;
  left: 270px; */


}

.container-add .child .timee .timeline {
  display: flex;
  align-items: center;
  gap: 20px;
}

.container-add .child .timee .timeline .kl01 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}

.container-add .child .timee .checkbox78 .typegraphy01 .dis {
  display: flex;
  width: 111px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 45px;
  font-weight: 500;
  margin-left: 180px;
  margin-top: 15px;
}

.dis .span {
  display: inline-block;
  height: 31px;
}

.container-add .child .timee .checkbox78 .typegraphy01 .ldi {
  display: flex;
  width: 87.012px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
}

.container-add .child .timee .checkbox78 .typegraphy01 {
  display: flex;
  /* align-items: center; */
  gap: 280px;
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme {

  display: flex;
  width: 87.012px;
  flex-direction: column;
  align-items: flex-end;
  gap: 35px;
  margin-top: 20px;
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme span.close {
  color: red;
  font-size: 18px;
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme {
  font-weight: 500;
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme input {
  content: 'closed';
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 0 10px;
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme .time0 {
  content: 'closed';
  /* display: none; */
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme .time01 {
  content: 'closed';
  /* display: none; */
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme .time02 {
  content: 'closed';
  /* display: none; */
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme .time03 {
  content: 'closed';
  /* display: none; */
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme .time04 {
  content: 'closed';
  /* display: none; */
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme .time05 {
  content: 'closed';
  /* display: none; */
}

.container-add .child .timee .checkbox78 .typegraphy01 .timme .time06 {
  content: 'closed';
  /* display: none; */
}


/* --------------------------------------------------------------------------- */


/* The switch - the box around the slider */
.container121 {
  display: flex;
  width: 51px;
  height: 31px;
  position: relative;
}

/* Hide default HTML checkbox */
.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderr {
  position: absolute;
  left: 60px;
  top: 5px;
}

.switch {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #e9e9eb;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

/* .checkbox78 {
  display: none;
} */

/* The slider */
.slider {
  width: 27px;
  height: 27px;
  left: calc(50% - 27px/2 - 10px);
  top: calc(50% - 27px/2);
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.checkbox:checked+.switch {
  background-color: #34C759;
}

.checkbox:checked+.switch .slider {
  left: calc(50% - 27px/2 + 10px);
  top: calc(50% - 27px/2);
}

/* --------------- */
/* The switch - the box around the slider */
.container1211 {
  width: 51px;
  height: 31px;
  margin-top: 15px;
  position: relative;
}

/* Hide default HTML checkbox */
.checkbox0 {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch0 {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #e9e9eb;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

/* The slider */
.slider0 {
  width: 27px;
  height: 27px;
  left: calc(50% - 27px/2 - 10px);
  top: calc(50% - 27px/2);
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.checkbox0:checked+.switch0 {
  background-color: #34C759;
}

.checkbox0:checked+.switch0 .slider0 {
  left: calc(50% - 27px/2 + 10px);
  top: calc(50% - 27px/2);
}


/* end add */


/* start login */

.login0 {
  background: #CEE4FF;

}


/* end  */




/*  */


/*  */






/* start footer */
footer .divFooter {
  background: #263069;

}

footer .divFooter {
  padding-top: 32.5px;
  padding-left: 25.5px;
  padding-right: 25.5px;
  padding-bottom: 2.31px;
}

footer .divFooter .footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21.11px;
  font-weight: 700;
  line-height: 31.67px;
  text-align: left;
  padding-bottom: 14px;
  color: #CEE4FF;
  ;

}

footer .divFooter .typegraphy {
  font-size: 14.07px;
  font-weight: 300;
  line-height: 21.11px;
  text-align: center;
  padding-bottom: 17px;

  color: #FFF;
  height: 42px;


}

footer .divFooter .typegraphy div {
  width: 430px;
  border-top: 0.88px solid #FFFFFF80;
  text-align: center;
  justify-content: center;
  display: inline-flex;
  padding-top: 14.07px;
  box-sizing: border-box;
  color: #E2E2E2;
  text-align: center;
  font-size: 14.074px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


footer .divFooter .link-page {
  display: flex;
  justify-content: center;
  color: #E2E2E2;
  text-align: center;
  font-size: 14.074px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

footer .divFooter .link-page ul {
  display: flex;
  padding: 8.796px;
  align-items: center;
  gap: 27px;

}

footer .divFooter .link-page ul .li9 {
  list-style-type: none;
  margin-top: 26px;

}

footer .divFooter .link-page ul .li9 a {
  color: #FFF;
  text-align: center;
  font-size: 21.111px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  padding: 8.796px;

}

footer .divFooter .logo-footer {
  display: flex;
  justify-content: center;
  gap: 36px;


}

footer .divFooter .logo-footer img:hover {
  transition: 0.5s ease-in-out;
  transform: scale(1.2);
}

footer .divFooter .gapp {
  height: 23px;
}

footer .divFooter .read {
  text-align: center;
  padding: 4.4px 8.8px 4.4px 8.8px;
  gap: 8.8px;
  color: #FFF;
  font-size: 10.56px;
  font-weight: 300;
  line-height: 15.83px;
  text-align: center;





}

footer .divFooter .read.first {
  border-top: 0.88px solid rgba(255, 255, 255, 0.30);
  padding-top: 8.8px;
}

.logofooter {
  color: #AACBF4;
  font-size: 21.111px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mob {
  display: none;
}

.contact,
.login-sign-up,
.login-sign-in {
  /* padding-top: 100px; */
  margin-top: -80px;
}

#nearby {
  display: none;
}

@media screen and (max-width: 1024px) {
  .d0 {
    padding: 100px 10px 0;
  }

  .container-add .child .Categories .title {
    width: 135px;
  }

  #nearby:checked~label {
    background-color: #263069;
    color: #CEE4FF;

  }


  .changecontainer .col-lg-7 {
    width: 100%;
  }

  .containerbody .searchbar .main .item-div {
    padding: 10px;
  }

  .containerbody .searchbar .main .item-div .title10 {
    border: 1px solid #263069;
  }

  .frame0,
  .imgleft,
  .imgright,
  .search-logo {
    display: none !important;
  }

  .searchbar.mobb .search-for-restroom-wrapper {
    width: 49vw !important;
  }

  .containerbody .searchbar {
    background: none;
    box-shadow: none;
    width: 100% !important;
    margin: auto;
  }

  .item-div {
    background-color: transparent;
    border-radius: 12px;
  }

  .containerbody .searchbar .input-search0 .input-search1 .form-controlsearch {
    background: none;

  }
  /* .mob.search{
    color: #fff;
    font-size: 25px;
    padding: 6px 12px;
  } */
  .mob.search{
    color: #fff;
    font-size: 25px;
padding: 15px 20px;
  }
  .navbar-toggler {
    border: none !important;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(38 ,48 ,105)' stroke-width='4' stroke-linecap='round' stroke-miterlimit='20' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .containerbody .searchbar .input-search0 {
    background: none;
    box-shadow: none;

  }

  .containerbody .searchbar .input-search0 .input-search1 .form-controlsearch {
    background-color: #FFF;

  }

  .containerbody .searchbar .input-search0 .input-search1 .display-img {
    background-color: #FFF;
    width: 80%;
    border-radius: 12px;

  }

  /* .frame5 img{
      display: none;
  } */
  .containerbody .searchbar .input-search0 .input-search1 button {
    background: none;
  }



  .desk {
    display: none;
  }

  .mob {
    display: block;
  }

  .mob {
    background-color: #263069;
    /* width: 100px; */
    display: unset !important;
    justify-content: center;
    padding: 20px;
    border-radius: 12px;
  }

  .containerbody .searchbar .input-search0 {
    justify-content: space-between;
  }

  .containerbody .searchbar .input-search0 .input-search1 {
    width: 100%;
  }

  .frame1 {
    display: none;
    opacity: 0;
  }

  .about-us {
    padding-top: 0;

  }

  .about-us .display {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .about-us .display .typegraphy0 span {
    font-size: 16px;
    line-height: 30.22px;
  }

  .containerbody.mape .overlay.filtered {
    z-index: 22 !important;
  }

}

@media screen and (max-width: 767px) {
  .about-us .display .man img {
    width: 300px;
    height: 300px;
  }

  .navbar .navbar-brand span {
    margin-right: 0px;
  }

  .containerbody .searchbar .input-search0 .input-search1 .display-img {
    background-color: #FFF;
    width: 120px;
    position: absolute;
    border-radius: 12px;
    top: 250px;
    right: 0;
  }

  .navbar {
    padding: 0 !important;
  }

  .containerbody .searchbar .input-search0 .input-search1 .form-controlsearch {
    width: 240px !important;
  }

  .item-div {
    background-color: #fff;

  }

  .containerbody .searchbar .main .item-div .title10 {
    min-width: 95px;
    border: 1px solid #263069;
  }
}

.signup-signin .sign-up {
  position: relative;
}

.signup-signin .sign-up-wrapper {
  align-self: stretch;
  width: 157.9px;
  border-radius: 15.79px 0px 0px 15.79px;
  background-color: #ececec;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7.9px;
  box-sizing: border-box;
  cursor: pointer;
}

.signup-signin .sign-in {
  position: relative;
  font-weight: 600;
  font-family: "Alexandria", sans-serif;

}

.signup-signin .sign-in-wrapper {
  align-self: stretch;
  width: 157.9px;
  border-radius: 15.79px;
  background-color: #263069;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7.9px;
  box-sizing: border-box;
  color: #fff;
}

.signup-signin.pass .sign-in-wrapper {
  width: unset;
}

.signup-signin.pass .frame-wrapper6 {
  margin: auto;
}

.signup-signin .frame-group {
  border-radius: 15.79px;
  background-color: #ececec;
  height: 59px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.signup-signin .email-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7.9px;
}

.signup-signin .frame-wrapper2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.signup-signin .enter-your-email {
  position: relative;
  font-weight: 300;
}

.signup-signin .enter-your-email,
.password-password {
  align-self: stretch;
  border-radius: 11.84px;
  outline: none;
  border: none;
  background-color: #eee;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
  padding: 7.9px 11.8px;
  box-sizing: border-box;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Alexandria", sans-serif;

}

.signup-signin .frame-parent2 {
  /* width: 462.7px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.signup-signin .frame-wrapper1 {
  position: absolute;
  top: 0px;
  left: 0px;
  /* width: 462.7px; */
  height: 102.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.signup-signin .eyeslash-icon {
  width: 23.7px;
  position: absolute;
  right: 12px;
  /* top: 0; */
  height: 23.7px;
  overflow: hidden;
  flex-shrink: 0;
}

.signup-signin .enter-your-password-here-parent {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 219px;
}

.signup-signin .frame-wrapper3 {
  /* position: absolute;
  top: 119.23px;
  left: 0px; */
  /* width: 462.7px; */
  height: 102.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.signup-signin .frame-parent1 {
  /* position: absolute;
  top: 0px;
  left: 0px; */
  /* width: 462.7px; */
  height: 221.9px;
}

.signup-signin .frame-div {
  /* position: absolute;
  ma
  top: -15px;
  left: calc(50% - 231.3px); */
  margin-top: 40px;
  /* width: 462.7px; */
  height: 221.9px;
  text-align: left;
}

.signup-signin .sign-in-container {
  align-self: stretch;
  border-radius: 7.9px;
  background-color: #263069;
  height: 61.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7.9px;
  box-sizing: border-box;
  cursor: pointer;
  color: white;
  width: 350px;
  margin: 10px auto;
}

.signup-signin .frame-wrapper6 {
  width: 450.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.signup-signin .create-one {
  position: relative;
  text-decoration: underline;
  color: #231fff;
  cursor: pointer;
}

.signup-signin .need-an-account-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 15px;
  color: #000;
}

.signup-signin .or-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 7.9px;
}

.signup-signin .google-icon {
  width: 32.8px;
  position: relative;
  height: 32.8px;
  overflow: hidden;
  flex-shrink: 0;
}

.signup-signin .sign-in-with {
  position: relative;
  font-weight: 500;
}

.signup-signin .google-parent {
  width: 271px;
  box-shadow: 0px 0.8211016058921814px 3.28px rgba(0, 0, 0, 0.25);
  border-radius: 12.32px;
  background-color: #fff;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.2px;
  box-sizing: border-box;
  gap: 8.2px;
  cursor: pointer;
  font-size: 18.06px;
  color: #000;
}

.signup-signin .frame-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2.4px;
  font-size: 18.95px;
  color: rgba(0, 0, 0, 0.6);
}

.signup-signin .frame-parent4 {
  top: 247.14px;
  left: 78.17px;
  /* width: 593.8px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 24px;
  color: #fff;
}

.signup-signin .forget-password {
  position: relative;
  text-decoration: underline;
  font-weight: 500;
  margin: 1px;
  margin-right: auto;
  margin-left: 100px;
  color: #263069;
  text-align: center;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.signup-signin .forget-password-wrapper {


  cursor: pointer;
  font-size: 12.63px;
  color: #231fff;
  margin-top: 0px;
}

.signup-signin .frame-container {
  margin: auto;
  /* position: absolute; */
  margin-top: 0px;
  top: 54.56px;
  /* left: 45.9px; */
  width: 80%;
  height: 440px;
}

.signup-signin .frame-wrapper {
  width: 875.3px;
  position: relative;
  border-radius: 31.58px;
  background-color: #fff;
  height: 523.5px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 18px;
  color: #000;
}

.signup-signin.pass .frame-wrapper {
  width: 615.3px;
  height: 423.5px;

}
.pass .EyeSlash{
  position: absolute;
  right: 20px;
}
.signup-signin.pass .frame-parent2{
  width: 100%;
}
.signup-signin .frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 6.3px;
}

.signup-signin .login-sign-in-inner {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Alexandria";
}

.signinnnn {
  border-radius: 15.7px !important;
}

.signup-signin .icon-parkpublic-toilet {
  width: 35.2px;
  position: relative;
  height: 35.2px;
  overflow: hidden;
  flex-shrink: 0;
}

.signup-signin .icon-parkpublic-toilet-parent {
  align-self: stretch;
  height: 35.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7.7px;
}

.signup-signin .frame-child {
  align-self: stretch;
  position: relative;
  border-top: 0.9px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  height: 0.9px;
}

.signup-signin .your-guide-to {
  align-self: stretch;
  position: relative;
  font-weight: 300;
}

.signup-signin .line-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.1px;
  text-align: center;
  font-size: 14.07px;
  color: #e2e2e2;
}

.signup-signin .frame-parent9 {
  top: 0px;
  left: 0px;
  width: 429.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.1px;
}

.signup-signin .frame-wrapper8 {
  align-self: stretch;
  position: relative;
  height: 105.6px;
}

.signup-signin .contact {
  flex: 1;
  position: relative;
  font-weight: 500;
}

.signup-signin .contact-wrapper {
  width: 146px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.8px;
  box-sizing: border-box;
  cursor: pointer;
}

.signup-signin .frame-parent10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 17.6px;
  text-align: center;
  color: #fff;
}

.signup-signin .frame-parent8 {
  width: 429.3px;
  height: 175.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 17.6px;
}

.signup-signin .frame-wrapper7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.signup-signin .group-icon {
  width: 40px;
  position: relative;
  height: 40px;
}

.signup-signin .group-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 35.2px;
}

.signup-signin .frame-wrapper10 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.signup-signin .frame-wrapper9 {
  width: 429.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.signup-signin .frame-parent7 {
  top: 32.55px;
  left: calc(50% - 215px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.signup-signin .frame-item {
  align-self: stretch;
  position: relative;
  border-top: 0.9px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  height: 0.9px;
}

.signup-signin .copyright-2024-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.4px 8.8px;
}

.signup-signin .rerest-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.4px 8.8px;
}

.signup-signin .frame-parent11 {
  width: 150.4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup-signin .line-group {
  top: 286.07px;
  left: calc(50% - 734px);
  width: 1468px;
  height: 49px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4.4px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.signup-signin .frame-parent6 {
  bottom: 0.57px;
  left: calc(50% - 760px);
  background-color: #263069;
  width: 1520px;
  height: 339.5px;
  font-size: 21.11px;
  color: #CEE4FF;
}

.signup-signin .frame-inner {
  top: 0px;
  left: 0px;
  box-shadow: 0px 3.5185184478759766px 3.52px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: 1520px;
  height: 88px;
}

.signup-signin .frame-6-10 {
  width: 44px;
  position: relative;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}

.signup-signin .rerest2 {
  position: relative;
  font-weight: 800;
}

.signup-signin .frame-6-10-parent {
  top: 21.99px;
  left: 52.78px;
  width: 173.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.7px;
  cursor: pointer;
  font-size: 28.15px;
}

.signup-signin .plus-icon {
  width: 21.1px;
  position: relative;
  height: 21.1px;
  overflow: hidden;
  flex-shrink: 0;
}

.signup-signin .plus-parent {
  top: 21.99px;
  left: 355.37px;
  border-radius: 13.19px;
  border: 1.8px solid #263069;
  box-sizing: border-box;
  width: 221.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 6.2px;
  cursor: pointer;
}

.signup-signin .vector-icon {
  width: 21.1px;
  position: relative;
  height: 21.1px;
  object-fit: contain;
}

.signup-signin .vector-parent {
  top: 21.99px;
  left: 599.03px;
  border-radius: 13.19px;
  background-color: #263069;
  width: 221.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  gap: 6.2px;
  cursor: pointer;
  color: #fff;
}

.signup-signin .contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  cursor: pointer;
}

.signup-signin .frame-parent12 {
  top: calc(50% - 24.81px);
  left: calc(50% + 190.16px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 55.4px;
  font-size: 21.11px;
}

.signup-signin .rectangle-parent {
  top: 0px;
  left: 0px;
  width: 1520px;
  height: 88px;
  font-size: 17.59px;
  color: #263069;
}

.signup-signin .login-sign-in {
  width: 100%;
  /* position: relative; */
  background-color: #cee4ff;
  height: auto;
  overflow: hidden;
  padding-bottom: 40px;
  text-align: left;
  font-size: 24px;
  color: rgba(118, 118, 118, 0.5);
}

.signup .d-flex {
  justify-content: space-between;
}

.signup input {
  width: 350px !important;
  margin-right: 10px;
}

.signup .eyeslash-icon {
  right: 20px;
}

.contact {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #CEE4FF;
}

.contact .full-name {
  position: relative;
}

.contact .full-name-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.2px;
}

.contact .frame-wrapper1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact .enter-your-full {
  position: relative;
  font-weight: 300;
}

.contact .enter-your-full-name-here-wrapper {
  align-self: stretch;
  border-radius: 12.33px;
  background-color: #eee;
  height: 66.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.2px 12.3px;
  box-sizing: border-box;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.contact .frame-div {
  top: 0px;
  left: 0px;
  width: 425.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact .frame-container {
  align-self: stretch;
  position: relative;
  height: 106.9px;
}

.contact .enter-your-message-here-wrapper {
  align-self: stretch;
  border-radius: 12.33px;
  background-color: #eee;
  height: 172.7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8.2px 12.3px;
  box-sizing: border-box;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.contact .submit-wrapper {
  align-self: stretch;
  border-radius: 8.22px;
  background-color: #263069;
  height: 64.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.2px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  color: #fff;
}

.contact .frame-group {
  /* position: absolute; */
  top: 29.6px;
  left: 40.29px;
  width: 425.1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10.7px;
}

.contact .frame-wrapper {

  margin-left: 20px;
  border-radius: 0px 57.56px 57.56px 0px;
  background-color: #fff;
  width: auto;
  padding-right: 20px;
  height: 582.2px;
  padding: 29.601px 53.447px 31.447px 40.29px;

}

.contact .have-any-questions {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}

.contact .contact-with-us {
  align-self: stretch;
  position: relative;
  font-size: 16.45px;
  font-weight: 600;
  color: #231fff;
}

.contact .have-any-questions-parent {
  padding-top: 30px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5.8px;
}

.contact .undraw-envelope-re-f5j4-1-icon {
  width: 343.7px;
  position: relative;
  height: 280.4px;
  overflow: hidden;
  flex-shrink: 0;
}

.contact .frame-child {
  width: 302.6px;
  position: relative;
  border-top: 0.8px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  height: 0.8px;
}

.contact .facebooklogo-icon {
  width: 28.8px;
  position: relative;
  height: 28.8px;
  overflow: hidden;
  flex-shrink: 0;
}

.contact .vector-icon {
  width: 28.7px;
  position: relative;
  height: 28.7px;
}

.facebooklogo-parent img:hover {
  transition: 0.5s ease-in-out;
  transform: scale(1.2);

}

.contact .facebooklogo-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32.9px;
}

.contact .frame-wrapper6 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.contact .frame-parent3 {
  top: 59.09px;
  left: 9.3px;
  width: 401.3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 31.2px;
  padding: 29.093px 9.607px 59.403px 9.305px;
}

.contact .frame-wrapper5 {
  top: 0px;
  left: 0px;
  box-shadow: 2.466768980026245px 1.64451265335083px 3.29px rgba(0, 0, 0, 0.25);
  border-radius: 57.56px;
  background-color: #fff;
  width: 420.2px;
  height: 582.2px;
  overflow: hidden;
  text-align: center;
  font-size: 19.73px;
  color: #263069;
}

.contact .frame-parent {
  /* position: absolute; */
  /* top: 124.91px;
  left: 281.7px; */
  border-radius: 57.56px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  /* width: 939px; */
  height: auto;
  font-family: "Alexandria";
}

.contact .icon-parkpublic-toilet {
  width: 35.2px;
  position: relative;
  height: 35.2px;
  overflow: hidden;
  flex-shrink: 0;
}

.contact .icon-parkpublic-toilet-parent {
  align-self: stretch;
  height: 35.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7.7px;
}

.contact .frame-item {
  align-self: stretch;
  position: relative;
  border-top: 0.9px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  height: 0.9px;
}

.contact .your-guide-to {
  align-self: stretch;
  position: relative;
  font-weight: 300;
}

.contact .line-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.1px;
  text-align: center;
  font-size: 14.07px;
  color: #e2e2e2;
}

.contact .frame-parent7 {
  top: 0px;
  left: 0px;
  width: 429.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.1px;
}

.contact .frame-wrapper8 {
  align-self: stretch;
  position: relative;
  height: 105.6px;
}

.contact .contact-wrapper {
  width: 146px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.8px;
  box-sizing: border-box;
  cursor: pointer;
}

.contact .about-us {
  flex: 1;
  position: relative;
  font-weight: 500;
}

.contact .frame-parent8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 17.6px;
  text-align: center;
  color: #fff;
}

.contact .frame-parent6 {
  width: 429.3px;
  height: 175.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 17.6px;
}

.contact .frame-wrapper7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.contact .group-icon {
  width: 40px;
  position: relative;
  height: 40px;
}

.contact .group-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 35.2px;
}

.contact .frame-wrapper9 {
  width: 429.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact .frame-parent5 {
  top: 32.55px;
  left: calc(50% - 215px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.contact .frame-inner {
  align-self: stretch;
  position: relative;
  border-top: 0.9px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  height: 0.9px;
}

.contact .copyright-2024-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.4px 8.8px;
}

.contact .rerest-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.4px 8.8px;
}

.contact .frame-parent9 {
  width: 150.4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact .line-group {
  top: 286.07px;
  left: calc(50% - 734px);
  width: 1468px;
  height: 49px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4.4px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.contact .frame-parent4 {
  bottom: 0.57px;
  left: calc(50% - 760px);
  background-color: #263069;
  width: 1520px;
  height: 339.5px;
  font-size: 21.11px;
  color: #CEE4FF;
}

.contact .rectangle-div {
  top: 0px;
  left: 0px;
  box-shadow: 0px 3.5185184478759766px 3.52px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: 1520px;
  height: 88px;
}

.contact .rectangle-icon {
  width: 44px;
  position: relative;
  border-radius: 43.98px;
  height: 44px;
  object-fit: cover;
}

.contact .vector-icon1 {
  width: 14.1px;
  position: relative;
  height: 7.9px;
}

.contact .rectangle-group {
  top: 14.95px;
  left: 1394.21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4.4px;
  gap: 6.2px;
}

.contact .frame-6-10 {
  width: 44px;
  position: relative;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}

.contact .rerest2 {
  position: relative;
  font-weight: 800;
}

.contact .frame-6-10-parent {
  top: 21.99px;
  left: 52.78px;
  width: 173.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.7px;
  cursor: pointer;
  font-size: 28.15px;
}

.contact .plus-icon {
  width: 21.1px;
  position: relative;
  height: 21.1px;
  overflow: hidden;
  flex-shrink: 0;
}

.contact .add-restroom {
  position: relative;
  font-weight: 600;
}

.contact .plus-parent {
  top: 21.99px;
  left: 355.37px;
  border-radius: 13.19px;
  border: 1.8px solid #263069;
  box-sizing: border-box;
  width: 221.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 6.2px;
  cursor: pointer;
}

.contact .vector-icon2 {
  width: 21.1px;
  position: relative;
  height: 21.1px;
  object-fit: contain;
}

.contact .vector-parent {
  top: 21.99px;
  left: 599.03px;
  border-radius: 13.19px;
  background-color: #263069;
  width: 221.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  gap: 6.2px;
  cursor: pointer;
  color: #fff;
}

.contact .contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  cursor: pointer;
}

.contact .frame-parent10 {
  top: calc(50% - 24.81px);
  left: calc(50% + 188.66px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 55.4px;
  font-size: 21.11px;
}

.contact .rectangle-parent {
  top: 0px;
  left: 0px;
  width: 1520px;
  height: 88px;
  font-size: 17.59px;
  color: #263069;
}

.contact .contact {
  width: 100%;
  position: relative;
  background-color: #cee4ff;
  height: 1139px;
  overflow: hidden;
  text-align: left;
  font-size: 18px;
  color: #000;
}


.contact .rectangle-icon1 {
  top: 16.49px;
  left: 18px;
  border-radius: 44.03px;
  width: 61.6px;
  height: 61.6px;
  object-fit: cover;
}

.contact .name-here {
  position: relative;
  font-weight: 500;
}

.contact .name-here-wrapper {
  align-self: stretch;
  height: 26.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 4.4px;
  box-sizing: border-box;
}

.contact .rabie123456gmailcom {
  position: relative;
  font-weight: 300;
}

.contact .rabie123456gmailcom-wrapper {
  align-self: stretch;
  height: 17.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 4.4px;
  box-sizing: border-box;
  font-size: 13px;
}

.contact .frame-parent12 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact .edit-profile {
  position: relative;
  font-weight: 600;
}

.contact .edit-profile-wrapper {
  width: 96.9px;
  border-radius: 13.21px;
  background-color: #263069;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  font-size: 13.21px;
  color: #fff;
}

.contact .frame-parent11 {
  top: 21.13px;
  left: 89.82px;
  width: 182.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12.3px;
  font-size: 14px;
}

.contact .line-div {
  align-self: stretch;
  position: relative;
  border-top: 0.6px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  height: 0.6px;
}

.contact .vector-icon3 {
  width: 22px;
  position: relative;
  height: 22px;
}

.contact .vector-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px 0px;
  gap: 8.8px;
  cursor: pointer;
}

.contact .line-container {
  top: 132.09px;
  left: 29.94px;
  width: 235.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.6px;
}

.contact .vector-icon4 {
  width: 22px;
  position: relative;
  height: 22px;
  object-fit: contain;
}

.contact .line-parent1 {
  top: 197.25px;
  left: 29.94px;
  width: 235.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.6px;
}

.contact .rectangle-root {
  width: 295px;
  position: relative;
  box-shadow: 0px 0.8805970549583435px 3.52px 1.76px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  height: 273px;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  text-align: left;
  font-size: 13.21px;
  color: #000;
}

.error .rectangle-icon {
  width: 100%;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 536.6px;
  flex-shrink: 0;
  object-fit: cover;
}

.re-rest-pass .send-message-1-1 {
  width: 336.9px;
  position: relative;
  height: 311.4px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.re-rest-pass .we-sent-you {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}

.re-rest-pass .please-check-your {
  margin: 0;
}

.re-rest-pass .please-check-your-container {
  width: 375px;
  position: relative;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  display: inline-block;
}

.re-rest-pass .resubmit {
  text-decoration: underline;
  color: #231fff;
}

.re-rest-pass .cant-get-email-container {
  position: relative;
  font-size: 16px;
  line-height: 36px;
  font-weight: 600;
  text-align: left;
}

.re-rest-pass .send-message-1-1-parent {
  top: 138.06px;
  left: calc(50% - 414px);
  width: 827.7px;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 28px;
  text-align: center;
}

.re-rest-pass .icon-parkpublic-toilet {
  width: 35.2px;
  position: relative;
  height: 35.2px;
  overflow: hidden;
  flex-shrink: 0;
}

.re-rest-pass .rerest {
  position: relative;
}

.re-rest-pass .icon-parkpublic-toilet-parent {
  align-self: stretch;
  height: 35.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7.7px;
}

.re-rest-pass .frame-child {
  align-self: stretch;
  position: relative;
  border-top: 0.9px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  height: 0.9px;
}

.re-rest-pass .your-guide-to {
  align-self: stretch;
  position: relative;
  font-weight: 300;
}

.re-rest-pass .line-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.1px;
  text-align: center;
  font-size: 14.07px;
  color: #e2e2e2;
}

.re-rest-pass .frame-parent1 {
  top: 0px;
  left: 0px;
  width: 429.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.1px;
}

.re-rest-pass .frame-div {
  align-self: stretch;
  position: relative;
  height: 105.6px;
}

.re-rest-pass .contact {
  flex: 1;
  position: relative;
  font-weight: 500;
}

.re-rest-pass .contact-wrapper {
  width: 146px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.8px;
  box-sizing: border-box;
  cursor: pointer;
}

.re-rest-pass .frame-parent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 17.6px;
  text-align: center;
  color: #fff;
}

.re-rest-pass .frame-container {
  width: 429.3px;
  height: 175.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 17.6px;
}

.re-rest-pass .frame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.re-rest-pass .group-icon {
  width: 40px;
  position: relative;
  height: 40px;
}

.re-rest-pass .group-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 35.2px;
}

.re-rest-pass .frame-wrapper2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.re-rest-pass .frame-wrapper1 {
  width: 429.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.re-rest-pass .frame-group {
  top: 32.55px;
  left: calc(50% - 215px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.re-rest-pass .frame-item {
  align-self: stretch;
  position: relative;
  border-top: 0.9px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  height: 0.9px;
}

.re-rest-pass .copyright-2024 {
  position: relative;
  font-weight: 300;
}

.re-rest-pass .copyright-2024-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.4px 8.8px;
}

.re-rest-pass .rerest-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.4px 8.8px;
}

.re-rest-pass .frame-parent3 {
  width: 150.4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.re-rest-pass .line-group {
  top: 286.07px;
  left: calc(50% - 734px);
  width: 1468px;
  height: 49px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4.4px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.re-rest-pass .frame-parent {
  bottom: 0.57px;
  left: calc(50% - 760px);
  background-color: #263069;
  width: 1520px;
  height: 339.5px;
  font-size: 21.11px;
  color: #CEE4FF;
}

.re-rest-pass .frame-inner {
  top: 0px;
  left: 0px;
  box-shadow: 0px 3.5185184478759766px 3.52px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: 1520px;
  height: 88px;
}

.re-rest-pass .frame-6-10 {
  width: 44px;
  position: relative;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}

.re-rest-pass .rerest2 {
  position: relative;
  font-weight: 800;
}

.re-rest-pass .frame-6-10-parent {
  top: 21.99px;
  left: 52.78px;
  width: 173.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.7px;
  cursor: pointer;
}

.re-rest-pass .vector-icon {
  width: 21.1px;
  position: relative;
  height: 21.1px;
  object-fit: contain;
}

.re-rest-pass .find-a-restroom {
  position: relative;
  font-weight: 600;
}

.re-rest-pass .vector-parent {
  top: 22px;
  left: 1237px;
  border-radius: 13.19px;
  background-color: #263069;
  width: 221.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  gap: 6.2px;
  cursor: pointer;
  font-size: 17.59px;
  color: #fff;
}

.re-rest-pass .rectangle-parent {
  top: 0px;
  left: 0px;
  width: 1520px;
  height: 88px;
  font-size: 28.15px;
}

.re-rest-pass .send-link {
  width: 100%;
  position: relative;
  background-color: #cee4ff;
  min-height: 100vh;
  margin-top: -12px;
  padding-top: 100px;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  color: #263069;
}





.edit-profilee .account-data {
  text-align: left;
  align-self: stretch;
  position: relative;
  font-size: 24px;
  font-weight: 500;

}

.edit-profilee .name {
  position: relative;
}

.edit-profilee .name-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
}

.edit-profilee .group-icon {
  width: 22px;
  height: 22px;
}

.edit-profilee .frame-parent {
  position: relative;
}

.edit-profilee .group-icon {
  position: absolute;
  right: 10px;
  top: 60px;
}

.edit-profilee .ahmad-ali-parent {
  align-self: stretch;
  border-radius: 13.19px;
  background-color: #eee;
  height: 57.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.8px 13.2px;
  box-sizing: border-box;
  gap: 189.1px;
  font-size: 15px;
}

.edit-profilee .frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.edit-profilee .ahmadali123gmailcom {
  position: relative;
  font-weight: 300;
}

.edit-profilee .ahmadali123gmailcom-wrapper {
  width: 331.6px;
  border-radius: 13.19px;
  background-color: #eee;
  height: 57.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.8px 13.2px;
  box-sizing: border-box;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.edit-profilee .account-data-parent {
  margin: auto;
  top: 296.54px;
  left: 451.25px;
  width: 331.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 29.9px;
  font-family: "Alexandria";
}

.edit-profilee .connect {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}

.edit-profilee .google-icon {
  width: 35.2px;
  position: relative;
  height: 35.2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.5;
}

.edit-profilee .google-parent {
  align-self: stretch;
  box-shadow: 0px 0px 3.52px rgba(0, 0, 0, 0.25);
  border-radius: 13.19px;
  background-color: #fff;
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  gap: 8.8px;
  text-align: center;
  font-size: 19.35px;
  color: rgba(0, 0, 0, 0.5);
}

.edit-profilee .connect-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 13.2px;
  width: 257px;
  margin: auto;
  margin-bottom: 27px;
}

.edit-profilee .google-parent {
  gap: 13.2px;
  width: 257px;
  display: flex;
  height: 47px;
  padding: 7.135px;
  justify-content: center;
  align-items: center;
  gap: 7.135px;
  align-self: stretch;
  margin: auto;
  margin-bottom: 27px;
  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Nunito;
  font-size: 15.698px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 0px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 2.854px 0px rgba(0, 0, 0, 0.25);
}

.edit-profilee .connect {
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.edit-profilee .edit-profile-inner {
  position: absolute;
  top: 149px;
  left: 451px;
  width: 331.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 24px;
  font-family: "Alexandria";
}

.edit-profilee .cancel {
  position: relative;
  font-weight: 500;
}

.edit-profilee .cancel-wrapper {
  width: 124.9px;
  border-radius: 8.8px;
  background-color: white;
  border: 1.3px solid #ff0000;
  box-sizing: border-box;
  height: 52.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
}

.edit-profilee .save {
  position: relative;
  font-weight: 600;
}

.edit-profilee .save-wrapper {
  width: 164.5px;
  border-radius: 8.8px;
  background-color: #263069;
  height: 52.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  color: #fff;
}

.edit-profilee .frame-div {
  /* position: absolute; */
  /* top: 614px; */
  /* left: 451px; */
  margin: 40px auto;
  width: 331.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 19.35px;
  color: #ff0000;
}

.edit-profilee .icon-parkpublic-toilet {
  width: 35.2px;
  position: relative;
  height: 35.2px;
  overflow: hidden;
  flex-shrink: 0;
}

.edit-profilee .icon-parkpublic-toilet-parent {
  align-self: stretch;
  height: 35.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7.7px;
}

.edit-profilee .frame-child {
  align-self: stretch;
  position: relative;
  border-top: 0.9px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  height: 0.9px;
}

.edit-profilee .your-guide-to {
  align-self: stretch;
  position: relative;
  font-weight: 300;
}

.edit-profilee .line-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.1px;
  text-align: center;
  font-size: 14.07px;
  color: #e2e2e2;
}

.edit-profilee .frame-parent4 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 429.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.1px;
}

.edit-profilee .frame-wrapper2 {
  align-self: stretch;
  position: relative;
  height: 105.6px;
}

.edit-profilee .contact {
  flex: 1;
  position: relative;
  font-weight: 500;
}

.edit-profilee .contact-wrapper {
  width: 146px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.8px;
  box-sizing: border-box;
  cursor: pointer;
}

.edit-profilee .frame-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 17.6px;
  text-align: center;
  color: #fff;
}

.edit-profilee .frame-parent3 {
  width: 429.3px;
  height: 175.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 17.6px;
}

.edit-profilee .frame-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.edit-profilee .group-icon1 {
  width: 40px;
  position: relative;
  height: 40px;
}

.edit-profilee .group-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 35.2px;
}

.edit-profilee .frame-wrapper4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.edit-profilee .frame-wrapper3 {
  width: 429.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.edit-profilee .frame-parent2 {
  position: absolute;
  top: 32.55px;
  left: calc(50% - 215px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.edit-profilee .frame-item {
  align-self: stretch;
  position: relative;
  border-top: 0.9px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  height: 0.9px;
}

.edit-profilee .copyright-2024-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.4px 8.8px;
}

.edit-profilee .rerest-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.4px 8.8px;
}

.edit-profilee .frame-parent6 {
  width: 150.4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.edit-profilee .line-group {
  position: absolute;
  top: 286.07px;
  left: calc(50% - 734px);
  width: 1468px;
  height: 49px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4.4px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.edit-profilee .frame-parent1 {
  position: absolute;
  bottom: -0.43px;
  left: calc(50% - 760px);
  background-color: #263069;
  width: 1520px;
  height: 339.5px;
  font-size: 21.11px;
  color: #CEE4FF;
}

.edit-profilee .frame-inner {
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0px 3.5185184478759766px 3.52px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: 1520px;
  height: 88px;
}

.edit-profilee .frame-6-10 {
  width: 44px;
  position: relative;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}

.edit-profilee .rerest2 {
  position: relative;
  font-weight: 800;
}

.edit-profilee .frame-6-10-parent {
  position: absolute;
  top: 21.99px;
  left: 52.78px;
  width: 173.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.7px;
  cursor: pointer;
  font-size: 28.15px;
}

.edit-profilee .plus-icon {
  width: 21.1px;
  position: relative;
  height: 21.1px;
  overflow: hidden;
  flex-shrink: 0;
}

.edit-profilee .plus-parent {
  position: absolute;
  top: 21.99px;
  left: 355.37px;
  border-radius: 13.19px;
  border: 1.8px solid #263069;
  box-sizing: border-box;
  width: 221.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 6.2px;
  cursor: pointer;
}

.edit-profilee .vector-icon {
  width: 21.1px;
  position: relative;
  height: 21.1px;
  object-fit: contain;
}

.edit-profilee .vector-parent {
  position: absolute;
  top: 21.99px;
  left: 599.03px;
  border-radius: 13.19px;
  background-color: #263069;
  width: 221.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  gap: 6.2px;
  cursor: pointer;
  color: #fff;
}

.edit-profilee .rectangle-icon {
  width: 44px;
  position: relative;
  border-radius: 43.98px;
  height: 44px;
  object-fit: cover;
}

.edit-profilee .vector-icon1 {
  width: 14.1px;
  position: relative;
  height: 7.9px;
}

.edit-profilee .rectangle-group {
  position: absolute;
  top: 14.95px;
  left: 1394.21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4.4px;
  gap: 6.2px;
}

.edit-profilee .contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  cursor: pointer;
}

.edit-profilee .frame-parent7 {
  position: absolute;
  top: calc(50% - 24.81px);
  left: calc(50% + 190.16px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 55.4px;
  font-size: 21.11px;
}

.edit-profilee .rectangle-parent {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1520px;
  height: 88px;
  color: #263069;
}

.edit-profilee .component-15-icon {
  width: 176.8px;
  position: relative;
  height: 176.8px;
  object-fit: cover;
}

.edit-profilee .component-15-parent {
  /* position: absolute; */
  top: 129.31px;
  left: 154.81px;
  box-shadow: 0px 0px 3.52px rgba(0, 0, 0, 0.25);
  border-radius: 13.19px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 8.8px;
  text-align: center;
  font-size: 14.07px;
}

.edit-profilee .edit-profile {
  width: 100%;
  position: relative;
  background-color: #fff;
  height: 100vh;
  text-align: left;
  font-size: 17.59px;
  color: #000;
}


.edit-profilee .rectangle-icon1 {
  position: absolute;
  top: 16.49px;
  left: 18px;
  border-radius: 44.03px;
  width: 61.6px;
  height: 61.6px;
  object-fit: cover;
}

.edit-profilee .name-here {
  position: relative;
  font-weight: 500;
}

.edit-profilee .name-here-wrapper {
  align-self: stretch;
  height: 26.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 4.4px;
  box-sizing: border-box;
}

.edit-profilee .rabie123456gmailcom {
  position: relative;
  font-weight: 300;
}

.edit-profilee .rabie123456gmailcom-wrapper {
  align-self: stretch;
  height: 17.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 4.4px;
  box-sizing: border-box;
  font-size: 13px;
}

.edit-profilee .frame-parent9 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.edit-profilee .edit-profile1 {
  position: relative;
  font-weight: 600;
}

.edit-profilee .edit-profile-wrapper {
  width: 96.9px;
  border-radius: 13.21px;
  background-color: #263069;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  font-size: 13.21px;
  color: #fff;
}

.edit-profilee .frame-parent8 {
  position: absolute;
  top: 21.13px;
  left: 89.82px;
  width: 182.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12.3px;
  font-size: 14px;
}

.edit-profilee .line-div {
  align-self: stretch;
  position: relative;
  border-top: 0.6px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  height: 0.6px;
}

.edit-profilee .vector-icon2 {
  width: 22px;
  position: relative;
  height: 22px;
}

.edit-profilee .vector-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px 0px;
  gap: 8.8px;
  cursor: pointer;
}

.edit-profilee .line-container {
  position: absolute;
  top: 132.09px;
  left: 29.94px;
  width: 235.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.6px;
}

.edit-profilee .vector-icon3 {
  width: 22px;
  position: relative;
  height: 22px;
  object-fit: contain;
}

.edit-profilee .line-parent1 {
  position: absolute;
  top: 197.25px;
  left: 29.94px;
  width: 235.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.6px;
}

.edit-profilee .rectangle-root {
  width: 295px;
  position: relative;
  box-shadow: 0px 0.8805970549583435px 3.52px 1.76px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  height: 273px;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  text-align: left;
  font-size: 13.21px;
  color: #000;
}


.edit-profilee .check-icon {
  width: 32.5px;
  position: relative;
  height: 32.5px;
}

.edit-profilee .success-message {
  position: relative;
}

.edit-profilee .check-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10.5px;
  z-index: 0;
}

.edit-profilee .changes-saves-successfully {
  width: 219px;
  position: absolute;
  margin: 0 !important;
  top: 53.4px;
  left: 59.69px;
  font-size: 15.71px;
  display: inline-block;
  z-index: 1;
}

.edit-profilee .close-icon {
  width: 20.9px;
  position: absolute;
  margin: 0 !important;
  top: 8.38px;
  left: 371.73px;
  height: 20.9px;
  z-index: 2;
}

.edit-profilee .frame-parent10 {
  position: absolute;
  top: 3.86px;
  left: 18px;
  backdrop-filter: blur(20.94px);
  border-radius: 16.75px;
  background-color: rgba(0, 0, 0, 0.13);
  border: 0.5px solid #000;
  box-sizing: border-box;
  width: 400px;
  height: 85.9px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16.8px;
  gap: 8.4px;
}

.edit-profilee .something-went-wrong {
  width: 179px;
  position: absolute;
  margin: 0 !important;
  top: 53.4px;
  left: 59.69px;
  font-size: 15.71px;
  display: inline-block;
  z-index: 1;
}

.edit-profilee .frame-parent11 {
  position: absolute;
  top: 117.46px;
  left: 18px;
  backdrop-filter: blur(20.94px);
  border-radius: 16.75px;
  background-color: rgba(0, 0, 0, 0.13);
  border: 0.5px solid #000;
  box-sizing: border-box;
  width: 400px;
  height: 85.9px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16.8px;
  gap: 8.4px;
  color: #ff0000;
}

.edit-profilee .frame-root {
  width: 436px;
  position: relative;
  height: 276.4px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  text-align: left;
  font-size: 20.94px;
  color: #32b768;
  font-family: Inter;
}

.write-review .frame-child {
  position: absolute;
  top: 6.36px;
  left: calc(50% - 207.37px);
  border-radius: 21.91px;
  width: 414.5px;
  height: 272.3px;
  object-fit: cover;
}

.write-review .vector-icon {
  width: 20.3px;
  position: relative;
  height: 20.3px;
  object-fit: contain;
}

.write-review .miles-from-your {
  position: relative;
  font-weight: 300;
}

.write-review .vector-parent {
  width: 284.4px;
  height: 23px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16.3px;
  z-index: 0;
  font-size: 18.96px;
}

.write-review .manx-museum {
  position: relative;
  font-weight: 500;
}

.write-review .manx-museum-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.write-review .div {
  position: relative;
}

.write-review .vector-icon1 {
  width: 13.3px;
  position: relative;
  height: 12.9px;
}

.write-review .star {
  width: 93.1px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.4px;
}

.write-review .star-wrapper {
  width: 93.1px;
  height: 12.9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.write-review .ratings1 {
  text-decoration: underline;
  color: #231fff;
}

.write-review .ratings-txt-container {
  width: 100%;
}

.write-review .ratings {
  width: 89.1px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.write-review .parent {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200.1px;
  height: 22.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2.9px;
}

.write-review .frame-wrapper {
  width: 200.1px;
  position: absolute;
  margin: 0 !important;
  top: 7.05px;
  left: 217.58px;
  height: 22.1px;
  z-index: 1;
  text-align: left;
  font-size: 16.07px;
}

.write-review .frame-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  gap: 59.6px;
  z-index: 1;
  font-size: 27.09px;
  color: #000;
}

.write-review .manhattan-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.write-review .new-york-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.write-review .frame-container {
  width: 108.4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.7px;
  z-index: 2;
  color: #000;
}

.write-review .frame-item {
  width: 1.4px;
  position: relative;
  border-right: 1.4px solid #000;
  box-sizing: border-box;
  height: 27.1px;
}

.write-review .opens-at-0930 {
  position: relative;
  color: #000;
}

.write-review .closed-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 4.1px;
}

.write-review .more-details {
  position: relative;
  text-decoration: underline;
}

.write-review .more-details-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #231fff;
}

.write-review .frame-div {
  width: 414.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 55.5px;
  z-index: 3;
  text-align: left;
  color: #f61e1e;
}

.write-review .vector-icon6 {
  width: 23px;
  position: relative;
  height: 18px;
  opacity: 0.7;
}

.write-review .vector-group {
  margin: 0 !important;
  position: absolute;
  top: 0.13px;
  left: 302.29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
  z-index: 4;
  text-align: left;
  font-size: 16.12px;
}

.write-review .directions {
  position: absolute;
  top: 7.08px;
  left: 44.95px;
  text-decoration: underline;
  font-weight: 600;
}

.write-review .arrow-top-right-icon {
  width: 15px;
  position: relative;
  height: 16.8px;
}

.write-review .arrow-top-right-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 8.17px;
  background-color: #0061ff;
  width: 37.9px;
  height: 36.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9.8px 11.4px;
  box-sizing: border-box;
}

.write-review .directions-parent {
  width: 124px;
  position: absolute;
  margin: 0 !important;
  top: 80.96px;
  left: 287.69px;
  height: 36.4px;
  z-index: 5;
  text-align: left;
  font-size: 15.26px;
  color: #231fff;
}

.write-review .frame-parent {
  position: absolute;
  top: 284.97px;
  left: 7.63px;
  width: 414.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9.5px;
  text-align: center;
  font-family: "Alexandria";
}

.write-review .group-child {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 7.3px;
  background-color: #263069;
  width: 115.4px;
  height: 40.9px;
  opacity: 0.85;
}

.write-review .paid {
  position: absolute;
  top: 5.84px;
  left: 49.66px;
  font-weight: 500;
}

.write-review .vector-icon7 {
  width: 12.8px;
  position: relative;
  height: 19.5px;
}

.write-review .vector-wrapper {
  position: absolute;
  top: 1.46px;
  left: 7.3px;
  border-radius: 18.38px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 36.8px;
  height: 36.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.1px 11.7px;
  box-sizing: border-box;
}

.write-review .rectangle-group {
  position: absolute;
  top: 198.93px;
  left: 307px;
  width: 115.4px;
  height: 40.9px;
  font-size: 20.45px;
  color: #fff;
}

.write-review .caretcircleleft-icon {
  position: absolute;
  top: 124.45px;
  left: 17.83px;
  width: 35.1px;
  height: 35.1px;
  overflow: hidden;
}

.write-review .caretcircleright-icon {
  position: absolute;
  top: 124.45px;
  left: 378.57px;
  width: 35.1px;
  height: 35.1px;
  overflow: hidden;
}

.write-review .frame-inner {
  width: 8.9px;
  position: relative;
  border-radius: 50%;
  background-color: #d9d9d9;
  height: 8.9px;
}

.write-review .frame-child1 {
  width: 8.9px;
  position: relative;
  border-radius: 50%;
  background-color: #263069;
  height: 8.9px;
}

.write-review .ellipse-parent {
  position: absolute;
  top: 250.05px;
  left: calc(50% - 26.29px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2.2px;
}

.write-review .vector-icon8 {
  width: 14px;
  position: relative;
  height: 14px;
}

.write-review .remove-parent {
  position: absolute;
  top: 33px;
  left: 313px;
  border-radius: 7.2px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3.6px 7.2px;
  gap: 7.2px;
  font-size: 14.73px;
  color: #f61e1e;
}

.write-review .rectangle-parent {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 6.36px rgba(0, 0, 0, 0.25);
  border-radius: 21.91px 21.91px 12.72px 12.72px;
  background-color: #fff;
  border: 1.3px solid #000;
  box-sizing: border-box;
  height: 460.6px;
  text-align: left;
  font-size: 20.32px;
  color: rgba(0, 0, 0, 0.7);
}

.write-review .stepney-city-farm {
  position: absolute;
  top: 32.2px;
  left: calc(50% - 77.51px);
  font-size: 16.24px;
  font-weight: 800;
  color: #263069;
}

.write-review .rectangle-icon {
  position: absolute;
  top: 89.43px;
  left: 83.47px;
  border-radius: 59.62px;
  width: 59.6px;
  height: 59.6px;
  object-fit: cover;
}

.write-review .abdullrahman-alaraby {
  position: absolute;
  top: 108.51px;
  left: 155.02px;
  font-size: 14.31px;
}

.write-review .vector-icon {
  width: 27.4px;
  position: relative;
  height: 27.4px;
}

.write-review .component-14 {
  width: 48.9px;
  border-radius: 24.44px;
  height: 48.9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10.7px;
  box-sizing: border-box;
}

.write-review .component-14-parent {
  position: absolute;
  top: 165.75px;
  left: calc(50% - 122.82px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.write-review .write-review-window-child {
  position: absolute;
  top: 225.37px;
  left: calc(50% - 214.64px);
  border-radius: 11.92px;
  background-color: #f4f4f4;
  border: 1.2px solid #1a73e8;
  box-sizing: border-box;
  width: 429.3px;
  height: 114.5px;
}

.write-review .share-details-of {
  position: absolute;
  top: 250.41px;
  left: 163.36px;
}

.write-review .add-photo-alternate-icon {
  width: 21.5px;
  position: relative;
  height: 21.5px;
}

.write-review .add-photos {
  position: relative;
  font-weight: 500;
}

.write-review .add-photo-alternate-parent {
  position: absolute;
  top: 357.73px;
  left: calc(50% - 63.5px);
  border-radius: 35.77px;
  border: 0.9px solid #0061ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  gap: 11.9px;
}

.write-review .cancel-wrapper {
  width: 91.8px;
  border-radius: 11.92px;
  border: 1.2px solid #1a73e8;
  box-sizing: border-box;
  height: 39.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  cursor: pointer;
}

.write-review .post {
  position: relative;
}

.write-review .post-wrapper {
  width: 91.8px;
  border-radius: 11.92px;
  background-color: #1a73e8;
  height: 39.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  box-sizing: border-box;
  color: #fff;
}

.write-review .frame-parent {
  position: absolute;
  top: 493.67px;
  left: calc(50% - 104.93px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 26.2px;
  text-align: center;
  font-size: 15.5px;
  color: #1a73e8;
}

.write-review .frame-child {
  width: 102.5px;
  position: relative;
  height: 60.8px;
}

.write-review .group-parent {
  position: absolute;
  top: 417.35px;
  left: calc(50% - 221.79px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10.7px;
}

.write-review .x-icon {
  position: absolute;
  top: 34.22px;
  left: 661px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

.write-review .write-review-window {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 4.77px rgba(0, 0, 0, 0.35);
  border-radius: 29.81px;
  background-color: #f9f9f9;
  border: 2px solid #1a73e8;
  box-sizing: border-box;
  height: 560.4px;
  overflow: hidden;
  text-align: left;
  font-size: 11.92px;
  color: #000;
}


.write-review .saly-26-icon {
  position: absolute;
  top: 78px;
  left: calc(50% - 76.5px);
  width: 152px;
  height: 188px;
  object-fit: cover;
}

.write-review .thanks-for-your {
  position: absolute;
  top: 331px;
  left: calc(50% - 96.5px);
}

.write-review .people-rely-on {
  position: absolute;
  top: 395px;
  left: calc(50% - 237.5px);
  font-size: 14px;
  font-weight: 600;
}

.write-review .mask-group-icon {
  position: absolute;
  height: 17.84%;
  width: 13.79%;
  top: 7.85%;
  right: 93.1%;
  bottom: 74.3%;
  left: -6.9%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.write-review .d-shapewireframe-cube {
  position: absolute;
  top: 280px;
  left: 40%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.write-review .x-icon1 {
  position: absolute;
  top: 34px;
  left: 661px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

.write-review .write-review-done {
  width: 725px;
  width: 100%;
  /* position: relative; */
  /* box-shadow: 0px 0px 4.77px rgba(0, 0, 0, 0.35); */
  border-radius: 29.81px;
  /* background-color: #f9f9f9; */
  border: 2px #1a73e8;
  box-sizing: border-box;
  height: 560.4px;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #263069;
}

.saly-26-icon {
  position: absolute;
  top: 78px;
  left: calc(50% - 76.5px);
  width: 152px;
  height: 188px;
  object-fit: cover;
}

.thanks-for-your {
  text-align: center;
  margin-top: 30px;

}

.people-rely-on {
  text-align: center;
  /* left: calc(50% - 237.5px); */
  font-size: 14px;
  margin-top: 30px;

  font-weight: 600;
}

.mask-group-icon {
  position: absolute;
  height: 17.84%;
  width: 13.79%;
  top: 7.85%;
  right: 93.1%;
  bottom: 74.3%;
  left: -6.9%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.d-shapewireframe-cube {
  position: absolute;
  top: 230px;
  left: 675px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.write-review-done .x-icon {
  position: absolute;
  top: 0px;
  right: 4%;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

.write-review-done {
  width: 100%;
  position: relative;
  /* box-shadow: 0px 0px 4.77px rgba(0, 0, 0, 0.35); */
  border-radius: 29.81px;
  /* background-color: #f9f9f9; */
  border: 2px #1a73e8;
  box-sizing: border-box;
  height: 360.4px;
  overflow: hidden;
  text-align: center;
  font-size: 16px;
  color: #263069;
}

input,
textarea {
  border: none;
  outline: none;
}

@media only screen and (max-width : 1024px) {

  .contact .undraw-envelope-re-f5j4-1-icon,
  .contact .frame-child,
  .contact .frame-wrapper6 {
    display: none;
  }

  .contact .frame-wrapper5 {
    box-shadow: none;
    height: auto;
    padding-top: 30px;
  }

  .contact {
    background-color: white;
  }

  .about-us {
    margin-top: -30px;
  }

  .contact .frame-parent {
    justify-content: center;
  }

  .about-us {
    background: none;
  }

  .man {
    position: absolute;
    left: 0px;
    top: -30px;
  }


  .about-us .display .typegraphy0 {
    font-size: 40px;
  }

  .about-us .display {
    position: relative;
  }

  .location {
    position: absolute;
    right: 0px;
    top: unset !important;
    left: unset !important;
    bottom: -100px !important;
    z-index: 22 !important;
  }

  .about-us {
    margin-top: 0;
    min-height: 80vh;
  }

  .signup-signin .login-sign-in {
    background-color: white;
  }

  .signup-signin .login-sign-in {
    margin-top: 90px;
  }

  .re-rest-pass .send-link {
    margin-top: -20px;
    background-color: white;
  }

  .offcanvas-body {
    background-color: #263069;
  }

  .signup-signin .login-sign-in,
  .login-sign-in {
    background-color: #fff !important;
  }

  .signup-signin .login-sign-in,
  .login-sign-in.signup {
    /* position: relative; */
    background-color: #fff !important;
  }
}

@media only screen and (max-width: 767px) {
  .contact .frame-wrapper {
    margin-left: 0px;

    width: 80%;
  }

  .contact .frame-group,
  .contact .frame-div {
    width: 100%;
  }

  .offcanvas-body .d-flex {
    display: flex;
    flex-direction: column;
  }

  .signup-signin .login-sign-in {
    margin-top: 20px;
  }

  .signup-signin .login-sign-in-inner {
    margin-top: 20px;
  }

  .signup-signin .frame-wrapper1,
  .signup .d-flex {
    flex-direction: column;
    margin-top: 20px;
  }

  .signup-signin .frame-wrapper3,
  .signup-signin .frame-wrapper,
  .signup-signin .frame-parent1,
  .signup-signin .frame-div,
  .signup-signin .frame-wrapper1,
  .signup-signin .frame-container {
    height: auto;
  }

  .signup-signin .enter-your-password-here-parent {
    margin: 10px 0;
  }

  .signup-signin .enter-your-email,
  .password-password {
    width: 350px;
    font-size: 11.298px;
    font-weight: 300;

  }

  body {
    width: 100%;
    padding: 0 !important;
    overflow-x: hidden !important;
  }

  .about-us .display .typegraphy0 {
    line-height: 18px;
  }

  .about-us {
    min-height: 90vh;
  }

  .location {
    bottom: -140px;
  }

  .signup-signin .login-sign-in,
  .login-sign-in {
    background-color: #fff !important;
  }

}


.cardrestroom .frame-child {
  position: absolute;
  top: 4.36px;
  left: calc(50% - 141.97px);
  border-radius: 15px;
  width: 283.8px;
  height: 186.4px;
  object-fit: cover;
}

.cardrestroom .vector-icon {
  width: 13.9px;
  position: relative;
  height: 13.9px;
  object-fit: contain;
}

.cardrestroom .miles-from-your {
  position: relative;
  font-weight: 300;
  margin-right: 5px;
  /* width: 150px; */
  text-align: left;
  font-size: 12px;
}

.cardrestroom .vector-parent {
  /* width: 194.8px; */
  height: 15.8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 11.1px;
  z-index: 0;
  font-size: 12.98px;
}

.cardrestroom .manx-museum {
  position: relative;
  font-weight: 500;
  /* width: 180px; */
  text-align: left;
}

.cardrestroom .manx-museum-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.cardrestroom .div {
  position: relative;
}

.cardrestroom .vector-icon1 {
  width: 9.1px;
  position: relative;
  height: 8.8px;
}

.cardrestroom .star {
  /* width: 63.7px; */
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4.4px;
}

.cardrestroom .star-wrapper {
  /* width: 63.7px; */
  height: 8.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cardrestroom .ratings1 {
  text-decoration: underline;
  color: #231fff;
}

.cardrestroom .ratings-txt-container {
  width: 100%;
}

.cardrestroom .ratings {
  /* width: 61px; */
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.cardrestroom .parent {
  /* position: absolute; */
  top: 0px;
  left: 0px;
  /* width: 137px; */
  height: 15.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.cardrestroom .frame-wrapper {
  /* width: 7px; */
  /* position: absolute; */
  margin-left: auto;
  top: 4.83px;
  left: 188.98px;
  height: 15.1px;
  z-index: 1;
  text-align: left;
  font-size: 11px;
}

.cardrestroom .frame-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  /* gap: 40.8px; */
  z-index: 1;
  font-size: 18.55px;
  color: #000;
}

.cardrestroom .manhattan-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.cardrestroom .new-york-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.cardrestroom .frame-container {
  /* width: 94.2px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.9px;
  z-index: 2;
  color: #000;
}

.cardrestroom .frame-item {
  width: 0.9px;
  position: relative;
  border-right: 0.9px solid #000;
  box-sizing: border-box;
  height: 18.5px;
}

.cardrestroom .opens-at-0930 {
  position: relative;
  color: #000;
}

.cardrestroom .closed-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2.8px;
}

.cardrestroom .more-details {
  position: relative;
  text-decoration: underline;
}

.cardrestroom .more-details-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #231fff;
}

.cardrestroom .frame-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  z-index: 3;
  text-align: left;
  color: #f61e1e;
}

.cardrestroom .vector-icon6 {
  width: 15px;
  position: relative;
  height: 12px;
  opacity: 0.7;
}

.cardrestroom .vector-group {
  margin: 0 !important;
  /* position: absolute; */
  width: 100px;
  top: 0.03px;
  left: 206.98px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
  z-index: 4;
  text-align: left;
  font-size: 11.03px;
}

.cardrestroom .directions {
  /* position: absolute; */
  top: 4.85px;
  left: 30.78px;
  text-decoration: underline;
  font-weight: 600;
}

.cardrestroom .arrow-top-right-icon {
  width: 20.3px;
  position: relative;
  height: 21.5px;
}

.cardrestroom .arrow-top-right-wrapper {
  /* position: absolute; */
  top: 0px;
  left: 0px;
  border-radius: 5.6px;
  background-color: #0061ff;
  width: 25.9px;
  height: 24.9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.7px 7.8px;
  box-sizing: border-box;
  margin-right: 5px;
}

.cardrestroom .directions-parent {
  width: 84.8px;
  /* position: absolute; */
  margin: 0 !important;
  top: 55.43px;
  left: 196.98px;
  height: 24.9px;
  z-index: 5;
  text-align: left;
  font-size: 10.45px;
  color: #231fff;
}

.cardrestroom .swiper {
  height: 200px;
}

.cardrestroom .frame-parent {
  /* position: absolute;
  top: 195.12px;
  left: 5.23px; */
  padding: 0 10px;
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.5px;
}

.cardrestroom .vector-icon7 {
  width: 21.3px;
  position: relative;
  height: 21.3px;
}

.cardrestroom .component-14 {
  position: absolute;
  top: 11.32px;
  right: 15px;
  border-radius: 19.01px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 38px;
  height: 38px;
  z-index: 222;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.3px;
  box-sizing: border-box;
}

.cardrestroom .group-child {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 5px;
  background-color: #263069;
  width: 79px;
  height: 28px;
  opacity: 0.85;
}

.cardrestroom .paid {
  position: absolute;
  top: 4px;
  left: 34px;
  font-weight: 500;
}

.cardrestroom .vector-icon8 {
  width: 8.7px;
  position: relative;
  height: 13.4px;
}

.cardrestroom .vector-wrapper {
  position: absolute;
  top: 1px;
  left: 5px;
  border-radius: 12.59px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 25.2px;
  height: 25.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5.5px 8px;
  box-sizing: border-box;
}

.cardrestroom .rectangle-group {
  position: absolute;
  top: 136.21px;
  right: 0px;
  width: 79px;
  height: 28px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  z-index: 22;
}

.cardrestroom .caretcircleleft-icon {
  position: absolute;
  top: 85.21px;
  left: 12px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.cardrestroom .caretcircleright-icon {
  position: absolute;
  top: 85.21px;
  left: 259px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.cardrestroom .frame-inner {
  width: 6.1px;
  position: relative;
  border-radius: 50%;
  background-color: #d9d9d9;
  height: 6.1px;
}

.cardrestroom .frame-child1 {
  width: 6.1px;
  position: relative;
  border-radius: 50%;
  background-color: #263069;
  height: 6.1px;
}

.cardrestroom .ellipse-parent {
  position: absolute;
  top: 171.21px;
  left: calc(50% - 18.2px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5px;
}

.cardrestroom .rectangle-parent {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 4.36px rgba(0, 0, 0, 0.25);
  border-radius: 15px 15px 8.71px 8.71px;
  background-color: #fff;
  border: 0.9px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  min-height: 340px;

  text-align: center;
  font-size: 13.91px;
  color: rgba(0, 0, 0, 0.7);
  font-family: "Alexandria";
  margin: 5px 0;
}





.rest-detail .frame-child {
  /* position: absolute; */
  top: 30.79px;
  left: calc(50% - 314.6px);
  border-radius: 14.07px;
  width: 471.5px;
  height: 279.7px;
  object-fit: cover;
}

.rest-detail .frame-item {
  /* position: absolute; */
  top: 121.39px;
  left: calc(50% + 185.91px);
  border-radius: 14.07px;
  width: 129.3px;
  height: 76.5px;
  object-fit: cover;
}

.rest-detail .frame-inner {
  /* position: absolute; */
  top: 30px;
  left: calc(50% + 185.5px);
  border-radius: 14.07px;
  width: 129.3px;
  height: 76.5px;
  object-fit: cover;
}

.rest-detail .rectangle-icon {
  position: absolute;
  top: 211.99px;
  left: calc(50% + 185.91px);
  border-radius: 14.07px;
  width: 129.3px;
  height: 76.5px;
  object-fit: cover;
}

.rest-detail .ellipse-div {
  width: 14.1px;
  position: relative;
  border-radius: 50%;
  background-color: #d9d9d9;
  height: 14.1px;
}

.rest-detail .frame-child2 {
  width: 14.1px;
  position: relative;
  border-radius: 50%;
  background-color: #263069;
  height: 14.1px;
}

.rest-detail .ellipse-parent {
  position: absolute;
  top: 328.1px;
  left: calc(50% - 129.88px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3.5px;
}

.rest-detail .stepney-city-farm {
  position: absolute;
  top: 359.77px;
  left: 51.9px;
  font-size: 21.11px;
  font-weight: 800;
  color: #263069;
}

.rest-detail .accessible-forward-icon {
  width: 13.8px;
  position: relative;
  height: 13.8px;
}

.rest-detail .wheelchair {
  position: relative;
  font-weight: 500;
}

.rest-detail .accessible-forward-parent {
  border-radius: 17.22px;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.9px;
  gap: 3.4px;
}

.rest-detail .baby-changing-station-parent {
  border-radius: 17.22px;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.9px;
  gap: 6.9px;
}

.rest-detail .vector-icon {
  width: 10.3px;
  position: relative;
  height: 12.1px;
}

.rest-detail .vector-parent {
  border-radius: 17.22px;
  background-color: #d9d9d9;
  height: 27.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.9px;
  box-sizing: border-box;
  gap: 6.9px;
}

.rest-detail .frame-parent {
  position: absolute;
  top: 438.94px;
  left: calc(50% - 311.65px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5.5px;
  font-size: 8.26px;
  color: #263069;
}

.rest-detail .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rest-detail .star {
  width: 2.3px;
  height: 15.3px;
  overflow: hidden;
  flex-shrink: 0;
}

.rest-detail .vector-icon1 {
  width: 13.6px;
  position: relative;
  height: 13.2px;
}

.rest-detail .star1 {
  width: 15.6px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.rest-detail .vector-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3.9px;
}

.rest-detail .frame-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2.3px;
}

.rest-detail .span {
  text-decoration: underline;
}

.rest-detail .div1 {
  position: relative;
  font-size: 12.22px;
}

.rest-detail .frame-group {
  position: absolute;
  top: 391.44px;
  left: 51.9px;
  width: 161.9px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5.3px;
  font-size: 13.19px;
}

.rest-detail .sports-complex {
  position: absolute;
  top: 414.31px;
  left: 51.9px;
}

.rest-detail .overview-wrapper {
  border-radius: 12.28px;
  background-color: #cfe5ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

.rest-detail .reviews-wrapper {
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
}

.rest-detail .frame-div {
  position: absolute;
  top: 490.83px;
  left: calc(50% - 160.87px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4.8px;
  font-size: 15.35px;
  color: #263069;
}

.rest-detail .vector-icon6 {
  width: 23.3px;
  position: relative;
  height: 23.3px;
}

.rest-detail .component-14 {
  width: 41.4px;
  border-radius: 20.72px;
  background-color: #f9f9f9;
  border: 0.9px solid #1a73e8;
  box-sizing: border-box;
  height: 41.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 9.1px;
}

.rest-detail .component-14-parent {
  position: absolute;
  top: 548.01px;
  left: 302.59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 8.8px;
  font-size: 11.5px;
}

.rest-detail .arrow-top-right-icon {
  width: 14.4px;
  position: relative;
  height: 16.3px;
}

.rest-detail .arrow-top-right {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 23px;
  height: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 3.8px;
  box-sizing: border-box;
}

.rest-detail .arrow-top-right-wrapper {
  width: 23px;
  position: relative;
  height: 23px;
}

.rest-detail .group-wrapper {
  width: 41.4px;
  border-radius: 20.72px;
  background-color: #1a73e8;
  height: 41.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 19.2px;
  box-sizing: border-box;
}

.rest-detail .frame-parent1 {
  position: absolute;
  top: 548.01px;
  left: 212.87px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 8.8px;
  font-size: 11.5px;
}

.rest-detail .line-div {
  position: absolute;
  top: 542.89px;
  left: 164.65px;
  border-top: 1.4px solid #d9d9d9;
  box-sizing: border-box;
  width: 246px;
  height: 1.4px;
}

.rest-detail .frame-child6 {
  position: absolute;
  top: 632.61px;
  left: 164.65px;
  border-top: 1.4px solid #d9d9d9;
  box-sizing: border-box;
  width: 246px;
  height: 1.4px;
}

.rest-detail .frame-child7 {
  position: absolute;
  top: 1044.28px;
  left: 164.65px;
  border-top: 1.4px solid #d9d9d9;
  box-sizing: border-box;
  width: 246px;
  height: 1.4px;
}

.rest-detail .new-york {
  position: absolute;
  top: 21.11px;
  left: 38.7px;
}

.rest-detail .location-on-icon {
  width: 21.1px;
  position: relative;
  height: 21.1px;
}

.rest-detail .manhattan {
  position: relative;
}

.rest-detail .location-on-parent {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8.8px;
  gap: 8.8px;
  text-align: center;
}

.rest-detail .new-york-parent {
  position: absolute;
  top: 655.32px;
  left: 51.9px;
  width: 125.5px;
  height: 38.1px;
  font-size: 14.07px;
  font-family: "Alexandria";
}

.rest-detail .clock-icon {
  width: 25.3px;
  position: relative;
  height: 25.3px;
  overflow: hidden;
  flex-shrink: 0;
}

.rest-detail .opens-9-am-sun {
  color: #000;
}

.rest-detail .vector-icon7 {
  width: 10.6px;
  position: relative;
}

.rest-detail .clock-parent {
  width: 263.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10.5px 0px;
  box-sizing: border-box;
  gap: 10.5px;
}

.rest-detail .container {
  width: 103.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rest-detail .frame-parent4 {
  width: 234px;
  background-color: #eedfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 72.8px;
}

.rest-detail .frame {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rest-detail .frame-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 69.6px;
}

.rest-detail .frame-parent6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 46.6px;
}

.rest-detail .frame-parent7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 62.2px;
}

.rest-detail .frame-parent8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 89.7px;
}

.rest-detail .frame-parent9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 64.4px;
}

.rest-detail .frame-parent10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 77px;
}

.rest-detail .frame-parent3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 20px;
  color: #000;
}

.rest-detail .frame-parent2 {
  position: absolute;
  top: 698.43px;
  left: calc(50% - 305.81px);
  width: 234px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5.3px;
  font-size: 14.07px;
  color: #ff0000;
}

.rest-detail .reviews1 {
  align-self: stretch;
  position: relative;
}

.rest-detail .reviews-container {
  align-self: stretch;
  height: 1328.2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.rest-detail .frame-wrapper {
  position: absolute;
  top: 1066.99px;
  left: 60.69px;
  width: 527.8px;
  height: 1328.2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 17.59px;
}

.rest-detail .row-child {
  width: 19.9px;
  position: relative;
  border-radius: 1.24px;
  height: 19.9px;
}

.rest-detail .space {
  width: 187.8px;
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.rest-detail .row1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.rest-detail .space1 {
  width: 131.8px;
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.rest-detail .space2 {
  width: 74.6px;
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.rest-detail .space3 {
  width: 23.6px;
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.rest-detail .space4 {
  width: 7.5px;
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.rest-detail .rating {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.rest-detail .b {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
}

.rest-detail .row6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.rest-detail .reviews2 {
  align-self: stretch;
  position: relative;
  font-size: 17.41px;
  letter-spacing: -0.01em;
  font-weight: 600;
}

.rest-detail .rating1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  font-size: 49.75px;
}

.rest-detail .row {
  align-self: stretch;
  border-radius: 9.95px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14.9px 10px;
  gap: 29.9px;
}

.rest-detail .icons {
  width: 21.1px;
  position: relative;
  height: 21.1px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.rest-detail .primary-buttondefault {
  width: 148.7px;
  border-radius: 7.04px;
  background-color: #263069;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14.1px 21.1px;
  box-sizing: border-box;
  gap: 7px;
}

.rest-detail .primary-buttondefault-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: 14.07px;
  color: #fff;
}

.rest-detail .row-parent {
  width: 426.6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36.9px;
  text-align: right;
  font-size: 17.41px;
}

.rest-detail .rectangle-icon1 {
  width: 52.4px;
  position: relative;
  border-radius: 52.45px;
  height: 52.4px;
  object-fit: cover;
}

.rest-detail .courtney-henry {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 600;
}

.rest-detail .mins-ago {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.rest-detail .row8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  text-align: right;
  font-size: 17.41px;
}

.rest-detail .text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.rest-detail .div14 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.rest-detail .divblock1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.rest-detail .consequat-velit-qui {
  width: 384.3px;
  position: relative;
  font-size: 16.17px;
  letter-spacing: -0.01em;
  line-height: 148%;
  display: inline-block;
}

.rest-detail .row7 {
  width: 426.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 14.9px;
}

.rest-detail .space5 {
  width: 515.5px;
  position: relative;
  border: 1.5px solid #d9d9d9;
  box-sizing: border-box;
  height: 1.5px;
}

.rest-detail .row11 {
  width: 426.6px;
  height: 165.4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.9px;
}

.rest-detail .space7 {
  align-self: stretch;
  position: relative;
  border: 1.5px solid #d9d9d9;
  box-sizing: border-box;
  height: 1.5px;
}

.rest-detail .row-group {
  width: 515.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.rest-detail .row-child27 {
  width: 425.9px;
  position: relative;
  height: 248.9px;
  object-fit: cover;
}

.rest-detail .divblock {
  align-self: stretch;
  height: 1323.8px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 36.9px;
}

.rest-detail .divblock-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17.6px;
}

.rest-detail .is-this-information-correct-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
}

.rest-detail .yes {
  position: relative;
  font-weight: 600;
}

.rest-detail .yes-wrapper {
  border-radius: 17.59px;
  background-color: #263069;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px 15.8px;
}

.rest-detail .vector-icon8 {
  width: 13.2px;
  position: relative;
  height: 13.2px;
}

.rest-detail .vector-container {
  border-radius: 13.19px;
  background-color: #fff;
  border: 0.9px solid #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 8.8px;
  cursor: pointer;
}

.rest-detail .frame-wrapper1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #000;
}

.rest-detail .frame-parent13 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 13.2px;
  font-size: 14.07px;
  color: #fff;
}

.rest-detail .frame-parent12 {
  width: 251.6px;
  border-radius: 17.59px;
  background-color: #ebe8e8;
  border: 0.9px solid #0061ff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8.8px 0px;
  gap: 3.5px;
  font-size: 17.59px;
  color: #000;
  font-family: "Alexandria";
}

.rest-detail .frame-parent11 {
  position: absolute;
  top: 1113px;
  left: 61px;
  width: 527px;
  height: 1611px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24.6px;
  font-size: 19.9px;
  color: #333;
}

.rest-detail .vector-icon9 {
  position: absolute;
  top: 297.32px;
  left: 603.43px;
  width: 26.4px;
  height: 13.2px;
  object-fit: contain;
}

.rest-detail .vector-icon10 {
  width: 28.1px;
  position: relative;
  height: 28.1px;
  cursor: pointer;
}

.rest-detail .vector-wrapper {
  position: absolute;
  top: 21.99px;
  left: -0.88px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.8px;
}

.rest-detail .add-photo-alternate-icon {
  width: 18.9px;
  position: relative;
  height: 18.9px;
}

.rest-detail .add-photo-alternate-parent {
  position: absolute;
  top: 335.14px;
  left: calc(50% + 194.05px);
  filter: drop-shadow(0px 0px 5.98px rgba(0, 0, 0, 0.3));
  border-radius: 31.47px;
  border: 0.9px solid #0061ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10.5px;
  gap: 10.5px;
  font-size: 10.49px;
}

.rest-detail .icons2 {
  width: 18.5px;
  position: relative;
  height: 18.5px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.rest-detail .edit-icon1 {
  width: 18.5px;
  position: relative;
  height: 18.5px;
}

.rest-detail .primary-buttondefault1 {
  width: 130px;
  border-radius: 6.15px;
  background-color: #263069;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12.3px 18.5px;
  box-sizing: border-box;
  gap: 6.2px;
}

.rest-detail .primary-buttondefault-container {
  position: absolute;
  top: 411px;
  left: 553px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.rest-detail .rectangle-parent {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 2.9px rgba(0, 0, 0, 0.25);
  border-radius: 13.19px;
  background-color: #f8f4f4;
  height: 2754px;
  overflow-y: auto;
  text-align: left;
  font-size: 12.31px;
  color: #000;
}


.rest-detail .stepney-city-farm1 {
  position: absolute;
  top: 32.2px;
  left: calc(50% - 77.51px);
  font-size: 16.24px;
  font-weight: 800;
  color: #263069;
}

.rest-detail .rectangle-icon5 {
  position: absolute;
  top: 89.43px;
  left: 83.47px;
  border-radius: 59.62px;
  width: 59.6px;
  height: 59.6px;
  object-fit: cover;
}

.rest-detail .abdullrahman-alaraby {
  position: absolute;
  top: 108.51px;
  left: 155.02px;
  font-size: 14.31px;
}

.rest-detail .vector-icon11 {
  width: 27.4px;
  position: relative;
  height: 27.4px;
}

.rest-detail .component-141 {
  width: 48.9px;
  border-radius: 24.44px;
  height: 48.9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10.7px;
  box-sizing: border-box;
}

.rest-detail .component-14-group {
  position: absolute;
  top: 165.75px;
  left: calc(50% - 122.82px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.rest-detail .write-review-window-child {
  position: absolute;
  top: 225.37px;
  left: calc(50% - 214.64px);
  border-radius: 11.92px;
  background-color: #f4f4f4;
  border: 1.2px solid #1a73e8;
  box-sizing: border-box;
  width: 429.3px;
  height: 114.5px;
}

.rest-detail .share-details-of {
  position: absolute;
  top: 250.41px;
  left: 163.36px;
}

.rest-detail .add-photo-alternate-icon1 {
  width: 21.5px;
  position: relative;
  height: 21.5px;
}

.rest-detail .add-photo-alternate-group {
  position: absolute;
  top: 357.73px;
  left: calc(50% - 63.5px);
  border-radius: 35.77px;
  border: 0.9px solid #0061ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  gap: 11.9px;
}

.rest-detail .cancel {
  position: relative;
  font-weight: 500;
}

.rest-detail .cancel-wrapper {
  width: 91.8px;
  border-radius: 11.92px;
  border: 1.2px solid #1a73e8;
  box-sizing: border-box;
  height: 39.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  cursor: pointer;
}

.rest-detail .post {
  position: relative;
}

.rest-detail .post-wrapper {
  width: 91.8px;
  border-radius: 11.92px;
  background-color: #1a73e8;
  height: 39.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  box-sizing: border-box;
  color: #fff;
}

.rest-detail .frame-parent14 {
  position: absolute;
  top: 493.67px;
  left: calc(50% - 104.93px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 26.2px;
  text-align: center;
  font-size: 15.5px;
  color: #1a73e8;
}

.rest-detail .group-icon {
  width: 102.5px;
  position: relative;
  height: 60.8px;
}

.rest-detail .group-parent {
  position: absolute;
  top: 417.35px;
  left: calc(50% - 221.79px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10.7px;
}

.rest-detail .x-icon {
  position: absolute;
  top: 34.22px;
  left: 661px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

.rest-detail .write-review-window {
  width: 725px;
  position: relative;
  box-shadow: 0px 0px 4.77px rgba(0, 0, 0, 0.35);
  border-radius: 29.81px;
  background-color: #f9f9f9;
  border: 2px solid #1a73e8;
  box-sizing: border-box;
  height: 560.4px;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  text-align: left;
  font-size: 11.92px;
  color: #000;
}


.marrr .react-stars {
  margin-top: 0px !important;
  margin-left: -15px !important;
}

.room-details-container .frame-child {
  /* top: 30.79px; */
  border-radius: 14.07px;
  width: 72%;
  height: 289.7px;
  object-fit: cover;
}

.room-details-container .frame-item {
  /* top: 121.39px; */
  left: calc(50% + 185.91px);
  border-radius: 14.07px;
  width: 129.3px;
  height: 76.5px;
  object-fit: cover;
}

.room-details-container .frame-inner {
  top: 30px;
  left: calc(50% + 185.5px);
  border-radius: 14.07px;
  width: 129.3px;
  height: 76.5px;
  object-fit: cover;
}

.room-details-container .rectangle-icon {
  /* top: 211.99px; */
  left: calc(50% + 185.91px);
  border-radius: 14.07px;
  width: 129.3px;
  height: 76.5px;
  object-fit: cover;
}

.room-details-container .ellipse-div {
  width: 14.1px;
  position: relative;
  border-radius: 50%;
  background-color: #d9d9d9;
  height: 14.1px;
}

.room-details-container .frame-child2 {
  width: 14.1px;
  position: relative;
  border-radius: 50%;
  background-color: #263069;
  height: 14.1px;
}

.room-details-container .ellipse-parent {
  top: 328.1px;
  left: calc(50% - 129.88px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3.5px;
}

.roomSelected {
  position: relative;
  /* top: -280px; */
  /* z-index: 22222; */
  margin-top: 40px;
  overflow: hidden;
  /* padding-bottom: 200px; */
}

.containerbody.d0.active .roomSelected {
  margin-top: -40px;
}

.containerbody.d0.active .roomSelected .row .col-md-7 {
  background-color: #fff;
  padding-top: 50px;
  box-shadow: 0px 0px 2.9px rgba(0, 0, 0, 0.25);
  border-radius: 13.19px;

}

.room-details-container .stepney-city-farm {
  top: 359.77px;
  left: 51.9px;
  font-size: 21.11px;
  font-weight: 800;
  color: #263069;
  margin-top: 10px;
}

.room-details-container .accessible-forward-icon {
  width: 13.8px;
  position: relative;
  height: 13.8px;
}

.room-details-container .wheelchair {
  position: relative;
  font-weight: 500;
}

.room-details-container .accessible-forward-parent {
  border-radius: 17.22px;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.9px;
  gap: 3.4px;
}

.room-details-container .baby-changing-station-parent {
  border-radius: 17.22px;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.9px;
  gap: 6.9px;
}

.room-details-container .vector-icon {
  width: 10.3px;
  position: relative;
  height: 12.1px;
}

.room-details-container .vector-parent {
  border-radius: 17.22px;
  background-color: #d9d9d9;
  height: 27.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.9px;
  box-sizing: border-box;
  gap: 6.9px;
}

.room-details-container .frame-group {
  /* top: 438.94px; */
  left: calc(50% - 311.65px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5.5px;
  font-size: 8.26px;
  color: #263069;
}

.room-details-container .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.room-details-container .star {
  width: 2.3px;
  height: 15.3px;
  overflow: hidden;
  flex-shrink: 0;
}

.room-details-container .vector-icon1 {
  width: 13.6px;
  position: relative;
  height: 13.2px;
}

.room-details-container .star1 {
  width: 15.6px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.room-details-container .vector-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3.9px;
}

.room-details-container .frame-parent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2.3px;
}

.room-details-container .span {
  text-decoration: underline;
}

.room-details-container .div1 {
  position: relative;
  font-size: 12.22px;
}

.room-details-container .frame-div {
  top: 391.44px;
  left: 51.9px;
  width: 161.9px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5.3px;
  font-size: 13.19px;
}

.room-details-container .sports-complex {
  top: 414.31px;
  left: 51.9px;
}

.room-details-container .overview-wrapper {
  border-radius: 12.28px;
  background-color: #cfe5ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

.room-details-container .reviews-wrapper {
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
}

.room-details-container .frame-parent2 {
  top: 490.83px;
  left: calc(50% - 160.87px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4.8px;
  font-size: 15.35px;
  color: #263069;
}

.room-details-container .vector-icon6 {
  width: 23.3px;
  position: relative;
  height: 23.3px;
}

.room-details-container .component-14 {
  width: 41.4px;
  border-radius: 20.72px;
  background-color: #f9f9f9;
  border: 0.9px solid #1a73e8;
  box-sizing: border-box;
  height: 41.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: 9.1px; */
}

.room-details-container .component-14-parent {
  top: 548.01px;
  left: 302.59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 8.8px;
  font-size: 11.5px;
}

.room-details-container .arrow-top-right-icon {
  width: 14.4px;
  position: relative;
  height: 16.3px;
}

.room-details-container .arrow-top-right {
  top: 0px;
  left: 0px;
  width: 23px;
  height: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 3.8px;
  box-sizing: border-box;
}

.room-details-container .arrow-top-right-wrapper {
  width: 23px;
  position: relative;
  height: 23px;
}

.room-details-container .group-wrapper {
  width: 41.4px;
  border-radius: 20.72px;
  background-color: #1a73e8;
  height: 41.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 19.2px;
  box-sizing: border-box;
}

.room-details-container .frame-parent3 {
  top: 548.01px;
  left: 212.87px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 8.8px;
  font-size: 11.5px;
}

.room-details-container .line-div {
  top: 542.89px;
  left: 164.65px;
  border-top: 1.4px solid #d9d9d9;
  box-sizing: border-box;
  width: 246px;
  margin: auto;
  margin-top: 20px;
  height: 1.4px;
}

.room-details-container .frame-child6 {
  top: 632.61px;
  left: 164.65px;
  border-top: 1.4px solid #d9d9d9;
  box-sizing: border-box;
  width: 246px;
  height: 1.4px;
}

.room-details-container .frame-child7 {
  top: 1074.28px;
  left: 164.65px;
  border-top: 1.4px solid #d9d9d9;
  box-sizing: border-box;
  width: 246px;
  height: 1.4px;
}

.room-details-container .new-york {
  top: 21.11px;
  left: 38.7px;
}

.room-details-container .location-on-icon {
  width: 21.1px;
  position: relative;
  height: 21.1px;
}

.room-details-container .manhattan {
  position: relative;
}

.room-details-container .location-on-parent {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  gap: 8.8px;
  text-align: center;
}

.room-details-container .new-york-parent {

  width: 100%;
  margin-top: 10px;
  height: 38.1px;
  font-size: 14.07px;
  font-family: "Alexandria";
}

.room-details-container .clock-icon {
  width: 25.3px;
  position: relative;
  height: 25.3px;
  overflow: hidden;
  flex-shrink: 0;
}

.room-details-container .opens-9-am-sun {
  color: #000;
}

.room-details-container .vector-icon7 {
  width: 10.6px;
  position: relative;
}

.room-details-container .clock-parent {
  width: 263.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10.5px 0px;
  box-sizing: border-box;
  gap: 10.5px;
}

.room-details-container .container {
  width: 103.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.room-details-container .frame-parent6 {
  width: 234px;
  background-color: #eedfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 72.8px;
}

.room-details-container .frame {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.room-details-container .frame-parent7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 69.6px;
}

.room-details-container .frame-parent8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 46.6px;
}

.room-details-container .frame-parent9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 62.2px;
}

.room-details-container .frame-parent10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 89.7px;
}

.room-details-container .frame-parent11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 64.4px;
}

.room-details-container .frame-parent12 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 77px;
}

.room-details-container .frame-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 20px;
  color: #000;
}

.room-details-container .frame-parent4 {
  top: 698.43px;
  left: calc(50% - 305.81px);
  width: 254px;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5.3px;
  font-size: 14.07px;
  color: #ff0000;
}

.room-details-container .reviews1 {
  align-self: stretch;
  position: relative;
}

.room-details-container .reviews-container {
  align-self: stretch;
  /* height: 1328.2px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.room-details-container .frame-wrapper1 {
  top: 1066.99px;
  left: 60.69px;
  width: 527.8px;
  /* height: 1328.2px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 17.59px;
}

.room-details-container .row-child {
  width: 19.9px !important;
  position: relative;
  border-radius: 1.24px;
  height: 19.9px !important;
}

.room-details-container .space {
  width: 187.8px;
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.room-details-container .row1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.room-details-container .space1 {
  /* width: 131.8px; */
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.room-details-container .space2 {
  /* width: 74.6px; */
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.room-details-container .space3 {
  /* width: 23.6px; */
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.room-details-container .space4 {
  /* width: 7.5px; */
  position: relative;
  border-radius: 4.98px;
  background-color: #fbbc04;
  height: 7.5px;
}

.room-details-container .rating {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.room-details-container .b {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
}

.room-details-container .row6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.room-details-container .reviews2 {
  align-self: stretch;
  position: relative;
  font-size: 17.41px;
  letter-spacing: -0.01em;
  font-weight: 600;
}

.room-details-container .rating1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-basis: 10%;
  gap: 10px;
  font-size: 49.75px;
}

.room-details-container .row {
  align-self: stretch;

  border-radius: 9.95px;
  padding: 14.925px 9.95px;

  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 29.9px;
}

.room-details-container .icons {
  width: 21.1px;
  position: relative;
  height: 21.1px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.room-details-container .primary-buttondefault {
  /* width: 148.7px; */
  border-radius: 7.04px;
  background-color: #263069;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14.1px 21.1px;
  box-sizing: border-box;
  gap: 7px;
}

.room-details-container .primary-buttondefault-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: 14.07px;
  color: #fff;
}

.room-details-container .row-parent {
  width: 426.6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36.9px;
  text-align: right;
  font-size: 17.41px;
}

.room-details-container .rectangle-icon1 {
  width: 52.4px;
  position: relative;
  border-radius: 52.45px;
  height: 52.4px;
  object-fit: cover;
}

.room-details-container .courtney-henry {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 600;
}

.room-details-container .mins-ago {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.room-details-container .row8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  text-align: right;
  font-size: 17.41px;
}

.room-details-container .text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.room-details-container .div14 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.room-details-container .div14 img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.room-details-container .divblock1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.room-details-container .consequat-velit-qui {
  width: 384.3px;
  position: relative;
  font-size: 16.17px;
  letter-spacing: -0.01em;
  line-height: 148%;
  display: inline-block;
}

.room-details-container .row7 {
  width: 426.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 14.9px;
}

.room-details-container .space5 {
  width: 515.5px;
  position: relative;
  box-sizing: border-box;
  height: 1.5px;
}

.room-details-container .row11 {
  width: 426.6px;
  height: 165.4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14.9px;
}

.room-details-container .space7 {
  align-self: stretch;
  position: relative;
  border: 1.5px solid #d9d9d9;
  box-sizing: border-box;
  height: 1.5px;
}

.room-details-container .row-group {
  width: 515.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.room-details-container .row-child27 {
  width: 425.9px;
  position: relative;
  height: 248.9px;
  object-fit: cover;
}

.room-details-container .divblock {
  align-self: stretch;
  /* height: 1323.8px; */
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 36.9px;
}

.room-details-container .divblock-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17.6px;
}

.room-details-container .is-this-information-correct-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
}

.room-details-container .yes {
  position: relative;
  font-weight: 600;
}

.room-details-container .yes-wrapper {
  border-radius: 17.59px;
  background-color: #263069;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px 15.8px;
}

.room-details-container .vector-icon8 {
  width: 13.2px;
  position: relative;
  height: 13.2px;
}

.room-details-container .vector-container {
  border-radius: 13.19px;
  background-color: #fff;
  border: 0.9px solid #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8.8px;
  gap: 8.8px;
  cursor: pointer;
}

.room-details-container .frame-wrapper2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #000;
}

.room-details-container .frame-parent15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 13.2px;
  font-size: 14.07px;
  color: #fff;
}

.room-details-container .frame-parent14 {
  width: 251.6px;
  border-radius: 17.59px;
  background-color: #ebe8e8;
  border: 0.9px solid #0061ff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8.8px 0px;
  gap: 3.5px;
  font-size: 17.59px;
  color: #000;
  font-family: "Alexandria";
}

.room-details-container .frame-parent13 {
  top: 1113px;
  left: 61px;
  width: 527px;
  /* height: 1611px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24.6px;
  font-size: 19.9px;
  color: #333;
}

.room-details-container .vector-icon9 {
  top: 297.32px;
  left: 603.43px;
  width: 26.4px;
  height: 13.2px;
  object-fit: contain;
}

.room-details-container .vector-icon10 {
  width: 28.1px;
  position: relative;
  height: 28.1px;
  cursor: pointer;
  z-index: 2;
}

.room-details-container .vector-wrapper {
  position: absolute;
  top: 5%;
  left: 6%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8.8px;
}

.room-details-container .add-photo-alternate-icon {
  width: 18.9px;
  position: relative;
  height: 18.9px;
}

.room-details-container .add-photo-alternate-parent {
  top: 335.14px;
  left: calc(50% + 194.05px);
  filter: drop-shadow(0px 0px 5.98px rgba(0, 0, 0, 0.3));
  border-radius: 31.47px;
  border: 0.9px solid #0061ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10.5px;
  gap: 10.5px;
  font-size: 10.49px;
}

.room-details-container .icons2 {
  width: 18.5px;
  position: relative;
  height: 18.5px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.room-details-container .edit-icon1 {
  width: 18.5px;
  position: relative;
  height: 18.5px;
}

.room-details-container .primary-buttondefault1 {
  width: 130px;
  border-radius: 6.15px;
  background-color: #263069;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12.3px 18.5px;
  box-sizing: border-box;
  gap: 6.2px;
}

.room-details-container .primary-buttondefault-container {
  top: 411px;
  left: 553px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.room-details-container .rectangle-parent {
  top: 12.2px;
  left: calc(50% - 367.92px);
  box-shadow: 0px 0px 2.9px rgba(0, 0, 0, 0.25);
  border-radius: 13.19px;
  background-color: #f8f4f4;
  width: 735px;
  height: 540px;
  padding-left: 50px;
  padding-top: 30px;
  /* margin-left: 100px; */
  overflow-y: auto;
  margin: 0 30px;
  margin-top: 30px;

}

.room-details-container .frame-container {
  top: 12.31px;
  left: 14.07px;
  /* background-color: #fff; */
  /* width: 893.7px; */
  height: 552.4px;
  overflow: hidden;
  background-color: #fff;
  align-items: center;
  justify-content: center !important;
  padding: 0.519px 12.852px 0.204px 12px;
  border-radius: 10px 13.194px 10px 10px;
  height: 552.4px;
  overflow: hidden;
}

.room-details-container .frame-wrapper {
  /* width: 921.9px; */
  position: relative;
  border-radius: 0px 13.19px 0px 0px;
  /* background-color: rgba(255, 255, 255, 0.9); */
  overflow: hidden;
  flex-shrink: 0;
  background-color: #fff;
  padding: 0.519px 1px 0.204px 12px;
  width: 100%;
}

.roomSelected .col-lg-7 {
  padding-left: 0 !important;
}

.room-details-container .vector-icon11 {
  width: 29.6px;
  position: relative;
  height: 37.7px;
}

.room-details-container .mappin {
  top: 0px;
  left: 0px;
  width: 35px;
  height: 43.1px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2.7px;
  box-sizing: border-box;
}

.room-details-container .div18 {
  align-self: stretch;
  position: relative;
  font-weight: 300;
}

.room-details-container .wrapper6 {
  top: 2.72px;
  left: 21.79px;
  border-radius: 10.9px;
  background-color: #ffc42e;
  width: 28.3px;
  height: 15.3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 7.3px;
  box-sizing: border-box;
}

.room-details-container .mappin-parent {
  width: 50.1px;
  position: relative;
  height: 43.1px;
}

.room-details-container .stepney-city-farm1 {
  position: relative;
  font-weight: 800;
}

.room-details-container .stepney-city-farm-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 2.5px;
  font-size: 11.98px;
  color: #263069;
}

.room-details-container .group-parent {
  top: 166.25px;
  left: 156.57px;
  filter: drop-shadow(0px 1.9970160722732544px 4.99px rgba(0, 0, 0, 0.3));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.room-details-container .frame-wrapper3 {
  width: 598.1px;
  position: relative;
  height: 564.7px;
  overflow: hidden;
  flex-shrink: 0;
  /* background-image: url('Frame 21 .4.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: 10.18px;
}

.room-details-container .frame-parent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 12.31px;
  color: #000;
}


.room-details-container .stepney-city-farm2 {
  top: 32.2px;
  left: calc(50% - 77.51px);
  font-size: 16.24px;
  font-weight: 800;
  color: #263069;
}

.room-details-container .rectangle-icon5 {
  top: 89.43px;
  left: 83.47px;
  border-radius: 59.62px;
  width: 59.6px;
  height: 59.6px;
  object-fit: cover;
}

.room-details-container .abdullrahman-alaraby {
  top: 108.51px;
  left: 155.02px;
  font-size: 14.31px;
}

.room-details-container .vector-icon12 {
  width: 27.4px;
  position: relative;
  height: 27.4px;
}

.room-details-container .component-141 {
  width: 48.9px;
  border-radius: 24.44px;
  height: 48.9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10.7px;
  box-sizing: border-box;
}

.room-details-container .component-14-group {
  top: 165.75px;
  left: calc(50% - 122.82px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.room-details-container .write-review-window-child {
  top: 225.37px;
  left: calc(50% - 214.64px);
  border-radius: 11.92px;
  background-color: #f4f4f4;
  border: 1.2px solid #1a73e8;
  box-sizing: border-box;
  width: 429.3px;
  height: 114.5px;
}

.room-details-container .share-details-of {
  top: 250.41px;
  left: 163.36px;
}

.room-details-container .add-photo-alternate-icon1 {
  width: 21.5px;
  position: relative;
  height: 21.5px;
}

.room-details-container .add-photo-alternate-group {
  top: 357.73px;
  left: calc(50% - 63.5px);
  border-radius: 35.77px;
  border: 0.9px solid #0061ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  gap: 11.9px;
}

.room-details-container .cancel {
  position: relative;
  font-weight: 500;
}

.room-details-container .cancel-wrapper {
  width: 91.8px;
  border-radius: 11.92px;
  border: 1.2px solid #1a73e8;
  box-sizing: border-box;
  height: 39.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  cursor: pointer;
}

.room-details-container .post {
  position: relative;
}

.room-details-container .post-wrapper {
  width: 91.8px;
  border-radius: 11.92px;
  background-color: #1a73e8;
  height: 39.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  box-sizing: border-box;
  color: #fff;
}

.room-details-container .frame-parent16 {
  top: 493.67px;
  left: calc(50% - 104.93px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 26.2px;
  text-align: center;
  font-size: 15.5px;
  color: #1a73e8;
}

.room-details-container .group-icon {
  width: 102.5px;
  position: relative;
  height: 60.8px;
}

.room-details-container .group-container {
  top: 417.35px;
  left: calc(50% - 221.79px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10.7px;
}

.room-details-container .x-icon {
  top: 34.22px;
  left: 661px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

.room-details-container .write-review-window {
  width: 725px;
  position: relative;
  box-shadow: 0px 0px 4.77px rgba(0, 0, 0, 0.35);
  border-radius: 29.81px;
  background-color: #f9f9f9;
  border: 2px solid #1a73e8;
  box-sizing: border-box;
  height: 560.4px;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  text-align: left;
  font-size: 11.92px;
  color: #000;
}



.popReview .stepney-city-farm {
  top: 32.2px;
  left: calc(50% - 77.51px);
  font-size: 16.24px;
  font-weight: 800;
  color: #263069;
}

.popReview .rectangle-icon {
  top: 89.43px;
  left: 83.47px;
  border-radius: 59.62px;
  width: 59.6px;
  height: 59.6px;
  object-fit: cover;
}

.popReview .abdullrahman-alaraby {
  margin-left: 30px;
  margin-top: 20px;
  font-size: 14.31px;
}

.popReview .vector-icon {
  width: 27.4px;
  position: relative;
  height: 27.4px;
}

.popReview .component-14 {
  width: 48.9px;
  border-radius: 24.44px;
  height: 48.9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10.7px;
  box-sizing: border-box;
}

.popReview .component-14-parent {
  top: 165.75px;
  left: calc(50% - 122.82px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.popReview .write-review-window-child {
  border-radius: 11.92px;
  background-color: #f4f4f4;
  border: 1.2px solid #1a73e8;
  box-sizing: border-box;
  width: 97%;
  height: 114.5px;
  padding: 10px;
  margin-top: 20px;
}

.popReview .share-details-of {
  top: 250.41px;
  left: 163.36px;
}

.popReview .add-photo-alternate-icon {
  width: 21.5px;
  position: relative;
  height: 21.5px;
}

.popReview .add-photos {
  position: relative;
  font-weight: 500;
}

.popReview .add-photo-alternate-parent {
  top: 357.73px;
  left: calc(50% - 63.5px);
  border-radius: 35.77px;
  border: 0.9px solid #0061ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  gap: 11.9px;
}

.popReview .cancel-wrapper {
  width: 91.8px;
  border-radius: 11.92px;
  border: 1.2px solid #1a73e8;
  box-sizing: border-box;
  height: 39.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  cursor: pointer;
  color: #1a73e8;
}

.popReview .post {
  position: relative;
}

.popReview .post-wrapper {
  width: 91.8px;
  border-radius: 11.92px;
  background-color: #1a73e8;
  height: 39.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.9px;
  box-sizing: border-box;
  color: #fff;
}

.popReview .frame-parent {
  position: absolute;
  top: 393.67px;
  left: calc(50% - 104.93px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 26.2px;
  text-align: center;
  font-size: 15.5px;
  color: #1a73e8;
}

.popReview .frame-child {
  width: 102.5px;
  position: relative;
  height: 60.8px;
}

.popReview .group-parent {
  position: absolute;
  top: 417.35px;
  left: calc(50% - 221.79px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10.7px;
}

.popReview .x-icon {
  position: absolute;
  top: 4.22px;
  left: 561px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

.popReview .write-review-window {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 4.77px rgba(0, 0, 0, 0.35);
  border-radius: 29.81px;
  background-color: #f9f9f9;
  border: 2px solid #1a73e8;
  box-sizing: border-box;
  height: 450.4px;
  overflow: hidden;
  text-align: left;
  padding-left: 20px;
  font-size: 11.92px;
  color: #000;
}


.popReview .saly-26-icon {
  position: absolute;
  top: 78px;
  left: calc(50% - 76.5px);
  width: 152px;
  height: 188px;
  object-fit: cover;
}

.popReview .thanks-for-your {
  position: absolute;
  top: 331px;
  left: calc(50% - 96.5px);
}

.popReview .people-rely-on {
  position: absolute;
  top: 395px;
  /* left: calc(50% - 237.5px); */
  font-size: 14px;
  font-weight: 600;
}

.popReview .mask-group-icon {
  position: absolute;
  height: 17.84%;
  width: 13.79%;
  top: 7.85%;
  right: 93.1%;
  bottom: 74.3%;
  left: -6.9%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.popReview .d-shapewireframe-cube {
  position: absolute;
  top: 230px;
  left: 675px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.popReview .x-icon1 {
  position: absolute;
  top: 34px;
  left: 661px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

.popReview .write-review-done {
  width: 725px;
  position: relative;
  box-shadow: 0px 0px 4.77px rgba(0, 0, 0, 0.35);
  border-radius: 29.81px;
  background-color: #f9f9f9;
  border: 2px solid #1a73e8;
  box-sizing: border-box;
  height: 560.4px;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  text-align: left;
  font-size: 16px;
  color: #263069;
}

.stepney-city-farm2 {
  text-align: center;
  margin-top: 20px;
  color: #263069;
  font-size: 22px;
  font-weight: 600;
}

.write-review-window img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 70px;
  margin-top: 30px;
}

.react-stars {
  margin: auto !important;
  margin-top: 30px !important;
}

.react-stars span {
  font-size: 40px !important;
  /* filter: drop-shadow(1px 0px 0px #FFC42E) drop-shadow(0px 1px 0px #FFC42E) drop-shadow(0px 1px 0px #FFC42E) drop-shadow(0px 0px 1px #FFC42E) !important; */
  margin: 0 10px !important;
  color: white;
}

.room-details-container button .manhattan {
  color: #fff;
}


.profileNav img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;

}

.profileNav {
  display: flex;
}

.profileNav h3 {
  font-size: 18px;
}

.profileNav p {
  font-size: 14px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  left: -180px;
}

.dropdown-item img {
  width: 25px;
  margin-right: 5px;
  margin-top: -5px;
}



.save-parent {
  width: 119px;
  height: 51px;
  justify-content: center;
  align-items: center;
  gap: 15.455px;
  border-radius: 15.455px;
  background: #1A73E8;
  color: #FFF;
  text-align: center;
  font-family: "Nunito";
  font-size: 20.091px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 34px;
  margin-bottom: 93.23px;
}


.cancel-parent {
  display: flex;
  width: 119px;
  height: 51px;
  padding: 15.455px;
  justify-content: center;
  align-items: center;
  gap: 15.455px;
  border-radius: 15.455px;
  border: 1.545px solid #1A73E8;
  color: #1A73E8;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 20.091px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 93.23px;

}


.add-photo-alternate-icon {
  width: 21.5px;
  position: relative;
  height: 21.5px;
}

.add-photos {
  position: relative;
  font-weight: 500;
}

.add-photo-alternate-parent {
  position: relative;
  filter: drop-shadow(0px 0px 6.8px rgba(0, 0, 0, 0.3));
  border-radius: 35.77px;
  width: 127px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8.9px;
  text-align: left;
  font-size: 11.92px;
  color: #000;
  border-radius: 35.773px;
  padding: 11.924px;

  border: 1px solid #0061FF;
  box-shadow: 0px 0px 6.8px 0px rgba(0, 0, 0, 0.30);
}

.photo-previews {
  text-align: center;
}

.photoaddx {
  position: relative;
}

.photo-previews img {
  width: 202.353px;
  margin: 5px 10px;
  height: 120px;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0px 0px 9.412px 0px rgba(0, 0, 0, 0.25);
}

.photoaddx .remove-photo-button {
  position: absolute;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 50%;
  right: 15px;
  top: -45px;
}

.remove-parent {
  color: red;
  border: none;
  outline: none;
  background: none;
  position: absolute;
  right: 10.6px;
  top: 0;
  text-align: right;
  margin: 10px 0;
  width: 100%;
  margin-left: auto;
  display: flex;
  width: 86px;
  height: 29px;
  padding: 2.889px 5.778px;
  justify-content: center;
  align-items: center;
  gap: 5.778px;
  flex-shrink: 0;
  z-index: 2;
  border-radius: 5.778px;
  background: #FFF;
}

.remove-parent img {

  width: 11.268px;
  height: 11.268px;
  flex-shrink: 0;
}

.my-favorites-list {
  color: #263069;
  font-size: 24px;
  font-weight: 700;
}


.container-add .child .divRestType .restroomType .type input[type="checkbox"]:checked~label img,
.container-add .child .child-01 .child1 .child2 .title0 input[type="checkbox"]:checked~label img,
.container-add .child .Categories .title input[type="radio"]:checked~label img {
  filter: invert(1) brightness(1000000%);
}


.saly-26-icon {
  /* position: absolute; */
  /* top: 78px; */
  /* left: calc(50% - 76.5px); */
  position: unset;
  width: 152px;
  height: 188px;
  object-fit: cover;
  display: block;
  margin: auto;
}



.toggle-check-input {
  width: 1px;
  height: 1px;
  display: none;
  position: absolute;
}

.toggle-check-text {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  background: #CCC;
  padding: 0.25em 0.5em 0.25em 2em;
  border-radius: 1em;
  min-width: 2em;
  color: #FFF;
  cursor: pointer;
  height: 30px;
  width: 50px;
  transition: background-color 0.15s;
}

.toggle-check-text:after {
  content: ' ';
  display: block;
  background: #FFF;
  width: 1.8em;
  height: 1.8em;
  border-radius: 1em;
  position: absolute;
  left: 0.08em;
  top: 0.05em;
  transition: left 0.15s, margin-left 0.15s;
}


.toggle-check-input:checked~.toggle-check-text {
  background: #34C759;
  padding-left: 0.5em;
  padding-right: 2em;
}

.toggle-check-input:checked~.toggle-check-textt:before {
  content: 'Yes';
  color: #34C759;
}

.toggle-check-textt:before {
  position: absolute;
  right: -32px;
  content: 'No';
  top: 2px;
  color: red;
}

.toggle-check-input:checked~.toggle-check-text:after {
  left: 86%;
  margin-left: -1.4em;
}

.swiper-backface-hidden .swiper-slide img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.cardrestroom .swiper {
  border-radius: 15px 15px 8.71px 8.71px;
  margin-bottom: 10px;
}


/* Add styles for the scroll-to-top button */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.scroll-to-top:hover {
  background-color: white;
  color: #0056b3;
  /* Adjust hover color as needed */
}

.footerImg {
  width: 24.911px;
  height: 34.09px;
  margin-right: 10px;
}

.mobflex .navicon {
  display: none;
}

.caretcircleleft-icon {
  width: 30px;
  position: absolute;
  left: -10px;
  border-radius: 20px;
  max-width: 30px;
  overflow: hidden;
  height: 30px;
  top: 55%;
  z-index: 1;
}

.caretcircleright-icon {
  width: 30px;
  position: absolute;
  border-radius: 20px;
  right: -10px;
  z-index: 1;
  top: 55%;

  max-width: 30px;
  overflow: hidden;
  height: 30px;
}

.pofilter {
  height: 140px;
}

.mobonly,
.divline {
  display: none;
}

.containerbody.d0.active .searchbar {
  z-index: 22;
}

.sign-up-wrapper .sign-up {
  color: #B1B1B1;
  font-family: "Alexandria", sans-serif;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit-profilee .cancel {
  color: #FF0000;
}

.styleTitle {
  border-radius: 16.754px;
  border: 0.524px solid #000;
  background: rgba(0, 0, 0, 0.13) !important;
  backdrop-filter: blur(10.471203804016113px);
  color: #32B768;
  margin-top: 100px;

}

.styleTitle .swal2-html-container {
  color: #fff;
}

.styleTitle2 {
  border-radius: 16.754px;
  border: 0.524px solid #000;
  background: rgba(0, 0, 0, 0.13) !important;
  backdrop-filter: blur(10.471203804016113px);
  color: #FF0000;
  margin-top: 100px;

}

footer .divFooter .footer {
  color: white;
}

.changecontainer .row .col-lg-7 {
  padding-right: 2px !important;

}


.navbar {
  z-index: 22222;
}

.addBtnPhoto {
  display: inline-flex;
  padding: 10.489px;
  justify-content: center;
  align-items: center;
  gap: 10.489px;
  border-radius: 31.467px;
  border: 0.88px solid #0061FF;
  box-shadow: 0px 0px 5.981px 0px rgba(0, 0, 0, 0.30);
  color: #000;
  font-size: 10.489px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 37px;
}

.add-photo-alternate-icon {
  width: 13.64px !important;
  border-radius: 0 !important;
}

.addbtndiv {
  text-align: end;
  padding-right: 5%;
}
.passResetty.modal.show .modal-dialog{
  transform: translateY(70%);
}
.modalReset.modal-body{
  background-color: white;
  border-radius: 12px;
}
.modalReset.modal-body input{
  border-radius: 11.84px;
  outline: none;
  border: none;
  background-color: #eee;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 7.9px 11.8px;
  box-sizing: border-box;
  font-size: 15px;
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Alexandria", sans-serif;
}
.modalReset.modal-body button{
  border: none;
  align-self: stretch;
  border-radius: 7.9px;
  background-color: #263069;
  height: 61.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7.9px;
  box-sizing: border-box;
  cursor: pointer;
  color: white;
  width: 80%;
  margin: 10px auto;
}
.modalReset.modal-body label{
  margin-top: 23px;
  color: #000;
  font-family: "Alexandria", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}
.modalReset.modal-body .sign-in{
  display: flex;
  width: 111.5px;
  padding:15px 10.575px;
  justify-content: center;
  align-items: center;
  gap: 5.575px;
  align-self: stretch;
  color: #FFF;
  text-align: center;
  font-family: "Alexandria";
  font-size: 16.295px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 15.79px;
    background-color: #263069;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    font-weight: 600;
    font-family: "Alexandria", sans-serif;
margin: auto;
}
.manx-museum-wrapper {
  position: relative;
  display: inline-block;
}
.manx-museum.hoverexceed {
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  max-width: 150px; 
}


/* .manx-museum-wrapper:hover .manx-museum.hoverexceed::after {
  content: attr(data-fullname);
  position: absolute;
  top: 0%;
  left: 0;
  white-space: nowrap;
  background-color: #fff;

  padding: 5px;
  border-radius: 3px;
  z-index: 22;
  display: block;
  font-size: 14px;
    transition: opacity 0.3s;
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-backdrop-filter: blur(10.471203804016113px);
    backdrop-filter: blur(10.471203804016113px);
    padding:2px 5px;
    gap: 8.377px;
    width: fit-content;
    background-color: white;
    text-wrap: wrap;
} */
.container-add .tooltiptext{
  bottom: 0% !important;
  left: 20% !important;

}
@media only screen and (max-width: 1520px) and (max-height: 757px) {
  .about-us .display .man img {
    top: -9%;
  }

  .about-us .display .location {
    top: 58%;
  }
}

@media only screen and (max-width: 1500px) {
  .contact-link {
    margin-right: 35.42px;
  }

  .about-link {
    margin-right: unset !important;
  }

  .navbtn {
    margin-right: unset;
  }

  .navbar .navbar-brand span {
    margin-right: 153px;
  }

  .navbar-expand-lg .navbar-nav {
    justify-content: space-around;
  }

  .navbar {
    padding: 10px !important;
  }

}

@media only screen and (max-width:1420px) {


  .container-add .child .child-01 .child0 .inputBox0 textarea {
    width: 90vw;
  }
}

@media only screen and (max-width: 1320px) {
  .room-details-container .rectangle-parent {
    margin: 10px 0;
    width: 98%;
  }

  .room-details-container .vector-wrapper {
    left: 3%;
  }
}

@media only screen and (max-width: 1280px) {
  .navbar .navbar-brand span {
    margin-right: 83px;
  }

  .containerbody .searchbar {
    width: 90%;
  }

  .searchbar .filters-parent {
    width: 125.1px;
  }

  .pofilter {
    width: 90vw;
  }

  .contact .frame-wrapper {

    width: auto;
    padding-right: 30px;
  }

  .about-us .display .location {
    top: 640px;
  }

  .room-details-container .rectangle-parent {

    width: 97%;

  }

  /* 
  .room-details-container .frame-parent13 {

    margin-bottom: 50px;
  } */

  .overlay.filtered {
    width: 40%;
    top: 29%;
    right: 1.5%;
  }

  .searchbar .frame-parent1 {
    font-size: 10px;
  }

  .searchbar .funnelsimple-icon {
    margin-left: 2px;
  }

  .searchbar .search-parent {
    width: 138.3px;
  }

}

@media only screen and (max-width: 1024px) {
  .containerbody.active .main {
    display: none;

  }
.pass.signup-signin .frame-div .sign-up{
margin-top: 0px;
}
.pass.signup-signin .frame-div .sign-up{
  color: #fff;
}
.signup-signin .frame-parent2{
  align-items: center;
}
  .room-details-container .row {
    border-radius: 7.032px;
    background: #F7F7F7;
  }

  .room-details-container .rectangle-parent {
    padding-bottom: 100px;
  }

  .aboutp-img {
    display: none;
  }

  .caretcircleright-icon,
  .caretcircleleft-icon {
    display: none;
  }

  .pofilter {
    height: fit-content;
  }

  .navicon1 {
    display: none;
  }

  .offcanvas-header {
    background-color: #263069;
  }

  .navbar {
    z-index: 22222;
  }

  .mobile.textttt {
    padding-top: 100px;

  }

  .signup-signin .frame-wrapper {
    width: 100%;

  }

  .signup-signin .forget-password-wrapper {
    justify-content: flex-start;
  }

  .signup-signin .forget-password {
    margin: 0px;
  }

  .signup-signin .frame-parent5 {
    margin-bottom: 10px;
  }

  .signup-signin .login-sign-in {
    height: auto;
  }

  .profileNav {
    margin-top: 10px;
  }

  .offcanvas {
    max-width: 80%;
  }

  .profileNav h3,
  .profileNav p {
    color: #fff;
  }

  .profileNav img {
    width: 59px;
    height: 59px;
  }

  .profileNav p {
    font-weight: 300;
  }

  .offcanvas-body .d-flex {
    align-items: flex-start;
  }

  .nav-item a.loginBtn {
    background-color: #fff;
    color: #263069 !important;
    width: 114px;
    height: 37px;
    padding: 7px 10px;
    border-radius: 12px;
  }

  .addRest {
    display: flex;
    width: 213px;
    padding: 8.755px;
    justify-content: center;
    align-items: center;
    gap: 6.129px;
    border-radius: 13.133px;
    border: 1.751px solid #fff;
    color: #263069;
    background-color: #fff;
    margin: 40px;
  }

  .mobflex a {
    color: #FFF !important;

    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .mobflex .navicon {
    display: block;
    height: 25px;
    display: flex;
    margin-right: 10px;
  }

  .mobflex a {
    font-size: 18px;
    display: flex;
    margin: 10px 0;
  }

  .mobflex {
    align-items: flex-start !important;
    padding-left: 20px;
    width: 100%;
  }

  .offcanvas-body .d-flex {
    flex-direction: column;
  }

  .containerbody .searchbar .main .item-div .title10 label img {
    filter: unset;
  }

  .containerbody .searchbar .main .item-div .title10 label h5 {
    color: #263069;
  }

  .navBorder {

    width: 80%;
    height: 1px;
    background: rgba(255, 255, 255, 0.30);
    margin: 20px auto;
  }

  .logout.nav-item {
    text-align: left;
    width: 100%;
    margin-left: 36px;
    color: #fff;
    margin-right: 10px;

  }

  .room-details-container .rectangle-parent {
    background-color: #fff;
    box-shadow: none;
  }

  .logout.nav-item img {
    margin-right: 10px;
    filter: brightness(100000%) invert(1);
  }

  .container-add .child .child-01 .child1 {
    margin-top: 30px;
    width: 100%;


  }

  .container-add .child .child-01 .child1 .child2 {
    padding: 0px 10px 0px 10px !important;
    height: 105px;
    width: 100%;

  }

  .container-add .child .divb {
    position: absolute;
    left: 40px;
    top: 340px;

  }

  .resCategories {
    border: 1.5px solid #000;
    height: 150px;
    border-radius: 10px;
    overflow-x: scroll;
    margin-top: 40px;
  }

  .container-add .child .Categories {
    height: 150px;
    width: max-content;
    border: none;
    margin-top: 0;
  }

  .container-add .child .divRestType {
    flex-wrap: wrap;
  }

  .container-add .child .divRestType .facilitiess {
    top: 150px;
    padding: 2px 10px;
    left: 20px;
  }

  .container-add .child .divRestType .facilities {
    margin-top: 20px;
  }

  .container-add .child .divRestType .restroomType {
    width: 100%;
    height: 125px;
    padding: 0px 10px 0px 10px;
  }

  .container-add .child .divRestType .facilities .type0 {
    width: 145px;
  }

  .container-add .child .parent .centerr {
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
    width: 100%;
  }

  .container-add .child .parent .centerr .child00 {
    width: 100%;
    justify-content: space-between;
  }

  .warni {
    display: none;
  }

  .container-add .child .parent .centerr .child01 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    width: 100%;
  }

  .kspan {
    font-size: 12px;
  }

  /* .keyleft {
    margin-left: -30px;
  } */

  .container-add .child .timee {
    padding: 5px 30px 5px 5px;
  }

  .container-add .child .line .line0,
  .container-add .child .line1 .line2 {
    width: 100%;
  }

  .container-add .child .timee .checkbox78 .typegraphy01 .dis {
    margin-left: 0px;
  }

  .container-add .child .timee .checkbox78 .typegraphy01 {
    gap: 0px;
  }

  .container-add .child .timee .checkbox78 .typegraphy01 .timme {
    width: 70%;
  }

  .timme .w-100 {
    width: 75% !important;
    margin-left: auto;
  }

  .container-add .child .timee .checkbox78 .typegraphy01 .timme input {
    font-size: 14px;
  }

  .container-add .child .timee .checkbox78 .typegraphy01 .timme {
    gap: 38px;
  }

  .container-add .child .child-01 .child0,
  .container-add .child .child-01 .child0 .inputBox0 input,
  .container-add .child .child-01 .child0 .inputBox0 {
    width: 100%;
  }

  .add-photo-alternate-parent {
    margin-left: 0px;
    margin: auto;
  }

  .labelphoto {
    width: 100%;
  }

  .about-us .display .typegraphy0 {
    width: 90%;
    margin-top: 90px;
    border: 0.44px solid #000;

    text-align: center;
  }

  .about-us .display .man img {
    left: -80px;
    top: -40px;
  }

  .location {
    right: -20px !important;
    bottom: -60px !important;

  }

  .location img {
    width: 150px;
  }

  .edit-profilee .component-15-parent {

    width: fit-content;

    margin: 10px auto;

  }

  .edit-profilee .component-15-icon {
    border-radius: 32px;
    border: 0.44px solid #000;

  }

  .edit-profilee .account-data {

    position: absolute;
    top: -300px;

    width: 100%;
    text-align: center;
    left: 0;
  }

  .favoritePage .row {
    padding: 0 10px;
  }

  #root {
    overflow-x: hidden;
  }

  .searchbar.mobb .frame-div,
  .searchbar.mobb .frame-wrapper {
    width: unset;
  }

  .searchbar .frame-parent {
    width: unset;
    box-shadow: none;
    background-color: transparent;
  }

  .searchbar.mobb .search-for-restroom-wrapper {
    display: block;
    width: 300px;
    position: relative;
    box-shadow: 0px 0px 4.84px rgba(0, 0, 0, 0.6);
    border-radius: 13.19px;
    background-color: #fff;
    display: flex;
    margin: auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 17px;
    color: #263069;
    padding: 15px;


  }

  .containerbody .overlay {
    left: 0;
  }

  .searchbar.mobb .search-for-restroom-wrapper::placeholder {
    background: url(search.svg);
    background-size: 19.253px;
    object-fit: contain;
    background-repeat: no-repeat;
    text-indent: 30px;
    font-size: 13.44px !important;
    color: #ABB7C2;
  }

  .searchbar .filter {
    /* width: max-content; */
    position: relative;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    background-color: #fff;
    width: 95vh !important;
    position: absolute;
    top: 0px;
    max-width: unset;

    text-align: left;
    z-index: 222;
    font-size: 16.89px;
    color: #263069;
    min-height: 98vh;
  }

  .filters {
    position: absolute;
    top: 12px;
    left: 25px;
    font-size: 24px;
  }

  .searchbar.mobb .quick-shortcuts {
    position: absolute;
    top: 65px;
    left: 25px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  .searchbar.mobb .gender {
    position: absolute;
    top: 222px !important;
    left: 25px !important;
    font-size: 16px !important;
    font-weight: 600;
    color: #000;
  }

  .searchbar.mobb .restroom-type {
    position: absolute;
    top: 305px !important;
    left: 25px !important;
    font-size: 16px !important;
    font-weight: 600;
    color: #000;
  }

  .room-details-container .frame-child {
    height: 164px;
    width: 100% !important;

  }

  .searchbar.mobb .categories {
    position: absolute;
    top: 388px !important;
    left: 25px !important;
    font-size: 16px !important;
    font-weight: 600;
    color: #000;
  }

  .searchbar.mobb .vector-icon {
    width: 14px;
    position: relative;
    height: 13px;
  }

  .searchbar.mobb .open-now {
    position: relative;
  }

  .searchbar.mobb .component-67 {
    position: absolute;
    top: 95px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon1 {
    width: 12px;
    position: relative;
    height: 15px;
  }

  .searchbar.mobb .component-68 {
    position: absolute;
    top: 95px;
    left: 138px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon2 {
    width: 13px;
    position: relative;
    height: 15px;
  }

  .searchbar.mobb .component-69 {
    position: absolute;
    top: 169px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 10px;
  }

  .searchbar.mobb .vector-icon3 {
    width: 17px;
    position: relative;
    height: 17px;
  }

  .searchbar.mobb .vector-wrapper {
    position: absolute;
    top: 17px;
    left: 309px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .searchbar.mobb .vector-icon4 {
    width: 10px !important;
    position: relative !important;
    height: 15px !important;
    top: unset !important;
    left: unset !important;
  }

  .searchbar.mobb .component-71 {
    position: absolute;
    top: 132px;
    left: 235px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .group-icon {
    width: 12.9px;
    position: relative;
    height: 15px;
  }

  .searchbar.mobb .component-74 {
    position: absolute;
    top: 95px;
    left: 267px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .component-77 {
    position: absolute;
    top: 132px;
    left: 154px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .component-79 {
    position: absolute;
    top: 132px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 10px;
  }

  .searchbar.mobb .vector-icon7 {
    width: 11px;
    position: relative;
    height: 15px;
  }

  .searchbar.mobb .component-86 {
    position: absolute;
    top: 252px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon8 {
    width: 12px;
    position: relative;
    height: 12px;
  }

  .searchbar.mobb .component-92 {
    position: absolute;
    top: 252px;
    left: 238px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .component-90 {
    position: absolute;
    top: 252px;
    left: 140px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .component-94 {
    position: absolute;
    top: 335px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon11 {
    width: 13px;
    position: relative;
    height: 10px;
  }

  .searchbar.mobb .component-97 {
    position: absolute;
    top: 335px;
    left: 111px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon12 {
    width: 14px;
    position: relative;
    height: 12px;
  }

  .searchbar.mobb .component-125 {
    position: absolute;
    top: 418px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon13 {
    width: 13px;
    position: relative;
    height: 14px;
  }

  .searchbar.mobb .component-126 {
    position: absolute;
    top: 418px;
    left: 163px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .component-127 {
    position: absolute;
    top: 455px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon15 {
    width: 11px;
    position: relative;
    height: 13px;
  }

  .searchbar.mobb .component-128 {
    position: absolute;
    top: 455px;
    left: 190px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon16 {
    width: 13px;
    position: relative;
    height: 13px;
  }

  .searchbar.mobb .component-129 {
    position: absolute;
    top: 492px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon17 {
    width: 12px;
    position: relative;
    height: 14px;
  }

  .searchbar.mobb .component-130 {
    position: absolute;
    top: 492px;
    left: 177px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .component-131 {
    position: absolute;
    top: 529px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .component-132 {
    position: absolute;
    top: 529px;
    left: 90px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon20 {
    width: 16px;
    position: relative;
    height: 11px;
  }

  .searchbar.mobb .component-133 {
    position: absolute;
    top: 529px;
    left: 160px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon21 {
    width: 16px;
    position: relative;
    height: 12px;
  }

  .searchbar.mobb .component-134 {
    position: absolute;
    top: 566px;
    left: 26px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon22 {
    width: 14px;
    position: relative;
    height: 16px;
  }

  .searchbar.mobb .component-135 {
    position: absolute;
    top: 566px;
    left: 176px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon23 {
    width: 16px;
    position: relative;
    height: 16px;
  }

  .searchbar.mobb .component-136 {
    position: absolute;
    top: 529px;
    left: 231px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon24 {
    width: 15px;
    position: relative;
    height: 20px;
  }

  .searchbar.mobb .component-137 {
    position: absolute;
    top: 603px;
    left: 25px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .vector-icon25 {
    width: 4.3px;
    position: relative;
    height: 16.5px;
  }

  .component-138 {
    position: absolute;
    top: 603px;
    left: 202px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
  }

  .searchbar.mobb .apply {
    position: relative;
    font-weight: 600;
  }

  .searchbar.mobb .apply-wrapper {
    position: absolute;
    top: 656px;
    left: 25px;
    border-radius: 12px;
    background-color: #263069;
    width: 112px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    color: #fff;
  }

  .searchbar.mobb .vector-icon26 {
    width: 16.7px;
    position: relative;
    height: 16px;
  }

  .searchbar.mobb .vector-parent {
    position: absolute;
    top: 656px;
    left: 157px;
    border-radius: 12px;
    border: 1.5px solid #ff0000;
    box-sizing: border-box;
    width: 113px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    font-size: 16px;
    color: #ff0000;
  }

  .filter-child {
    position: absolute;
    top: 213.5px;
    left: calc(50% - 155px);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    width: 311px;
    height: 1px;
  }

  .filter-item {
    position: absolute;
    top: 296.5px;
    left: calc(50% - 155px);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    width: 311px;
    height: 1px;
  }

  .filter-inner {
    position: absolute;
    top: 382.5px;
    left: calc(50% - 155px);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    width: 311px;
    height: 1px;
  }

  .filter {
    width: 100%;
    position: relative;
    border-radius: 10px;
    background-color: #f9f9f9;
    height: 706px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #263069;
  }


  .searchbar .filters-parent {
    width: unset;
    border-left: 0;
  }

  .containerbody .searchbar .main .item-div .title10 input[type="checkbox"]~label {
    width: 93px;
  }

  .containerbody .searchbar .main .item-div .title10 label img {
    margin-top: 6px;
  }

  /* 
  .overlay.filtered {
    position: absolute;
    width: 90%;
    height: 430px;
    top: 20%;
    right: unset;
    left: 6%;
    border: 2px solid #000;
    z-index: 11;
  } */


  .containerbody {
    background: #F9F9F9;
  }

  .roomSelected {
    margin-top: 680px;
  }

  .containerbody.d0.active .roomSelected {
    z-index: 2222222;
  }

  .room-details-container .rectangle-parent {
    width: 90%;
    margin-left: 0px;
    padding-left: 0;
  }

  .room-details-container .frame-wrapper {
    width: 100%;
    margin-left: 10px;
  }

  .room-details-container .vector-wrapper {
    position: absolute;
    top: 0px;
    left: 5px;

  }

  .room-details-container .rectangle-parent {
    /* text-align: center; */
    padding: 10px;
    margin: auto;
  }

  .room-details-container .frame-wrapper1,
  .room-details-container .row-parent {
    width: 100%;
  }

  .room-details-container .frame-parent13 {
    width: 100%;
  }

  .room-details-container .frame-child {
    width: 100%;
    height: unset;
  }

  .filter input {
    display: none;
  }

  .filter input[type="checkbox"]:checked+label {
    background-color: #263069 !important;
    color: #fff;

  }

  .filter input[type="radio"]:checked+label {
    background-color: #263069 !important;
    color: #fff;
  }

  .filter input+label img.active {

    display: none;
  }

  .container-add .child .child-01 .child0 .inputBox0 span {
    top: 0;
  }

  .filter input[type="checkbox"]:checked+label img {

    display: block;
  }

  .filter input[type="radio"]:checked+label img,
  .filter input[type="checkbox"]:checked+label img {
    filter: invert(1) brightness(100000000%) !important;
  }

  .tabdiv {
    /* height: 100vh; */
    border-radius: 56.794px 56.794px 0px 0px !important;
    background: #FFF;
    position: relative;
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.25);
    padding-top: 60px !important;
    width: 98% !important;
    margin: auto !important;

  }

  .roomSelected .col-lg-5 {
    border: none !important;
  }

  .tabdiv .divline {
    position: absolute;
    top: 30px;
    background: rgba(0, 0, 0, 0.20);
    width: 157.13px;
    height: 5.679px;
    left: 40%;
  }


  .room-details-container .frame-parent13 {
    margin-bottom: 80px;
  }

  /* .filter input[type="checkbox"]:checked+label img {
    display: none !important;
  } */

  .filter input[type="checkbox"]:checked+label img.active {
    display: block !important;
  }

  .gislocation-poi-o-parent {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    width: 120px;
    margin-bottom: 5px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
    margin-top: 2px;
    margin-left: auto;
  }

  .divline {
    display: block;
  }

  .containerbody {
    height: 100vh;
  }

  .re-rest-pass .send-message-1-1-parent img {
    width: 90%;
    object-fit: contain;
    object-position: center;
  }

  .re-rest-pass .send-message-1-1-parent {
    width: unset;
  }

  .signup-signin .frame-wrapper3 {
    margin-top: 30px;
  }

  .signup-signin .frame-parent4 {
    margin-top: 80px;
  }

  .signup-signin .frame-wrapper {
    height: unset;
  }

  .signup-signin .frame-wrapper {
    min-height: 70vh;
  }

  .signup-signin .login-sign-in {
    margin-top: 50px;
  }

  .contact .frame-wrapper5 {
    width: unset;
  }

  .favoritePage .container {
    padding: 40px 24px;
  }

  .edit-profilee .edit-profile {
    margin-bottom: 100px;
    height: unset;
  }

  .container-add {
    margin-top: 40px;
  }



  .changecontainer .col-lg-5 {
    display: none;
  }

  /* .containerbody.d0 .changecontainer>.row .row {
    height: 75vh;
  } */

  .searchbar.mobb .search-for-restroom-wrapper {
    display: flex;
    width: 55vw;
    height: 67.33px;
    padding: 0px 8.977px;
    align-items: center;
    gap: 6.733px;
    flex-shrink: 0;
    border-radius: 11.222px;
    box-shadow: 0px 0px 11.191px 6.994px rgba(13, 10, 44, 0.06);
  }

  .searchbar.mobb .search-for-restroom-wrapper::placeholder {
    color: #ABB7C2;
    font-size: 20.199px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .searchbar .gislocation-poi-o-parent {
    margin: 0 20px;
    display: flex;
    height: 67px;
    padding: 13.989px;
    justify-content: center;
    align-items: center;
    width: 150px;
    background-color: #fff;
    border-radius: 16.787px;
    background: #FFF;
    box-shadow: 0px 0px 11.191px 0px rgba(0, 0, 0, 0.06);
  }

  .containerbody.d0 .changecontainer>.row .row {
    background-color: #F9F9F9;
  }

  .mobonly .gislocation-poi-o-parent {
    display: none;
  }

  .component-143 {
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    color: #000;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
  }

  .iconnnn {
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #263069;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
    color: white;
  }

  .changecontainer {
    height: 0;
  }

  .room-details-container .frame-wrapper {
    margin-left: 0px;
  }

  .room-details-container .vector-wrapper {
    left: -5px;
  }

  /* .changecontainer .col-lg-4{
      display: none;

    } */

  .containerbody.d0.active .changecontainer>.row {
    /* transition: all 3s ease-in-out; */
    transform: translateY(0px);
  }

  .mobonly {
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .overlay.filtered {
    z-index: 0;
  }

  .room-details-container .frame-div {
    width: unset;
  }

  .room-details-container {

    overflow-y: auto;
    transition: top 0.3s ease;
  }

  .divline {

    cursor: grab;
    text-align: center;
  }

  .divline:active {
    cursor: grabbing;
  }

  .overlay.filtered {
    display: none;

  }

  .containerbody.mape .overlay.filtered {
    z-index: 0;
    width: 95%;
    height: 50vh;
    border: 1px solid #000;
    left: 1%;
    top: 250px;
    margin: auto;
    display: block;

  }

  .containerbody.mape .cardrestroom.col-sm-12 {
    display: none;

  }

  .containerbody.mape.containerbody.d0 .changecontainer>.row .row,
  .containerbody.mape.containerbody.d0 .changecontainer>.row .row {
    height: 60px !important;
  }

  .component-143 {
    margin: 0 10px;

  }

  .searchbar.mobb label {
    font-size: 15px;
  }

  .popReview.modal-body {
    width: 110% !important;
    margin-left: -5%;
    margin-top: 100px;
  }

  .room-details-container .frame-wrapper {
    height: 100vh;
    overflow-y: scroll;
  }

  .addBtnPhoto {
    display: flex;
    width: 110px;
    height: 34.53px;
    padding: 9.535px;
    justify-content: center;
    align-items: center;
    gap: 4.545px;
    border-radius: 5.582px;
    border: 0.8px solid #0061FF;
    box-shadow: 0px 0px 5.438px 0px rgba(0, 0, 0, 0.30);
    color: #000 !important;
    font-size: 9.535px;
    font-style: normal;
    margin-left: 10px;
    font-weight: 500;
    line-height: normal;
  }

  .add-photo-alternate-icon {
    width: 13.64px !important;
    border-radius: 0 !important;
  }

  .room-details-container .component-14 {
    width: 41.4px;
    position: absolute;
    right: 40%;
    top: 15px;
    border: none !important;
  }
}

.containerbody {
  z-index: 0;
}

.modelform .modal-content {
  background-color: #fff !important;
  margin-top: 200px;
}

@media only screen and (min-width: 700px) and (max-width: 1024px) {
  .modal.show .modal-dialog {
    margin-top: 200px;
  }

  .divline {
    display: block;
  }

  .about-us .display .typegraphy0 {
    width: 80%;
  }

  .containerbody {
    height: 100vh;
  }


  .tabdiv.active {
    overflow-y: scroll;
  }

  .searchbar.mobb .search-for-restroom-wrapper {
    display: block;
    /* width: 80vw; */
  }

  .containerbody.d0 .changecontainer>.row .row {
    height: 100vh;
    padding-bottom: 180px;

  }

  .containerbody .searchbar .main .item-div .title10 input[type="checkbox"]~label {
    width: 124.301px;
  }

  .containerbody.mape .overlay.filtered {
    z-index: 0;
    width: 95%;
    height: 60vh;
    border: 1px solid #000;
    left: 2%;
    top: 25%;
    margin: auto;

  }

  .container-add .child .child-01 .child0 .inputBox0 span {
    top: 0;
  }

  .room-details-container .frame-parent {

    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 100px;
  }
  .room-details-container .swiper-slide img{
    width: 504px;
    height: 270px;
    flex-shrink: 0;
    border-radius: 24.611px;

  }
  /* .searchbar.mobb .component-68 {
    left: 238px;
  }

  .searchbar.mobb .component-74 {

    left: 467px;
  }

  .searchbar.mobb .component-71 {

    left: 465px;
  }

  .searchbar.mobb .component-77 {
    left: 254px;
  }

  .searchbar.mobb .component-92 {
    left: 458px;
  }

  .searchbar.mobb .component-90 {
    left: 240px;
  }

  .searchbar.mobb .component-97 {

    left: 251px;
  }

  .searchbar.mobb .vector-wrapper {
    left: 90%;
  }

  .searchbar.mobb .component-126 {
    left: 343px;
  }

  .searchbar.mobb .component-128 {
    position: absolute;
    top: 455px;
    left: 340px;
  }

  .searchbar.mobb .component-130 {
    position: absolute;
    top: 492px;
    left: 347px;
  }

  .searchbar.mobb .component-136 {
    left: 451px;
  }

  .searchbar.mobb .component-133 {
    left: 300px;
  }

  .searchbar.mobb .component-132 {
    left: 140px;
  }

  .searchbar.mobb .component-135 {

    left: 276px;
  }

  .searchbar.mobb .component-135 {

    left: 346px;
  }

  .searchbar.mobb .component-138 {

    left: 362px;
  }

  .searchbar.mobb .vector-parent {
    left: 357px;
  } */

  .searchbar .filter {
    max-width: 400px;
    width: 100% !important;
    min-height: 600px;
    height: 750px !important;
    left: 25%;
    top: 0px;
    max-height: max-content;
  }

  /* 
  .overlay.filtered {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    right: unset;
    left: 0%;
    border: 0px;
    z-index: 0;
  } */
  .cardrestroom .rectangle-parent {
    border: none !important;
  }

  .roomSelected {
    overflow: unset;
  }

  .room-details-container .frame-wrapper,
  .room-details-container .frame-container,
  .room-details-container .rectangle-parent {
    height: 100%;
  }

  .tabdiv.active {
    /* transform: translateY(-50%); */
  }

  .mobonly {
    display: block;
  }

  .signup-signin .login-sign-in {
    margin-top: 40px;
  }

  .signup-signin .forget-password {
    margin-left: 80px;
  }

  .nav-item {
    margin-top: 40px;
  }

  .contact .have-any-questions {
    font-size: 30px;
  }

  .contact .contact-with-us {
    font-size: 22px;
  }

  .contact .frame-div,
  .contact .frame-wrapper1,
  .contact .submit-wrapper {
    width: 505px;
    margin-top: 30px;
  }

  .contact .submit-wrapper {
    margin-top: 10px;
  }

  .contact .frame-parent {
    margin-top: 0 !important;
  }

  .about-us .display .typegraphy0 span {
    font-size: 22px;
  }

  .about-us .display {
    margin-bottom: 100px;
  }

  .about-us .display .typegraphy0 {
    margin-top: 110px !important;
  }

  .about-us .display .man img {
    left: -50px;
    top: -70px;
  }

  .edit-profilee .component-15-parent {

    height: 318px;

    width: 242px !important;
  }

  .edit-profilee .component-15-icon {
    width: 213px;
    height: 213px;
  }

  .edit-profilee .ahmad-ali span {
    font-size: 22px;
  }

  .edit-profilee .account-data {
    position: unset;
    text-align: left;
  }

  .edit-profilee .account-data-parent,
  .edit-profilee .ahmadali123gmailcom-wrapper {
    width: 450px;
  }

  .edit-profilee .edit-profile {
    height: unset;
  }

  .edit-profilee .account-data {
    margin-top: 40px;
  }

  .edit-profilee .frame-div {
    width: 450px;
  }

  .edit-profilee .cancel-wrapper {
    width: 164.5px;
  }

  .edit-profilee .save-wrapper {
    width: 194.5px;
  }

  .container-add .child .child-01 .child0,
  .container-add .child .child-01 .child0 .inputBox0 input,
  .container-add .child .child-01 .child0 .inputBox0 {
    width: 90%;
  }

  .container-add .child .child-01 {
    flex-wrap: nowrap;
  }

  .container-add .child .child-01 .child1 {
    width: 40%;
  }

  .container-add .child .child-01 .child1 .child2 {
    flex-direction: column;
  }

  .container-add .child .child-01 .child1 .child2,
  .container-add .child .child-01 {
    height: 227px;
  }

  .container-add .child .child-01 .child0 {
    justify-content: space-around;
    height: 227px;

  }

  .container-add .child .child-01 {
    align-items: flex-end;
  }

  .container-add .child .divb {
    position: absolute;
    left: 90px;
    top: 280px;
  }

  .container-add .child .divRestType .facilities {
    width: 90%;
    height: 242px;

  }

  .container-add .child .divRestType {
    flex-wrap: nowrap;
  }

  .container-add .child .divRestType .restroomType {
    width: 40%;
    flex-direction: column;
  }

  .container-add .child .divRestType {
    align-items: center;
  }

  .container-add .child .divRestType .restroomType {
    height: 242px;
  }

  .container-add .child .divRestType .facilitiess {
    top: 0px;
    left: 340px;
  }

  .container-add .child .divRestType .restroomType {
    margin-top: 20px;
  }

  .container-add .child .divRestType .restroomm {
    top: -5px;
  }

  .container-add .child .parent .centerr {
    width: 70%;
    margin: auto;
  }

  .timme .w-100 {
    width: 100% !important;
  }

  .typegraphy01 {
    justify-content: space-between;
  }

  .container-add .child .timee .checkbox78 .typegraphy01 .timme {
    width: unset;
  }

  .room-details-container .frame-child {
    width: 504px !important;
    height: 270px;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 700px) {
  .mobonly {
    display: block;
  }


  .tabdiv.active {
    transform: translateY(-65%);
    z-index: 222;
  }

  .component-143 {
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
  }

  .iconnnn {
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #263069;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
    color: white;
  }

  .roomSelected {
    overflow: unset;
  }

  .tabdiv .divline {

    left: 30%;
  }

  .room-details-container .new-york-parent {
    width: unset;
  }

  .room-details-container .manhattan {
    text-align: left;
  }

  /* .containerbody {
    height: unset;
  } */
  .tabdiv {
    margin-top: 0px !important;
    width: 106% !important;
  }

  .tabdiv.active {
    transform: translateY(-60%);
    z-index: 222;
  }


  .signup-signin .frame-wrapper3 {
    margin-top: 0px;
  }

  .signup-signin .frame-parent4 {
    margin-top: 0px;
  }

  .signup-signin .frame-div .sign-up {
    margin-top: 23px;
    color: #000;
    font-family: "Alexandria", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .signup-signin .sign-in-container {
    width: 270px;
  }

  .signup-signin .frame-div {
    margin-top: 0;
  }

  .containerbody.d0 .changecontainer>.row .row {
    height: 746px;
    padding-bottom: 300px;
  }

  /* 
  .room-details-container .frame-parent13 {
    margin-bottom: 180px;
  } */

  .containerbody.active .searchbar .gislocation-poi-o-parent {
    display: none;
  }

  .signup-signin .sign-in-wrapper,
  .signup-signin .sign-up-wrapper {

    display: flex;
    width: 111.5px;
    padding: 5.575px;
    justify-content: center;
    align-items: center;
    gap: 5.575px;
    align-self: stretch;
    color: #FFF;
    text-align: center;
    font-family: "Alexandria";
    font-size: 16.295px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .sign-up-wrapper .sign-up {
    font-size: 16.295px;

  }

  .contact .frame-wrapper {
    width: 90%;
    margin-top: 30px;
  }

  .gislocation-poi-o-parent {
    position: absolute;
    top: 210px;
    right: -20px;

  }

  .searchbar.mobb .search-for-restroom-wrapper {
    width: 78vw !important;
    border-radius: 8.022px;
    box-shadow: 0px 0px 8px 5px rgba(13, 10, 44, 0.06);
    height: 48.133px;
    padding: 0px 6.418px;
    align-items: center;
    gap: 4.813px;
    flex-shrink: 0;
  }

  .mob {
    padding: 12px;
    border-radius: 8px;
  }

  .searchbar.mobb .search-for-restroom-wrapper {
    font-size: 14.44px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .searchbar .gislocation-poi-o-parent {
    height: 57px;
    width: 130px;
    background-color: #fff;
    border-radius: 12.787px;
  }

  .room-details-container .rectangle-parent {
    width: 98%;
    height: 100%;
  }

  .tabdiv {
    width: 106% !important;
    margin-left: -10px !important;
  }

  .containerbody.d0 .changecontainer>.row .row {
    padding: 0 24px;
  }

  .popReview .write-review-window-child {
    width: 95%;
  }

  .room-details-container .frame-container {
    height: max-content !important;
  }

  .containerbody .row {
    margin-right: -10px !important;
  }

  .room-details-container .row {
    margin-right: unset !important;
  }

  .filter label {
    font-size: 14px;
  }

  .d0.active {
    padding-top: 60px;
  }

  .contact .frame-wrapper {
    padding-right: 0;
  }

  .searchbar.mobb .search-for-restroom-wrapper{
    width: 65vw !important;
  }
  .navbar {
    padding: 5px !important;
  }

  .containerbody.d0.active .roomSelected {
    /* transform: unset; */
    z-index: 22;
  }

  .roomSelected {
    margin-top: 550px;
  }

  .room-details-container .swiper-slide {
    /* width: 260px;
    height: 154px; */
    flex-shrink: 0;
    border-radius: 13px;

  }

  .room-details-container .mySwiper2 {
    height: 154px !important;
  }

  .room-details-container .swiper-slide img {
    width: 260px;
    height: 154px;
    flex-shrink: 0;
    border-radius: 13px;


  }

  .container-add .child .divRestType .restroomm,
  .container-add .child .divb,
  .container-add .child .child-01 .child0 .inputBox0 input:valid~span,
  .container-add .child .child-01 .child0 .inputBox0 input:focus~span {
    font-size: 15px;
  }

  .tabdiv {

    border-radius: 30px 30px 0px 0px !important;
  }

  .tabdiv .divline {
    width: 83px;
    height: 3px;
    background: rgba(0, 0, 0, 0.20);
    left: 38%;
  }

  .cardrestroom .rectangle-parent {
    box-shadow: 0px 0px 5.1px rgba(0, 0, 0, 0.25);
    border-radius: 17.58px 17.58px 10.21px 10.21px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    height: 369.5px;
    text-align: left;
    font-size: 16.3px;
    color: #fff;
  }

  .room-details-container .courtney-henry {
    color: #333;

    font-size: 14.065px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.141px;
  }

  .room-details-container .row-child {
    width: 14.065px !important;
    height: 14.065px !important;
  }

  .room-details-container .mins-ago {
    color: #333;
    text-align: right;
    font-size: 12.306px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.123px;
  }

  .room-details-container .frame-wrapper {
    padding: unset;
  }

  .room-details-container button {
    color: #FFF;
    font-size: 11.361px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .room-details-container .stepney-city-farm {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .room-details-container .frame-container {
    padding: unset;
  }

  .room-details-container .frame-child {
    width: 260px !important;
    height: 154px !important;
    flex-shrink: 0;
    border-radius: 13px !important;
  }

  /* .room-details-container .swiper-slide {
    width: 260px !important;
    height: 154px !important;
  } */

  .room-details-container .frame-div {
    width: unset;
  }

  .manhattan {
    font-size: 12px;
  }
  .mob.search{
    color: #fff;
    font-size: 25px;
    padding: 7px 12px;
  }
  /* .room-details-container .swiper-wrapper{
    width: 260px !important;
    overflow: visible !important;
    margin-left: unset !important;
  } */
  .searchbar.mobb .search-for-restroom-wrapper {
    width: 63vw !important;
  }
  .searchbar.mobb .frame-div, .searchbar.mobb .frame-wrapper,.searchbar .frame-container{
    width: 100%;
    justify-content: space-between;
  }
}

.room-details-container .space5,
.room-details-container .space4,
.room-details-container .space3,
.room-details-container .space2,
.room-details-container .space1 {
  border-radius: 4.98px;
}

.cardrestroom.map {
  z-index: 222222222;
  width: unset;
  margin-left: -10px;
  height: unset;
}

.cardrestroom.map .rectangle-parent {
  height: 180px;
  width: 200px;
}

.cardrestroom.map .swiper {
  height: 100px;
  width: 200px;
  min-height: unset;
  border: none;
}

.cardrestroom.map .rectangle-parent {

  box-shadow: unset;

  border: none;
  min-height: unset;

}

.cardrestroom.map .manx-museum {

  font-size: 10px;
}

.cardrestroom.map .ratings1,
.cardrestroom.map .more-details {

  font-size: 8px;
}

.empty {
  text-align: center;
  padding-top: 40px;
}

.empty img {
  width: 111px;
  height: 111px;
  flex-shrink: 0;
}

.empty h3 {
  color: #000;
  text-align: center;
  ;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.empty p {
  color: #6228D7;
  text-align: center;
  ;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 300px;
  margin: auto;
}

.empty a {
  display: flex;
  width: 213px;
  padding: 8.755px;
  justify-content: center;
  align-items: center;
  gap: 6.129px;
  color: #FFF;
  ;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  /* line-height: normal; */
  border-radius: 13.133px;
  border: 1.751px solid #263069;
  background: #263069;
  margin: auto;
}

.empty a img {
  height: 20px;
  width: 20px;
}

.reset-pass-success {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reset-pass-success img {
  width: 134.03px;
  height: 134.03px;
  flex-shrink: 0;
}

.reset-pass-success h3 {
  color: #374151;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 58.843px;
  /* 163.452% */
}

.reset-pass-success p {
  color: #828282;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.325px;
  /* 143.02% */
}

.reset-pass-success a {
  color: #231FFF;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline !important;
}

#iconContainer img {
  filter: brightness(0);
}

#iconContainer.iconnnn img {
  filter: brightness(100%);
}

#component143Container.iconnnn img {
  filter: brightness(100%) invert(1);
}

@media only screen and (max-width: 414px) {
  .container-add .child .timee .checkbox78 .typegraphy01 .dis span {
    font-size: 14px;
  }

  .container-add .child .timee .checkbox78 .typegraphy01 .dis {
    width: 101px;
    gap: 50px;
  }

  .toggle-check-input:checked~.toggle-check-textt:before {
    content: '';
  }

  /* .container-add .child .parent .centerr .child01 .kl{
    gap: 0;
  } */
  .kspan {
    width: 55px;
  }

  .container-add .child .parent>span,
  .do {
    font-size: 12px;

  }

  .timme .w-100 {
    width: 70% !important;
  }

  .tabdiv.active {
    height: 65vh !important;
  }

  .searchbar.mobb label {
    font-size: 14px !important;
  }

  .searchbar .filter {
    height: 98vh !important;
    width: 95vw !important;
    top: -40px;
    left: 0;
  }

  .searchbar.mobb .vector-wrapper {
    left: unset;
    right: 30px
  }

  .searchbar.mobb .component-74 {
    left: 247px;
  }

  .tabdiv {
    margin-top: -100px !important;
  }


  .tabdiv.active {
    height: 65vh !important;
    width: 105% !important;
    margin-left: -6px !important;
  }

  .reviewempty img {
    width: 256.581px !important;
  }

  .tabdiv.active {
    transform: translateY(-70%);
  }

  .containerbody .searchbar .main .item-div .title10 label h5 {
    font-size: 9px !important;
  }

  .containerbody .searchbar .main .item-div .title10 label img {
    width: 24px;
    height: 24px;

  }

  .containerbody .searchbar .main .item-div .title10 {
    min-width: 93.633px;
    gap: 7.203px;

  }

  .containerbody .searchbar .main .item-div .title10 {
    height: 55px;
  }

  .containerbody .searchbar .main .item-div .title10 input[type="checkbox"]~label {
    width: 93.633px;
    height: 75px;
  }

  .d0 {
    padding-top: 50px;
  }

  .searchbar.mobb .search-for-restroom-wrapper {
    height: 47.53px;
  }

  .mob {
    padding: 12px;
  }

  .pass.signup-signin .frame-parent2 {
    width: 100% !important;
  }

  .pass.signup-signin .frame-wrapper6,
  .pass.signup-signin.pass .frame-wrapper {
    width: 100%;
  }

  .gislocation-poi-o-parent {
    top: 180px;
  }

  .containerbody .searchbar .main .item-div .title10 input[type="checkbox"]~label {

    height: 65px;
    border: 1px solid #263069;
  }

  .containerbody .searchbar .main .item-div .title10 input[type="checkbox"]~label {

    gap: 0px;
  }

  .searchbar .gislocation-poi-o-parent {
    height: 47px;
  }

  .contact .frame-wrapper {
    padding: 10px;
  }

  .containerbody.mape .overlay.filtered {

    top: 220px;
  }

  .containerbody.d0 .changecontainer>.row .row {
    padding: 0px !important;
  }

  .containerbody .row .row .col-sm-12 {
    padding: 15px 20px !important;
  }

  footer .divFooter .typegraphy div {
    width: unset;
  }
  .container-add{
    width: 98%;
  }
  footer .divFooter {
    padding-left: 5px;
    padding-right: 5px;

  }

  footer .divFooter .typegraphy div {
    color: #E6E6E6;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  footer .divFooter .link-page ul {
    margin-top: 40px;
  }

  .containerbody .searchbar {
    padding: 14.07px 0;
  }

  .room-details-container .frame-child {
    width: 228px !important;
    height: 134px !important;
    height: unset !important;
  }

  .add-photos {
    font-size: 9px;
  }

}

@media only screen and (max-width: 360px) {

  .contact .frame-parent,
  .contact .have-any-questions-parent {
    width: 320px;
  }

  .signup input,
  .signup-signin .enter-your-email,
  .password-password {
    width: 310px !important;
  }

  .profileNav {
    padding-left: 20px !important;
  }

  .mobflex a {
    font-size: 16px;
  }

  .addRest {
    margin: 30px 40px;
  }
}

@media only screen and (max-height: 900px) {
  .about-us {
    min-height: 110vh;
  }


}

@media only screen and (max-width: 414px) and (max-height: 667px) {
  .searchbar .filter {
    top: -80px;
  }

  .filters {
    position: absolute;
    top: 30px;
  }

  .searchbar .filter {
    height: 110vh !important;
  }

  .searchbar .filter {
    min-height: 110vh;
  }

  .searchbar.mobb .vector-wrapper {
    top: 67px;

  }

  .navbar {
    min-height: 47.96px;
  }
}

@media only screen and (max-height: 790px) and (max-width: 800px) {
  .about-us {
    min-height: 140vh;
  }

  .location {
    bottom: -90px !important;
  }

}

@media only screen and (max-height: 586px) {
  .about-us {
    min-height: 220vh;
  }

}

.reviewempty {
  text-align: center;
  margin: 20px auto;
}

.reviewempty img {
  width: 386.581px;
  height: 198.624px;
}

.reviewempty h3 {
  color: #333;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
}

.reviewempty p {
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}

/* Hide scrollbar for all elements */
::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar */
}

body {
  overflow: -moz-scrollbars-none;
  /* Hide scrollbar for Firefox */
}

/* Optional: Hide scrollbar for WebKit-based browsers */
html {
  overflow: -webkit-scrollbar;
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
}

.room-details-container .swiper {
  width: 100%;
  height: 100%;
}

.room-details-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.room-details-container .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.room-details-container .swiper {
  width: 100%;
  height: 380px;
  margin-left: auto;
  margin-right: auto;
}

.room-details-container .swiper-slide {
  background-size: cover;
  background-position: center;
}

.room-details-container .mySwiper2 {
  width: 100%;
}

.room-details-container .mySwiper {
  /* height: 20%; */
  box-sizing: border-box;
  padding: 10px 0;
}

.room-details-container .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.room-details-container .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.room-details-container .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.room-details-container .swipersliders {
  display: flex;
}

.room-details-container .swipersliders .first-swiper {
  width: 100%;

}

.room-details-container .swipersliders .first-swiper .swiper-slide {
  width: 100%;
  background-color: transparent !important;
}

.room-details-container .swipersliders .second-swiper .swiper-slide {
  background-color: transparent !important;

}

.room-details-container .swipersliders .first-swiper img {
  width: 100%;
  height: 279.722px;
  border-radius: 14.074px;
  box-shadow: 0px 3.519px 3.519px 0px rgba(0, 0, 0, 0.25);

}



.room-details-container .swipersliders .second-swiper .swiper-slide {
  width: 127px !important;

}

.room-details-container .swipersliders .second-swiper img {
  width: 127px;
  height: 76.528px;

  flex-shrink: 0;
  border-radius: 14.074px;
  box-shadow: 0px 3.519px 3.519px 0px rgba(0, 0, 0, 0.25);
}

.room-details-container .second-swiper.swiper {
  width: 30% !important;
  margin-left: 20px;
  margin-right: 10px;

}

.room-details-container .swipersliders .first-swiper img {
  margin-top: 5px;
}

.room-details-container .first-swiper {
  height: 350px;
}

.swiper-pagination-bullet {
  width: 14.074px;
  height: 14.074px;
  background-color: #D9D9D9;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #263069;
}

.room-details-container .swipersliders .first-swiper {
  margin-top: -40px;
}

.room-details-container .second-swiper.swiper {
  margin-top: -15px;
}

.room-details-container .swiper-button-lock {
  display: block;
}

.room-details-container .swiper-button-prev.swiper-button-lock {
  display: none;
}

.room-details-container .second-swiper .swiper-wrapper {
  height: unset;
}

.room-details-container .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  left: 35%;
  top: 90%;
  transform: rotate(90deg);
}

.room-details-container .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: rgba(0, 0, 0, 0.50);
}

/* .room-details-container .swipersliders .second-swiper .swiper-wrapper {
  margin-bottom: 50px !important;
} */

.cardrestroom .swiper-button-prev:after,
.cardrestroom .swiper-rtl .swiper-button-next:after,
.cardrestroom .swiper-button-next:after,
.cardrestroom .swiper-rtl .swiper-button-prev:after {
  padding-left: 8px;
  width: 24px !important;
  height: 24px !important;
  font-size: 14px;
  flex-shrink: 0;
  font-weight: 700;
  color: #263069;
  border: 1px solid #263069;
  border-radius: 50%;
  padding-top: 4px;
}

.tabdiv .divline.divnotline {
  background: none;
  color: #231FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


input:focus {
  background-color: #fff;
  border: 1px solid #263069;
}

.signup-signin input:focus {
  background-color: #fff !important;
  border: 1px solid #263069 !important;
}

.searchbar input:focus {
  background-color: #fff;
}

@media only screen and (min-width:1024px) {
  .room-details-container .swipersliders .second-swiper .swiper-slide {
    height: 76px !important;
    margin-top: 2px;

  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: transparent !important;
  }

  .dropdown-item a {
    color: #000 !important;
  }

  .searchbar .frame-parent input:focus {
    border: none;
    background-color: #edeaea;
  }

  .profileNav {
    justify-content: space-around;
    padding-top: 7px;
  }

  .dropdown-divider {
    width: 235.119px !important;
    height: 0.616px !important;
    background: rgba(0, 0, 0, 0.50) !important;
    margin: 16px auto;
  }

  .dropdown-menu[data-bs-popper] {
    left: -190% !important;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
    width: 295px;
    height: 272.985px;
    background: #FFF;
    box-shadow: 0px 0.881px 3.522px 1.761px rgba(0, 0, 0, 0.25);
    border-radius: 0;
  }

  .empty {
    padding-top: 80px;
  }

  .dropdown-menu[data-bs-popper] {
    left: -120%;
  }

  .edit-profilee .connect-parent {
    width: 331px !important;
  }

  .edit-profilee .connect {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .edit-profilee .google-parent {
    margin: unset;
    margin-right: auto;
  }
}






@media only screen and (max-width:414px) and (min-width:390px) and (max-height:900px) and (min-height: 700px) {
  .about-us {
    min-height: 107vh;
  }
}

@media only screen and (width:414px) and (height:896px) {
  .about-us {
    min-height: 97vh;
  }
}

@media only screen and (width:360px) and (height:740px) {
  .about-us {
    min-height: 132vh;
  }
}

.warnny .warning {

  color: #F00;
  text-align: center;
  font-family: "Nunito";
  font-size: 31.86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.warnny .vector-icon {
  height: 12.9%;
  display: block;
  max-height: 100%;
  width: 77.8px;
  margin: auto;
}

.warnny .you-are-one {
  font-size: 20px;
  margin-top: 10px;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 440.2px;
}

.warnny .are-you-sure {
  color: #F00;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.warnny .no {
  position: relative;
  font-weight: 500;
}

.warnny .no-wrapper {
  width: 119.5px;
  border-radius: 17.44px;
  border: 1.7px solid #ff0000;
  box-sizing: border-box;
  height: 57.9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 17.4px;
  cursor: pointer;
}

.warnny .yes {
  position: relative;
}

.warnny .yes-wrapper {
  width: 119.5px;
  border-radius: 17.44px;
  background-color: #32b768;
  height: 57.9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 17.4px;
  box-sizing: border-box;
  color: #fff;
}

.warnny .frame-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 38.4px;
  font-size: 22.67px;
  color: #e81a1a;
}

/* .warnny .warning-parent {

} */

.warnny {
  position: relative;
  box-shadow: 0px 0px 8.84px rgba(0, 0, 0, 0.25);
  border-radius: 26.52px;
  background-color: #fff;
  border: 3.5px solid #ff0000;
  box-sizing: border-box;
  height: 448px;
  width: 466.13px;
  overflow: hidden;
  text-align: center;
  font-size: 31.86px;
  color: #ff0000;
  font-family: "Nunito";
}

div:where(.swal2-container) h2:where(.swal2-title) {
  padding: .8em 0.2em !important;
}

@media only screen and (max-width:700px) {
  .warnny .you-are-one {
    width: 300px;
  }

  .containerbody.d0 .changecontainer>.row .row {
    padding-bottom: 150px !important;
  }
}

@media only screen and (width: 414px) and (height: 896px) {
  .searchbar .filter {
    min-height: 80vh;
  }
}

@media only screen and (width: 430px) and (height: 932px) {
  .searchbar .filter {
    min-height: 80vh;
    top: -50px;
    max-width: 360px;
  }
}

@media only screen and (width: 412px) and (height: 915px) {
  .searchbar .filter {
    min-height: 80vh;
  }

}


.mobdirectionbtn {
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: #0061FF;
  color: #FFF;
  font-size: 12.245px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.divnotline {
  width: 93px !important;

}

.room-details-container .component-14 {
  z-index: 22;
}

.cardrestroom .swiper-backface-hidden .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.room-details-container .swiper-button-prev {
  display: none !important;
}

.pagination-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #263069 !important;

}

.pagination-buttons svg {
  font-size: 35px;
  color: #263069;

}


.tooltippppy {
  position: relative;
  cursor: pointer;
}

.tooltippppy img {
  position: relative;
}

.tooltippppy .tooltiptext {
  visibility: hidden;
  width: 260px;
  text-align: center;
  position: absolute;
  z-index: 1;
  /* bottom: 0%; */
  /* Position above the image */
  left: 700px;
  opacity: 0;
  transition: opacity 0.3s;
  color: #FFF;
  font-size: 15.707px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 16.754px;
  border: 1px solid #231FFF;
  background: rgb(0, 0, 0);
  backdrop-filter: blur(10.471203804016113px);
  display: flex;
  padding: 16.754px;
  justify-content: center;
  align-items: center;
  gap: 8.377px;
}

.tooltippppy:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 350px) {
  .kspan {
    width: 30px;
  }
  .container-add .child .divRestType .restroomType{
    flex-wrap: wrap;
    padding: 10px;
  }
  .container-add .child .parent .centerr .child00 {
    gap: 20px;
  }

  .container-add .child .parent>span,
  .do {
    width: 30px;
  }

  .container-add .child .parent .centerr .child00 {
    width: 80%;
  }

  .container-add .child .parent .centerr .child01 {
    width: 90%;
  }

  .kl01>span {
    font-size: 12px;
  }

  .container-add .child .child-01 .child0 .inputBox0 textarea::placeholder {
    padding: 5px;
  }

  .container-add .child .timee .checkbox78 .typegraphy01 .dis span {
    font-size: 11px;
    width: 65px;
  }

  .timme .w-100 {
    width: 60% !important;
  }

  .navbar .navbar-brand span {
    margin-right: 53px;
  }

  .searchbar.mobb label {
    font-size: 12px !important;
  }
}

.editbtn .group-wrapper{
  background-color: #fff ;
  border: 1px solid #1a73e8;
}
.editbtn .group-wrapper svg{
 color: #1a73e8;
}
.swiperbtnadd{
  right: 0 !important;
}

@media only screen and (max-width: 330px) {
  .searchbar.mobb label{
    font-size: 10px !important;
  }
  .searchbar.mobb .component-74 {
    left: 237px;
    }
}

@media only screen and (max-width:320px){
  .containerbody .row .row .col-sm-12{
    padding: 15px !important;
  }
}
.overlay{
  position: relative;
}
.map-controls {
  position: absolute;
  bottom: 10%;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.zoom-button, .location-button {
  background-color: white;
  border-radius: 50%; 
  padding: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.zoom-button:hover, .location-button:hover {
  background-color: #f0f0f0;
}
.zoom-button img{
  width: 45px;
  height: 45px;
}
.location-button img{
  width: 25px;
  height: 25px;
}